import React, { Component } from 'react'
import {
  Container,
  Grid,
  Dimmer,
  Loader,
  Header,
  Divider,
  List,
  Button
} from 'semantic-ui-react'
import ViewOrderListItem from '../components/ViewOrderListItem'
// redux
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getOrders, cancelOrder } from '../actions/product'

class ViewOrdersPage extends Component {
  state = {
    offset: 0,
    limit: 5
  }
  componentWillMount() {
    const { offset, limit } = this.state
    this.props.getOrders({
      offset,
      limit
    })
  }
  loadMore() {
    const { offset, limit } = this.state
    const nextOffset = offset + limit
    this.setState({
      offset: nextOffset
    })

    this.props.getOrders({
      offset: nextOffset,
      limit
    })
  }
  loadPrev() {
    const { offset, limit } = this.state
    let nextOffset = offset - limit
    if (nextOffset < 0) {
      nextOffset = 0
    }
    this.setState({
      offset: nextOffset
    })

    this.props.getOrders({
      offset: nextOffset,
      limit
    })
  }

  componentWillUnmount() {}
  render() {
    const { isOrderLoading, orders } = this.props.product
    const { cancelOrder } = this.props

    return (
      <Container>
        <Dimmer active={isOrderLoading} inverted page blurring>
          <Loader active={isOrderLoading}>Loading</Loader>
        </Dimmer>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Header as="h3">Your Orders</Header>
              <Divider />
              <List>
                {orders
                  ? orders.map(order =>
                      <ViewOrderListItem
                        key={order.id}
                        order={order}
                        cancelOrder={cancelOrder.bind(this)}
                      />
                    )
                  : null}
                <div />
              </List>
              {orders.length === 0
                ? <Header as="h3" textAlign="center">
                    No Orders!
                  </Header>
                : null}
            </Grid.Column>
          </Grid.Row>
          {orders.length === 0
            ? null
            : <Grid.Row centered>
                <Grid.Column computer={3} tablet={5} mobile={8}>
                  <Button
                    onClick={this.loadPrev.bind(this)}
                    content="Previous"
                    fluid
                    circular
                    disabled={this.state.offset === 0}
                  />
                </Grid.Column>
                <Grid.Column computer={3} tablet={5} mobile={8}>
                  <Button
                    onClick={this.loadMore.bind(this)}
                    content="Next"
                    fluid
                    circular
                    disabled={orders.length !== this.state.limit}
                  />
                </Grid.Column>
              </Grid.Row>}
        </Grid>
      </Container>
    )
  }
}

const mapStateToProps = state => {
  return state
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ getOrders, cancelOrder }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewOrdersPage)
