import React from 'react'
import { Message } from 'semantic-ui-react'

const ErrorBox = ({ message, error, dismissError, isLoggedIn }) => {

  if(!isLoggedIn){
    return null
  }

  if (error) {
    const { message, rest } = error.hasOwnProperty('response')
      ? error.response.data
      : error

    return (
      <Message
        error
        header="There was an error!"
        className="error-box"
        onDismiss={dismissError}
      >
        <Message.Header>There was an error!</Message.Header>
        <Message.List>
          <Message.Item>
            {message}
          </Message.Item>
          {rest
            ? typeof rest.errors !== 'undefined'
              ? rest.errors.map(err =>
                  <Message.Item>
                    {err.message}
                  </Message.Item>
                )
              : null
            : null}
        </Message.List>
      </Message>
    )
  } else {
    return null
  }
}

export default ErrorBox
