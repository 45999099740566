import React from 'react'

import { Modal, Button, Grid, List, Segment } from 'semantic-ui-react'
import ViewOrderProductsTable from './ViewOrderProductsTable'
import makePackingSlip from '../common/pdf'

export default ({ order, open, deselectOrder }) => {
  const {
    orderNum,
    name,
    streetAddress,
    unit,
    city,
    province,
    country,
    postalCode,
    phone,
    email,
    comments,
    orderProducts
  } = order
  return (
    <Modal open={open} size="large" className="packing-slip">
      <Modal.Header>
        Picking / Packing Slip{' '}
        <Button
          icon="print"
          circular
          basic
          onClick={() => {
            makePackingSlip(order)
          }}
        />
        <Button
          icon="close"
          circular
          basic
          floated="right"
          onClick={deselectOrder}
        />
      </Modal.Header>
      <Modal.Content>
        <Grid id="section-to-print">
          <Grid.Row columns="equal">
            <Grid.Column as={Segment} padded style={{ margin: 10 }}>
              <List relaxed>
                <List.Item>
                  <List.Header>Order Number</List.Header>
                  {orderNum}
                </List.Item>
                <List.Item>
                  <List.Header>Ship To Address</List.Header>
                  {name} <br />
                  {streetAddress} {unit ? `Unit ${unit}` : null} <br />
                  {city}, {province} {postalCode} <br />
                  {country}
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column as={Segment} padded style={{ margin: 10 }}>
              <List relaxed>
                <List.Item>
                  <List.Header>Contact</List.Header>
                  {phone} <br />
                  {email}
                </List.Item>
                <List.Item>
                  <List.Header>Comments</List.Header>
                  {comments}
                </List.Item>
              </List>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <ViewOrderProductsTable orderProducts={orderProducts} />
          </Grid.Row>
        </Grid>
      </Modal.Content>
    </Modal>
  )
}
