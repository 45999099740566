import React, { Component } from 'react'

import {
  Table,
  Button,
  Segment,
  Divider,
  Label,
  Form,
  Checkbox
} from 'semantic-ui-react'
import moment from 'moment'
import { makePackingSlips } from '../common/pdf'

export default class ManageOrdersBatchTable extends Component {
  state = { selectedOrders: [] }
  statusColor = status => {
    switch (status) {
      case 'shipped':
        return 'green'
      case 'approved':
        return 'blue'
      case 'pending':
        return 'orange'
      case 'rejected':
        return 'red'
      case 'cancelled':
        return null
      default:
        return null
    }
  }

  handleSelectAll(e, data, orders) {
    if (!data.checked) {
      this.setState({ selectedOrders: this.props.orders.map(({ id }) => id) })
    } else {
      this.setState({ selectedOrders: [] })
    }
  }

  handleChange(data, order) {
    let { selectedOrders } = this.state
    if (data.checked) {
      selectedOrders = [...selectedOrders, order.id]
      this.setState({
        selectedOrders
      })
    } else {
      selectedOrders = selectedOrders.filter(id => id !== order.id)
      this.setState({
        selectedOrders
      })
    }
  }

  printBatch() {
    let { orders } = this.props
    orders = orders.filter(
      ({ id }) => this.state.selectedOrders.indexOf(id) > -1
    )
    makePackingSlips(orders)
  }

  onChange = (e, data) => {
    this.setState({ [data.name]: data.value })
  }
  changeStatus() {
    this.props.changeStatus(this.props.order.id, this.state.status)
  }
  shipOrder() {
    this.props.shipOrder(
      this.props.order.id,
      this.state.courier,
      this.state.tracking
    )
  }
  render() {
    const { orders } = this.props
    const { selectedOrders } = this.state
    return (
      <div>
        <Segment>
          <Button
            onClick={this.printBatch.bind(this)}
            content="Print Packing Slips"
            icon="print"
            disabled={selectedOrders.length === 0}
          />
        </Segment>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>
                <Form.Field
                  control={Checkbox}
                  label="All"
                  onClick={this.handleSelectAll.bind(this)}
                  defaultChecked={orders.length === selectedOrders.length}
                />
              </Table.HeaderCell>
              <Table.HeaderCell>Status</Table.HeaderCell>
              <Table.HeaderCell>Created At</Table.HeaderCell>
              <Table.HeaderCell>Order Number</Table.HeaderCell>
              <Table.HeaderCell>Ship To</Table.HeaderCell>
              <Table.HeaderCell>Contact</Table.HeaderCell>
              <Table.HeaderCell>Submitted By</Table.HeaderCell>
              <Table.HeaderCell>Products On Order</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {orders.map(order => {
              const {
                id,
                orderProducts,
                createdAt,
                orderNum,
                user,
                name,
                status,
                streetAddress,
                unit,
                city,
                province,
                country,
                postalCode,
                phone,
                email
              } = order
              const { username } = user
              return (
                <Table.Row key={id}>
                  <Table.Cell collapsing>
                    <Checkbox
                      checked={selectedOrders.indexOf(id) > -1}
                      onChange={(e, data) => this.handleChange(data, order)}
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <Label color={this.statusColor(status)} basic>
                      {status}
                    </Label>
                  </Table.Cell>
                  <Table.Cell>
                    {moment(createdAt).format('YYYY-MM-DD h:mm a')}
                  </Table.Cell>
                  <Table.Cell>{orderNum}</Table.Cell>
                  <Table.Cell>
                    {name} <br />
                    {streetAddress} {unit}
                    <br />
                    {city}, {province} {postalCode} <br />
                    {country}
                  </Table.Cell>
                  <Table.Cell>
                    {phone} <br />
                    {email}
                  </Table.Cell>
                  <Table.Cell>
                    {username} <br />
                  </Table.Cell>
                  <Table.Cell>
                    {orderProducts.length} <br />
                  </Table.Cell>
                </Table.Row>
              )
            })}
          </Table.Body>
        </Table>
        <Divider />
      </div>
    )
  }
}
