// @ts-check
// eslint-disable jsx-a11y/anchor-has-content
import React, { Component } from "react";
import { Table, Button, Segment, Grid } from "semantic-ui-react";
import moment from "moment";
export default class AdjustmentHistory extends Component {
  state = {
    offset: 0,
    limit: 500,
  };

  componentWillMount() {
    const { offset, limit } = this.state;
    this.props.adminGetAdjustmentHistory(offset, limit);
  }

  tableToExcel(table, name, filename) {
    var uri = "data:application/vnd.ms-excel;base64,",
      template =
        '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
      base64 = function (s) {
        return window.btoa(unescape(encodeURIComponent(s)));
      },
      format = function (s, c) {
        return s.replace(/{(\w+)}/g, function (m, p) {
          return c[p];
        });
      };
    if (!table.nodeType) table = document.getElementById(table);
    var ctx = { worksheet: name || "Worksheet", table: table.innerHTML };

    document.getElementById("dlink").href = uri + base64(format(template, ctx));
    document.getElementById("dlink").download = filename;
    document.getElementById("dlink").click();
  }
  loadMore() {
    let { offset, limit } = this.state;
    offset = offset + limit;
    this.props.adminGetMoreAdjustmentHistory(offset, limit);
  }
  render() {
    const { data } = this.props;
    const { limit } = this.state;
    return (
      <div>
        <Segment>
          <Button
            content="Export to Excel"
            icon="save"
            onClick={() =>
              this.tableToExcel(
                "master-list",
                "Adjustment History",
                "Adjustment-History.xls"
              )
            }
          />
          {/* eslint-disable-next-line */}
          <a
            id="dlink"
            href=""
            style={{ display: "none" }}
            onClick={(e) => e.preventDefault()}
          />
        </Segment>
        <Table id="master-list">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>SKU</Table.HeaderCell>
              <Table.HeaderCell>Product</Table.HeaderCell>
              <Table.HeaderCell>Action</Table.HeaderCell>
              <Table.HeaderCell>Reference</Table.HeaderCell>
              <Table.HeaderCell>Qty</Table.HeaderCell>
              <Table.HeaderCell>Date</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data.map((row) => {
              return (
                <Table.Row>
                  <Table.Cell>{row.productVariant.sku}</Table.Cell>
                  <Table.Cell>{row.productVariant.product.name}</Table.Cell>
                  <Table.Cell>{row.detail}</Table.Cell>
                  <Table.Cell>{row.ref}</Table.Cell>
                  <Table.Cell>{row.qty}</Table.Cell>
                  <Table.Cell>
                    {moment(new Date(row.createdAt)).format("YYYY-MM-DD HH:mm")}
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
        <Grid centered>
          <Grid.Column width={4}>
            <Button
              floated="right"
              basic
              circular
              fluid
              disabled={data ? data.length < limit : null}
              onClick={this.loadMore.bind(this)}
              content={data.length < limit ? "No more data." : "Next Page"}
            />
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}
