import React, { Component } from "react";
import {
  Header,
  Divider,
  Button,
  Table,
  Form,
  Input,
  Grid,
} from "semantic-ui-react";
import AdminLayout from "../components/AdminLayout";
// redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  adminGetSizeGuide,
  adminCreateSizeGuide,
  adminEditSizeGuide,
  adminDeleteSizeGuide,
} from "../actions/admin";

class ManageSizeGuide extends Component {
  state = {
    id: null,
    size: "",
    neck: ["", ""],
    chest: ["", ""],
    arm: ["", ""],
    order: "",
  };
  componentWillMount() {
    this.props.adminGetSizeGuide();
  }

  selectSizeGuide(sizeGuide) {
    this.setState(sizeGuide);
  }

  editSizeGuide() {
    this.props.adminEditSizeGuide(this.state);
    this.setState({
      id: null,
      size: "",
      neck: ["", ""],
      chest: ["", ""],
      arm: ["", ""],
      order: "",
    });
  }
  createSizeGuide() {
    this.props.adminCreateSizeGuide(this.state);
    this.setState({
      id: null,
      size: "",
      neck: ["", ""],
      chest: ["", ""],
      arm: ["", ""],
      order: "",
    });
  }
  render() {
    const { sizeGuide, isSizeGuideLoading } = this.props.admin;
    const { id, size, chest, arm, order } = this.state;
    const isComplete = size;
    return (
      <AdminLayout isLoading={isSizeGuideLoading}>
        <Header as="h3">Manage Size Guide</Header>
        <Divider />
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Table definition>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell />
                    <Table.HeaderCell>Size</Table.HeaderCell>
                    <Table.HeaderCell>Chest (cm)</Table.HeaderCell>
                    <Table.HeaderCell>Arm (cm)</Table.HeaderCell>
                    <Table.HeaderCell>Sort Order</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell collapsing>
                      <Button
                        icon="add"
                        basic
                        circular
                        color="green"
                        onClick={
                          id
                            ? this.editSizeGuide.bind(this)
                            : this.createSizeGuide.bind(this)
                        }
                        disabled={!isComplete}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <Form.Field
                        name="size"
                        control={Input}
                        value={size}
                        placeholder="Size"
                        onChange={(e, data) =>
                          this.setState({
                            [data.name]: data.value,
                          })
                        }
                      />
                    </Table.Cell>

                    <Table.Cell>
                      <Form.Group>
                        <Form.Field
                          name="chest"
                          control={Input}
                          value={chest[0]}
                          placeholder="Min"
                          onChange={(e, data) =>
                            this.setState({
                              [data.name]: [data.value, chest[1]],
                            })
                          }
                        />
                        <Form.Field
                          name="chest"
                          control={Input}
                          value={chest[1]}
                          placeholder="Max"
                          onChange={(e, data) =>
                            this.setState({
                              [data.name]: [chest[0], data.value],
                            })
                          }
                        />
                      </Form.Group>
                    </Table.Cell>
                    <Table.Cell>
                      <Form.Group>
                        <Form.Field
                          name="arm"
                          control={Input}
                          value={arm[0]}
                          placeholder="Min"
                          onChange={(e, data) =>
                            this.setState({
                              [data.name]: [data.value, arm[1]],
                            })
                          }
                        />
                        <Form.Field
                          name="arm"
                          control={Input}
                          value={arm[1]}
                          placeholder="Max"
                          onChange={(e, data) =>
                            this.setState({
                              [data.name]: [arm[0], data.value],
                            })
                          }
                        />
                      </Form.Group>
                    </Table.Cell>
                    <Table.Cell>
                      {" "}
                      <Form.Field
                        name="order"
                        control={Input}
                        value={order}
                        placeholder="Sort Order"
                        onChange={(e, data) =>
                          this.setState({
                            [data.name]: data.value,
                          })
                        }
                      />
                    </Table.Cell>
                  </Table.Row>

                  {sizeGuide
                    ? sizeGuide
                        .filter(({ id }) => id !== this.state.id)
                        .map((sizeG) => {
                          const { id, size, chest, arm, order } = sizeG;
                          return (
                            <Table.Row key={id}>
                              <Table.Cell>
                                <Button.Group>
                                  <Button
                                    icon="edit"
                                    basic
                                    circular
                                    color="grey"
                                    onClick={() => this.selectSizeGuide(sizeG)}
                                  />
                                  <Button
                                    icon="delete"
                                    basic
                                    color="red"
                                    circular
                                    onClick={() =>
                                      this.props.adminDeleteSizeGuide({ id })
                                    }
                                  />
                                </Button.Group>
                              </Table.Cell>
                              <Table.Cell>{size}</Table.Cell>

                              <Table.Cell>{chest.join(" - ")}</Table.Cell>
                              <Table.Cell>{arm.join(" - ")}</Table.Cell>
                              <Table.Cell>{order}</Table.Cell>
                            </Table.Row>
                          );
                        })
                    : null}
                </Table.Body>
              </Table>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </AdminLayout>
    );
  }
}

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      adminGetSizeGuide,
      adminCreateSizeGuide,
      adminEditSizeGuide,
      adminDeleteSizeGuide,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageSizeGuide);
