// @ts-check
import React, { Component } from "react";

import { Header, Divider, Grid } from "semantic-ui-react";
import AdminLayout from "../components/AdminLayout";
import EditBaseUserForm from "../components/EditBaseUserForm";

import { getLocations } from "../actions/user";
// redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  adminClearUser,
  adminCreateUser,
  adminGetApprovers,
  adminEditUserProp,
} from "../actions/admin";

class CreateUserPage extends Component {
  componentWillMount() {
    this.props.adminGetApprovers();
    this.props.adminClearUser();
    this.props.adminEditUserProp("enabled", true);
    this.props.getLocations();
  }
  componentDidUpdate() {
    const id = this.props.admin.singleUser.id;
    if (id) {
      this.props.history.push(`/admin/manage-users/edit/${id}`);
    }
  }

  handleChange(e, data) {
    if (data.name === "enabled") {
      data.value = data.checked;
    }
    this.props.adminEditUserProp(data.name, data.value);
  }

  handleSaveBaseUser(e) {
    e.preventDefault();
    const {
      username,
      password,
      enabled,
      firstname,
      lastname,
      email,
      role,
      approverId,
      wallet,
      location,
      hourlyType,
    } = this.props.admin.singleUser;
    this.props.adminCreateUser({
      username,
      password,
      enabled,
      firstname,
      lastname,
      email,
      role,
      approverId,
      wallet,
      location,
      hourlyType,
    });
  }

  render() {
    const { singleUser, isUsersLoading, approvers } = this.props.admin;
    const { locations } = this.props.user;
    const {
      username,
      password,
      enabled,
      firstname,
      lastname,
      email,
      role,
      approverId,
      wallet,
      location,
      hourlyType,
    } = singleUser;
    const isEdited = username && password && email;
    return (
      <AdminLayout isLoading={isUsersLoading}>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Header as="h3">Create User</Header>
              <Divider />
              <EditBaseUserForm
                adminClearUser={this.props.adminClearUser}
                onSubmit={this.handleSaveBaseUser.bind(this)}
                isEdited={isEdited}
                isEditMode={false}
                username={username}
                password={password}
                enabled={enabled}
                email={email}
                firstname={firstname}
                lastname={lastname}
                role={role}
                approverId={approverId}
                approvers={approvers}
                handleChange={this.handleChange.bind(this)}
                locations={locations}
                location={location}
                wallet={wallet}
                hourlyType={hourlyType}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </AdminLayout>
    );
  }
}

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      adminClearUser,
      adminCreateUser,
      adminGetApprovers,
      adminEditUserProp,
      getLocations,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateUserPage);
