import React, { Component } from "react";
import {
  Grid,
  Header,
  Button,
  Divider,
  Form,
  Select,
  Input,
  Tab,
} from "semantic-ui-react";
import AdminLayout from "../components/AdminLayout";
import ManageOrdersTable from "../components/ManageOrdersTable";
import ManageOrdersBatchTable from "../components/ManageOrdersBatchTable";
import ManageOrdersModal from "../components/ManageOrdersModal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
// redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  adminGetOrders,
  adminSetOrderStatus,
  adminGetOrdersGrouped,
  adminShipOrder,
} from "../actions/admin";
import { ManageOrdersGrouped } from "../components/ManageOrdersGrouped";

class ManageOrders extends Component {
  state = {
    offset: 0,
    limit: 200,
    status: "approved",
    orderNum: "",
    selectedOrder: false,
    startDate: moment().startOf("month"),
    endDate: moment().endOf("month"),
    disabled: false,
    view: "detail",
  };

  componentDidMount() {
    const { offset, limit, status, startDate, endDate } = this.state;
    this.props.adminGetOrders({ offset, limit, status, startDate, endDate });
    this.props.adminGetOrdersGrouped({
      offset,
      limit,
      status,
      startDate,
      endDate,
    });
  }

  loadMore() {
    let { offset, limit } = this.state;
    offset = offset + limit;
    const state = this.state;
    this.setState({ offset });
    this.props.adminGetOrders({ ...state, offset });
  }

  onChange(e, data) {
    if (data.name === "orderNum" && data.value !== "") {
      this.setState({ disabled: true });
    }
    this.setState({ [data.name]: data.value });
  }

  onDateChange(date, name) {
    this.setState({
      [name]: date,
    });
  }

  search() {
    let { limit, status, startDate, endDate, orderNum } = this.state;
    this.setState({
      offset: 0,
    });

    if (orderNum) {
      this.props.adminGetOrders({
        offset: 0,
        limit,
        orderNum,
      });
    } else {
      this.props.adminGetOrders({
        offset: 0,
        limit,
        status,
        orderNum,
        startDate,
        endDate,
      });
    }

    this.props.adminGetOrdersGrouped({
      offset: 0,
      limit,
      status,
      startDate,
      endDate,
    });
  }

  reset() {
    this.setState({
      offset: 0,
      limit: 50,
      status: "approved",
      orderNum: "",
      startDate: moment().subtract(30, "d"),
      endDate: moment(),
      selectedOrder: false,
      disabled: false,
    });
    this.props.adminGetOrders({
      offset: 0,
      limit: 50,
      status: "approved",
      orderNum: "",
      startDate: moment().subtract(30, "d"),
      endDate: moment(),
    });
  }

  selectOrder = (order) => this.setState({ selectedOrder: order });

  deselectOrder = () => this.setState({ selectedOrder: false });

  changeStatus(id, status) {
    this.props.adminSetOrderStatus(
      {
        id,
        status,
      },
      this.state
    );
  }
  shipOrder(id, courier, tracking) {
    this.props.adminShipOrder(
      {
        id,
        courier,
        tracking,
      },
      this.state
    );
  }

  render() {
    const { limit } = this.state;
    const { orders, isOrderLoading, ordersGrouped } = this.props.admin;
    const {
      selectedOrder,
      status,
      orderNum,
      startDate,
      endDate,
      disabled,
    } = this.state;
    return (
      <AdminLayout isLoading={isOrderLoading}>
        <Header as="h3">Manage Orders</Header>
        <Divider />
        <ManageOrdersModal
          open={selectedOrder}
          order={selectedOrder}
          deselectOrder={this.deselectOrder.bind(this)}
        />
        <Grid>
          <Grid.Row columns="equal">
            <Grid.Column>
              <Form inline className="no-print">
                <Form.Group>
                  <Form.Field
                    name="status"
                    control={Select}
                    value={status}
                    label="Order Status"
                    placeholder="Select Status"
                    options={[
                      { key: 0, text: "any", value: "" },
                      { key: 1, text: "pending", value: "pending" },
                      { key: 2, text: "approved", value: "approved" },
                      { key: 3, text: "shipped", value: "shipped" },
                      { key: 4, text: "cancelled", value: "cancelled" },
                      { key: 5, text: "rejected", value: "rejected" },
                    ]}
                    onChange={this.onChange.bind(this)}
                    disabled={disabled}
                  />

                  <Form.Field>
                    <label>Start Date</label>
                    <DatePicker
                      name="startDate"
                      dateFormat="YYYY-MM-DD"
                      selected={startDate}
                      startDate={startDate}
                      endDate={endDate}
                      onChange={(date) => this.onDateChange(date, "startDate")}
                      disabled={disabled}
                      showMonthDropdown
                      showYearDropdown
                      monthsShown={2}
                      className="ui input"
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>End Date</label>
                    <DatePicker
                      dateFormat="YYYY-MM-DD"
                      selected={endDate}
                      startDate={startDate}
                      endDate={endDate}
                      onChange={(date) => this.onDateChange(date, "endDate")}
                      disabled={disabled}
                      showMonthDropdown
                      showYearDropdown
                      monthsShown={2}
                      className="ui input"
                    />
                  </Form.Field>
                  <Form.Field
                    name="orderNum"
                    control={Input}
                    value={orderNum}
                    label="Search Order Number"
                    placeholder="Order Number (case sensitive)"
                    onChange={this.onChange.bind(this)}
                  />
                  <Form.Field>
                    <label>&nbsp;</label>
                    <Button onClick={this.search.bind(this)}>Search</Button>
                    <Button onClick={this.reset.bind(this)}>Reset</Button>
                  </Form.Field>
                </Form.Group>
              </Form>
              <Divider />

              <Tab
                menu={{ secondary: true, pointing: true }}
                panes={[
                  {
                    menuItem: "By ShipTo",
                    render: () => {
                      return (
                        <div>
                          {ordersGrouped.length > 0 ? (
                            <ManageOrdersGrouped
                              ordersGrouped={ordersGrouped}
                              shipOrder={this.shipOrder.bind(this)}
                            />
                          ) : (
                            "No Orders Found!"
                          )}
                        </div>
                      );
                    },
                  },
                  {
                    menuItem: "Order Manager",
                    render: () => {
                      return (
                        <div>
                          {orders.length > 0 ? (
                            <ManageOrdersTable
                              orders={orders}
                              selectOrder={this.selectOrder.bind(this)}
                              changeStatus={this.changeStatus.bind(this)}
                              shipOrder={this.shipOrder.bind(this)}
                            />
                          ) : (
                            "No Orders Found!"
                          )}
                        </div>
                      );
                    },
                  },
                  {
                    menuItem: "Batch Export",
                    render: () => {
                      return (
                        <div>
                          {orders.length > 0 ? (
                            <ManageOrdersBatchTable
                              orders={orders}
                              selectOrder={this.selectOrder.bind(this)}
                              changeStatus={this.changeStatus.bind(this)}
                              shipOrder={this.shipOrder.bind(this)}
                            />
                          ) : (
                            "No Orders Found!"
                          )}
                        </div>
                      );
                    },
                  },
                ]}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row centered>
            <Grid.Column width={4}>
              <Button
                fluid
                circular
                basic
                onClick={this.loadMore.bind(this)}
                disabled={orders.length < limit}
                content={
                  orders.length < limit ? "No more orders." : "Load More"
                }
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </AdminLayout>
    );
  }
}

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      adminGetOrders,
      adminGetOrdersGrouped,
      adminSetOrderStatus,
      adminShipOrder,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageOrders);
