import React, { Component } from 'react'

import {
  Table,
  Button,
  Divider,
  Label,
  Form,
  Input,
  Select
} from 'semantic-ui-react'
import moment from 'moment'

export default ({ orders, selectOrder, changeStatus, shipOrder }) => {
  return (
    <div>
      {orders.map(order => (
        <ManageOrderTable
          key={order.id}
          order={order}
          selectOrder={selectOrder}
          changeStatus={changeStatus}
          shipOrder={shipOrder}
        />
      ))}
    </div>
  )
}

export class ManageOrderTable extends Component {
  state = { status: this.props.order.status, courier: '', tracking: '' }
  statusColor = status => {
    switch (status) {
      case 'shipped':
        return 'green'
      case 'approved':
        return 'blue'
      case 'pending':
        return 'orange'
      case 'rejected':
        return 'red'
      case 'cancelled':
        return null
      default:
        return null
    }
  }
  onChange = (e, data) => {
    this.setState({ [data.name]: data.value })
  }
  changeStatus() {
    this.props.changeStatus(this.props.order.id, this.state.status)
  }
  shipOrder() {
    this.props.shipOrder(
      this.props.order.id,
      this.state.courier,
      this.state.tracking
    )
  }
  render() {
    const { selectOrder, order } = this.props
    const {
      id,
      orderProducts,
      createdAt,
      orderNum,
      user,
      name,
      status,
      streetAddress,
      unit,
      city,
      province,
      country,
      postalCode,
      phone,
      email,
      comments,
      courier,
      tracking,
      rejectReason
    } = order
    const { username } = user
    return (
      <div>
        {/* meta  */}
        <Table key={id} color={this.statusColor(status)} structured>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Status</Table.HeaderCell>
              <Table.HeaderCell>Created At</Table.HeaderCell>
              <Table.HeaderCell>Order Number</Table.HeaderCell>
              <Table.HeaderCell>Ship To</Table.HeaderCell>
              <Table.HeaderCell>Contact</Table.HeaderCell>
              <Table.HeaderCell>Comments</Table.HeaderCell>
              <Table.HeaderCell>SubmittedBy</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            <Table.Row>
              <Table.Cell>
                <Label ribbon color={this.statusColor(status)} basic>
                  {status}
                </Label>
              </Table.Cell>
              <Table.Cell>
                {moment(createdAt).format('YYYY-MM-DD h:mm a')}
              </Table.Cell>
              <Table.Cell>{orderNum}</Table.Cell>
              <Table.Cell>
                {name} <br />
                {streetAddress} {unit}
                <br />
                {city}, {province} {postalCode} <br />
                {country}
              </Table.Cell>
              <Table.Cell>
                {phone} <br />
                {email}
              </Table.Cell>
              <Table.Cell>
                {comments} <br />
                {rejectReason ? `Reject Reason: ${rejectReason} ` : null}
              </Table.Cell>
              <Table.Cell>
                {username} <br />
              </Table.Cell>
            </Table.Row>
          </Table.Body>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Line</Table.HeaderCell>
              <Table.HeaderCell>SKU</Table.HeaderCell>
              <Table.HeaderCell>Product</Table.HeaderCell>
              <Table.HeaderCell>Size</Table.HeaderCell>
              <Table.HeaderCell>Qty Ordered</Table.HeaderCell>
              <Table.HeaderCell>On Hand</Table.HeaderCell>
              <Table.HeaderCell />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {orderProducts.map((product, idx) => {
              return (
                <Table.Row key={product.id}>
                  <Table.Cell>{idx + 1}</Table.Cell>
                  <Table.Cell>{product.productVariant.sku}</Table.Cell>
                  <Table.Cell>{product.productVariant.product.name}</Table.Cell>
                  <Table.Cell>{product.productVariant.size}</Table.Cell>
                  <Table.Cell>{product.qty}</Table.Cell>
                  <Table.Cell>
                    {status === 'pending' || status === 'approved' ? (
                      product.productVariant.instock
                    ) : (
                      '-'
                    )}
                  </Table.Cell>
                  <Table.Cell />
                </Table.Row>
              )
            })}
          </Table.Body>
          <Table.Footer fullWidth>
            <Table.Row>
              <Table.HeaderCell colSpan={3}>
                <Form>
                  <Form.Group>
                    <Form.Button
                      onClick={() => selectOrder(order)}
                      content="Packing Slip"
                    />
                    {status !== 'shipped' ? (
                      <Form.Field
                        name="status"
                        control={Select}
                        defaultValue={status}
                        placeholder="Change Status"
                        options={[
                          { key: 1, text: 'pending', value: 'pending' },
                          { key: 2, text: 'approved', value: 'approved' },
                          { key: 4, text: 'cancelled', value: 'cancelled' },
                          { key: 5, text: 'rejected', value: 'rejected' },
                          { key: 6, text: 'shipped', value: 'shipped' }
                        ]}
                        size="small"
                        onChange={this.onChange.bind(this)}
                      />
                    ) : null}
                    {status !== 'shipped' ? (
                      <Form.Button
                        onClick={this.changeStatus.bind(this)}
                        content="Change Status"
                      />
                    ) : null}
                  </Form.Group>
                </Form>
              </Table.HeaderCell>
              <Table.HeaderCell colSpan={2} />
              <Table.HeaderCell colSpan={2}>
                {status !== 'shipped' ? (
                  <Form>
                    <Form.Group grouped>
                      <Form.Field
                        name="courier"
                        control={Input}
                        placeholder="Courier"
                        size="small"
                        onChange={this.onChange.bind(this)}
                      />
                      <Form.Field
                        name="tracking"
                        control={Input}
                        placeholder="Tracking Number"
                        size="small"
                        onChange={this.onChange.bind(this)}
                        action={
                          <Button
                            content="Ship"
                            positive
                            size="small"
                            disabled={
                              !this.state.courier || !this.state.tracking
                            }
                            onClick={this.shipOrder.bind(this)}
                          />
                        }
                      />
                    </Form.Group>
                  </Form>
                ) : (
                  <div>
                    {courier}: {tracking}
                  </div>
                )}
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
        <Divider />
      </div>
    )
  }
}
