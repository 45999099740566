import React, { useState } from "react";
import {
  Container,
  Grid,
  Segment,
  Form,
  Header,
  Icon,
  Message,
} from "semantic-ui-react";
import wings from "../logo-wings.svg";
import shawLogo from "../shaw-logo.svg";
import { THEME } from "../env";

import { Link } from "react-router-dom";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { verifyUser } from "../actions/user";

const VerifyPageView = (props) => {
  const { verifyUser, match } = props;

  const paramVerificationToken = match.params.verificationToken;

  const [verificationToken, setVerificationToken] = useState(
    paramVerificationToken
  );

  const [errMessage, setErrMessage] = useState("");
  const [isVerified, setIsVerified] = useState(false);
  const [verifiedUsername, setVerifiedUsername] = useState("");

  const handleChange = (e, data) => {
    setVerificationToken(data.value);
  };

  const handleSubmit = async () => {
    const data = await verifyUser({ verificationToken });
    if (!data.success) {
      return setErrMessage(data.message);
    }
    setIsVerified(true);
    setVerifiedUsername(data.username);
  };

  return (
    <Container className="login-page">
      <Grid centered>
        <Grid.Column computer={6} mobile={16}>
          <div className="login-box">
            <Segment>
              <Header textAlign="center" icon as="h1">
                <Icon>
                  <img
                    src={THEME === "freedom" ? wings : shawLogo}
                    width={THEME === "freedom" ? 35 : 100}
                    style={{ display: "inline-block" }}
                    alt="wings"
                  />
                </Icon>
                Verify
                <Header.Subheader>Verify Corporate Email</Header.Subheader>
              </Header>

              {!isVerified && (
                <Form>
                  <Form.Input
                    name="verificationToken"
                    label="Verification Token"
                    placeholder="Token"
                    onChange={handleChange}
                    value={verificationToken}
                  />
                  {errMessage && (
                    <p style={{ color: "red" }}>
                      <small>{errMessage}</small>
                    </p>
                  )}

                  <Form.Button fluid onClick={handleSubmit} primary>
                    Verify
                  </Form.Button>
                  <p>
                    <Link to="/Login">Go to Login</Link>
                  </p>
                </Form>
              )}
              {isVerified && (
                <>
                  <Message success>
                    Verified <strong>{verifiedUsername}</strong> Successfully!
                  </Message>

                  <Link to="/">&larr; Go Home</Link>
                </>
              )}
            </Segment>
          </div>
        </Grid.Column>
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state) => state;
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ verifyUser }, dispatch);
};
export const VerifyPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(VerifyPageView);
