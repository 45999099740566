import React from "react";

import { Header, Input, Button, Dimmer, List, Image } from "semantic-ui-react";

const EditProductImageList = ({
  handleImageInput,
  handleImageUpload,
  uploadDisabled,
  images,
  deleteProductImage,
  handleMoveImage,
  isEdited,
  uploadedImage,
  selectImage,
}) => {
  const fileTooLarge = uploadedImage ? uploadedImage.size > 3000000 : false;
  return (
    <div>
      <Header as="h3">Base Product Images</Header>
      <Input
        type="file"
        placeholder="Add image"
        action
        onChange={handleImageInput}
      >
        <input accept="image/*" />
        <Button
          content={fileTooLarge ? "File Too Large" : "Upload"}
          disabled={uploadDisabled || fileTooLarge}
          onClick={handleImageUpload}
          positive={!fileTooLarge}
          negative={fileTooLarge}
        />
      </Input>
      <br />
      <br />

      <List horizontal>
        <Dimmer
          inverted
          active={isEdited}
          content={
            <Header as="h4" color="black" style={{ color: "black" }}>
              Save base product before editing images.
            </Header>
          }
        />

        {images
          ? images.map((image, idx) => {
              return (
                <List.Item>
                  <Image
                    as="a"
                    src={`${process.env.PUBLIC_URL}/storage/${image}`}
                    size="small"
                    onClick={() => selectImage(image, idx)}
                  />
                  <div style={{ marginTop: 5 }} />
                  <Button
                    circular
                    basic
                    color="black"
                    icon="chevron left"
                    size="mini"
                    onClick={() => handleMoveImage(idx, idx - 1)}
                  />
                  <Button
                    circular
                    basic
                    color="black"
                    icon="chevron right"
                    size="mini"
                    onClick={() => handleMoveImage(idx, idx + 1)}
                  />
                  <Button
                    icon="close"
                    color="red"
                    size="mini"
                    basic
                    circular
                    onClick={() => deleteProductImage(image)}
                  />
                </List.Item>
              );
            })
          : null}
      </List>
    </div>
  );
};
export default EditProductImageList;
