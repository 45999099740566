import React, { Component } from "react";
import { Container, Grid, Dimmer, Loader, Button } from "semantic-ui-react";

// redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getProducts,
  addToCart,
  getCart,
  getCategories,
  getSizeGuide,
} from "../actions/product";
import { FilterBy } from "../components/FilterBy/FilterBy";
import { ProductList } from "../components/ProductList/ProductList";

class CatalogPage extends Component {
  componentWillMount() {
    const [offset, limit, categoryId] = [0, 25, null];
    this.setState({
      offset,
      limit,
    });
    this.props.getProducts({
      offset,
      limit,
      categoryId,
    });
    this.props.getCart({});
    this.props.getCategories();
    this.props.getSizeGuide();
    document.body.classList.add("grey");
  }
  componentWillUnmount() {
    document.body.classList.remove("grey");
  }

  filterCategory(categories) {
    let { offset, limit } = this.state;
    offset = 0;
    const categoryIds = categories.map((c) => c.id);
    this.setState({
      offset,
      categoryIds,
    });

    this.props.getProducts({ offset, limit, categoryIds });
  }

  loadMore() {
    let { offset, limit, categoryId } = this.state;
    offset = offset + limit;
    this.setState({ offset });
    this.props.getProducts({
      offset,
      limit,
      categoryId,
    });
  }

  addToCart(productVariantId, qty = 1) {
    this.props.addToCart(
      {
        userId: this.props.user.detail.id,
        productVariantId,
        qty,
      },
      qty > 1
    );
  }

  render() {
    const { products, isCatalogLoading, categories } = this.props.product;
    return (
      <Container>
        <Dimmer active={isCatalogLoading} inverted page blurring>
          <Loader active={isCatalogLoading}>Loading...</Loader>
        </Dimmer>

        <Grid>
          <Grid.Row columns="equal">
            <Grid.Column>
              <FilterBy
                productTypes={categories}
                filterCategory={this.filterCategory.bind(this)}
              />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns="equal">
            <Grid.Column>
              <Grid columns="equal">
                <ProductList products={products} />
              </Grid>
              {products.length === this.state.limit ? (
                <Grid columns="equal" centered>
                  <Grid.Column computer={4}>
                    <Button
                      fluid
                      basic
                      circular
                      onClick={this.loadMore.bind(this)}
                    >
                      Load More
                    </Button>
                  </Grid.Column>
                </Grid>
              ) : null}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getCategories,
      getProducts,
      addToCart,
      getCart,
      getSizeGuide,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CatalogPage);
