import React, { Component } from "react";
import { Table, Button, Segment, Grid } from "semantic-ui-react";
import { Link } from "react-router-dom";

export default class ProductMasterList extends Component {
  transform() {
    const { products } = this.props;
    let data = [];

    for (let product of products) {
      let variants = product.v_productVariants.map((v) => {
        v.name = product.name;
        v.enabled = product.enabled;
        v.description = product.description;
        v.category = product.category.name;
        return v;
      });
      data = [...data, ...variants];
    }

    return data;
  }

  tableToExcel(table, name, filename) {
    var uri = "data:application/vnd.ms-excel;base64,",
      template =
        '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>{table}</table></body></html>',
      base64 = function (s) {
        return window.btoa(unescape(encodeURIComponent(s)));
      },
      format = function (s, c) {
        return s.replace(/{(\w+)}/g, function (m, p) {
          return c[p];
        });
      };
    if (!table.nodeType) table = document.getElementById(table);
    var ctx = { worksheet: name || "Worksheet", table: table.innerHTML };

    document.getElementById("dlink").href = uri + base64(format(template, ctx));
    document.getElementById("dlink").download = filename;
    document.getElementById("dlink").click();
  }

  render() {
    const { loadMore, limit, products } = this.props;
    const data = this.transform(products);
    return (
      <div>
        <Segment>
          <Button
            content="Export to Excel"
            icon="save"
            onClick={() =>
              this.tableToExcel(
                "master-list",
                "Product Master List",
                "Product-Master-List.xls"
              )
            }
          />
          {/* eslint-disable-next-line */}
          <a id="dlink" style={{ display: "none" }} />
        </Segment>
        <Table definition id="master-list">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell />
              <Table.HeaderCell>SKU</Table.HeaderCell>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>Description</Table.HeaderCell>
              <Table.HeaderCell>Category</Table.HeaderCell>
              <Table.HeaderCell>Size</Table.HeaderCell>
              <Table.HeaderCell>On Hand</Table.HeaderCell>
              <Table.HeaderCell>On Order</Table.HeaderCell>
              <Table.HeaderCell>Virtual On Hand</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data.map((row) => (
              <Table.Row key={row.id}>
                <Table.Cell collapsing>
                  <Button
                    as={Link}
                    to={`/admin/manage-products/edit/${row.productId}`}
                    basic
                    circular
                    icon="edit"
                    size="mini"
                  />
                </Table.Cell>
                <Table.Cell>{row.sku}</Table.Cell>
                <Table.Cell>{row.name}</Table.Cell>
                <Table.Cell>{row.description}</Table.Cell>
                <Table.Cell>{row.category}</Table.Cell>
                <Table.Cell>{row.size}</Table.Cell>
                <Table.Cell>{row.instock}</Table.Cell>
                <Table.Cell>{row.onorder}</Table.Cell>
                <Table.Cell>{row.virtualinstock}</Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        <Grid centered>
          <Grid.Column width={4}>
            <Button
              floated="right"
              basic
              circular
              fluid
              disabled={products ? products.length < limit : null}
              onClick={loadMore}
              content={
                products.length < limit ? "No more products." : "Next Page"
              }
            />
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}
