import React, { Component } from "react";
import {
  Form,
  Select,
  Input,
  Divider,
  Header,
  TextArea,
} from "semantic-ui-react";
import { THEME } from "../env";

export default class CheckoutShipping extends Component {
  state = {
    confirmAddr: false,
    confirmProducts: false,
  };

  componentDidMount() {
    const addr = this.props.addressOpts;
    if (addr.length === 1) {
      const selected = addr[0];
      if (!selected) return;
      this.props.handleAddressChange(null, selected);
    }
  }

  render() {
    const {
      addressOpts,
      handleAddressChange,
      handleChange,
      submitOrder,
      streetAddress,
      unit,
      city,
      province,
      country,
      postalCode,
      phone,
      email,
      isEventUser,
      comments
    } = this.props;

    const { confirmAddr, confirmProducts } = this.state;
    const eventUserDisableSubmit = isEventUser && !comments

    return (
      <Form onSubmit={submitOrder} style={{ padding: 10 }}>
        <Header as="h3">Shipping Information</Header>
        <Divider />
        <Form.Group widths="equal">
          <Form.Field
            label="Select Address"
            control={Select}
            search
            options={addressOpts}
            placeholder="Select Address"
            defaultValue={
              addressOpts.length === 1 ? addressOpts[0].value : undefined
            }
            onChange={handleAddressChange}
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field
            name="streetAddress"
            label="Street Address"
            control={Input}
            value={streetAddress}
            readOnly
          />
          <Form.Field
            name="unit"
            label="Unit"
            control={Input}
            value={unit}
            width={4}
            readOnly
          />
          <Form.Field
            name="postalCode"
            label="Postal Code"
            control={Input}
            value={postalCode}
            width={5}
            readOnly
          />
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field
            name="city"
            label="City"
            control={Input}
            value={city}
            readOnly
          />
          <Form.Field
            name="province"
            label="Province"
            control={Input}
            value={province}
            readOnly
          />
          <Form.Field
            name="country"
            label="Country"
            control={Input}
            value={country}
            readOnly
          />
        </Form.Group>
        <small>
          If an address appears incorrect or does not exist, please contact your
          Administrator.
        </small>
        <Header as="h3">Contact Information</Header>
        <Divider />
        <Form.Group widths="equal">
          <Form.Field
            name="email"
            label="Email"
            placeholder="Email"
            type="email"
            control={Input}
            value={email}
            onChange={handleChange}
          />
          <Form.Field
            name="phoneNumber"
            label="Phone Number"
            placeholder="Phone Number"
            control={Input}
            defaultValue={phone}
            onChange={handleChange}
          />
        </Form.Group>
        <Header as="h3">Order Comments</Header>
        <Divider />
        <Form.Group widths="equal">
          <Form.Field
            name="comments"
            label="Comments & Shipping Instructions"
            placeholder="Enter any comments or special instructions."
            control={TextArea}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group>
        {isEventUser && (
            <small>In Home / Events users, please enter your shipping address in the comments section.</small>
          )}
        </Form.Group>
        <Header as="h3">Confirm Order</Header>
        <Divider />
        <Form.Group>
          <Form.Checkbox
            label="I confirm the shipping address is accurate."
            value={confirmAddr}
            onChange={(e, data) => {
              this.setState({
                confirmAddr: data.checked,
              });
            }}
          />
          <Form.Checkbox
            label="I confirm the product, sizing, and quantity is accurate."
            value={confirmProducts}
            onChange={(e, data) => {
              this.setState({
                confirmProducts: data.checked,
              });
            }}
          />
        </Form.Group>
        <Form.Group>
          <Form.Button
            content="Submit Order"
            color={THEME === "freedom" ? "red" : "primary"}
            floated="right"
            disabled={!streetAddress || !confirmAddr || !confirmProducts ||
              eventUserDisableSubmit}
            onClick={submitOrder}
            type="submit"
          />
        </Form.Group>
      </Form>
    );
  }
}
