import React from "react";
import { Menu, Icon, Dropdown, Label, Container } from "semantic-ui-react";
import { Link, NavLink } from "react-router-dom";
// redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { logoutUser } from "../../actions/user";
import { toggleCart } from "../../actions/ui";
import freedomBrand from "../../logo-brand.svg";
import shawBrand from "../../shaw-logo.svg";

import "./index.css";
import { THEME } from "../../env";
import clsx from "clsx";

const Navbar = (props) => {
  const { user } = props;
  const { isLoggedIn, detail } = user;

  if (!isLoggedIn) {
    return null;
  }

  const { firstname, role, wallet } = detail;
  const isAdmin = role === "admin";
  const isCorporateUser = role === "corporate-user";
  const isCorporateManager = role === "corporate-manager";
  const isApprover = role === "approver" || role === "admin";
  const userDropdown = (
    <div>
      <Icon name="user outline" inverted={THEME === "freedom"} /> {firstname}
    </div>
  );
  const { cart, cartSum } = props.product;

  return (
    <Menu
      className={clsx(THEME, "Navbar")}
      inverted={THEME === "freedom"}
      size="massive"
      secondary
      pointing
      fixed="top"
    >
      <Container>
        <Menu.Item className="Navbar-item Navbar-brand">
          <img
            src={THEME === "shaw" ? shawBrand : freedomBrand}
            alt="freedom logo"
          />
        </Menu.Item>
        <Menu.Item as={NavLink} to="/" className="Navbar-item" exact>
          Shop
        </Menu.Item>
        {isLoggedIn ? (
          <Menu.Menu position="right">
            {isAdmin && (
              <Menu.Item
                as={NavLink}
                className="Navbar-item"
                to="/admin/dashboard"
              >
                Admin
              </Menu.Item>
            )}

            {isCorporateManager && (
              <Menu.Item
                as={NavLink}
                to="/corporate-manager"
                className="Navbar-item"
              >
                Manager
              </Menu.Item>
            )}

            {isApprover && (
              <Menu.Item as={NavLink} to="/approver" className="Navbar-item">
                Approver{" "}
                {props.product.approverOrders.length > 0 && (
                  <Label color="blue" empty circular size="mini" />
                )}
              </Menu.Item>
            )}

            <Menu.Item as={NavLink} to="/view-orders" className="Navbar-item">
              My Orders
            </Menu.Item>
            <Menu.Item
              onClick={props.toggleCart}
              as="a"
              className="Navbar-item"
            >
              <Icon.Group size="large">
                <div className="icon-bag" />
                {cart.length > 0 && <Icon corner name="circle" color="blue" />}
              </Icon.Group>
              {cart.length ? cartSum : null}
            </Menu.Item>
            <Dropdown
              icon={userDropdown}
              pointing
              className="Navbar-item Navbar-item-icon Navbar-item-user"
            >
              <Dropdown.Menu>
                {isCorporateUser && (
                  <>
                    <Dropdown.Item>Credits: {wallet || 0}</Dropdown.Item>
                    <Dropdown.Divider />
                  </>
                )}
                <Dropdown.Item as={NavLink} to="/my-profile">
                  My Profile
                </Dropdown.Item>
                <Dropdown.Item onClick={props.logoutUser}>Logout</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Menu.Menu>
        ) : (
          <Menu.Menu position="right">
            <Menu.Item as={Link} to="/login">
              Login
            </Menu.Item>
          </Menu.Menu>
        )}
      </Container>
    </Menu>
  );
};

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      logoutUser,
      toggleCart,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
