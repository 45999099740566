import React, { useState } from "react";
import {
  Segment,
  Table,
  Button,
  Divider,
  Label,
  Form,
  Modal,
} from "semantic-ui-react";
import makeGroupedPackingSlip, {
  makeGroupedPackingSlips,
} from "../common/groupedPdf";

const statusColor = (status) => {
  switch (status) {
    case "shipped":
      return "green";
    case "approved":
      return "blue";
    case "pending":
      return "orange";
    case "rejected":
      return "red";
    case "cancelled":
      return null;
    default:
      return null;
  }
};

const ShipToModal = (props) => {
  const { open, onClose, onConfirm } = props;
  const [courier, setCourier] = useState();
  const [tracking, setTracking] = useState();
  return (
    <Modal open={open} size="mini" onClose={onClose}>
      <Modal.Content>
        <Form>
          <Form.Input
            label="Courier"
            placeholder="Courier"
            onChange={(e, data) => setCourier(data.value)}
            value={courier}
          />
          <Form.Input
            label="Tracking"
            placeholder="Tracking"
            onChange={(e, data) => setTracking(data.value)}
            value={tracking}
          />
          <Form.Group>
            <Form.Button type="button" onClick={onClose}>
              Cancel
            </Form.Button>
            <Form.Button
              color="green"
              onClick={() => onConfirm(courier, tracking)}
            >
              Confirm
            </Form.Button>
          </Form.Group>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

export const GroupedOrder = (props) => {
  const { order, shipOrder } = props;

  const [isShipOpen, setIsShipOpen] = useState(false);

  const {
    status,
    shipToName,
    streetAddress,
    unit,
    city,
    province,
    country,
    postalCode,
    orderItems,
  } = order;

  const handleShip = async (courier, tracking) => {
    await shipOrder(
      orderItems.map((o) => o.orderId),
      courier,
      tracking
    );
    setIsShipOpen(false);
  };

  return (
    <Segment color={statusColor(status)}>
      <Table attached>
        <Table.Header>
          <Table.HeaderCell>Status</Table.HeaderCell>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.HeaderCell>Address</Table.HeaderCell>
          <Table.HeaderCell>Unit</Table.HeaderCell>
          <Table.HeaderCell>City</Table.HeaderCell>
          <Table.HeaderCell>Province</Table.HeaderCell>
          <Table.HeaderCell>Country</Table.HeaderCell>
          <Table.HeaderCell>PostalCode</Table.HeaderCell>
        </Table.Header>
        <Table.Body>
          <Table.Cell>
            <Label ribbon color={statusColor(status)} basic>
              {status}
            </Label>
          </Table.Cell>
          <Table.Cell>{shipToName}</Table.Cell>
          <Table.Cell>{streetAddress}</Table.Cell>
          <Table.Cell>{unit}</Table.Cell>
          <Table.Cell>{city}</Table.Cell>
          <Table.Cell>{province}</Table.Cell>
          <Table.Cell>{country}</Table.Cell>
          <Table.Cell>{postalCode}</Table.Cell>
        </Table.Body>
      </Table>
      <Table attached>
        <Table.Header>
          <Table.HeaderCell>Order#</Table.HeaderCell>
          <Table.HeaderCell>Product</Table.HeaderCell>
          <Table.HeaderCell>Sku</Table.HeaderCell>
          <Table.HeaderCell>Size</Table.HeaderCell>
          <Table.HeaderCell>QTY</Table.HeaderCell>
          <Table.HeaderCell>Email</Table.HeaderCell>
          <Table.HeaderCell>Comment</Table.HeaderCell>
        </Table.Header>
        <Table.Body>
          {orderItems.map((o) => {
            return (
              <Table.Row key={o.orderNum + o.sku}>
                <Table.Cell>{o.orderNum}</Table.Cell>
                <Table.Cell>{o.name}</Table.Cell>
                <Table.Cell>{o.sku}</Table.Cell>
                <Table.Cell>{o.size}</Table.Cell>
                <Table.Cell>{o.qty}</Table.Cell>
                <Table.Cell>{o.email}</Table.Cell>
                <Table.Cell>{o.comment}</Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
      <Divider />
      <Button
        content="Packing Slip"
        onClick={() => makeGroupedPackingSlip(order)}
      />
      <Button
        content="Ship"
        floated="right"
        color="green"
        onClick={() => setIsShipOpen(true)}
      />
      <ShipToModal
        open={isShipOpen}
        onClose={() => setIsShipOpen(false)}
        onConfirm={handleShip}
      />
    </Segment>
  );
};

export const ManageOrdersGrouped = (props) => {
  return (
    <div>
      <Button
        content="Export All Grouped"
        onClick={() => makeGroupedPackingSlips(props.ordersGrouped)}
      />
      {props.ordersGrouped.map((o) => (
        <GroupedOrder
          key={o.shipToName}
          order={o}
          shipOrder={props.shipOrder}
        />
      ))}
    </div>
  );
};
