import React from "react";

import { Form, Checkbox, Input, Select } from "semantic-ui-react";

export default ({
  mode,
  name,
  description,
  enabled,
  categoryId,
  productType,
  categories,
  isEdited,
  saveBaseProduct,
  handleChange,
  roles,
}) => {
  let categoryOptions = categories.map(({ id, name }) => {
    return { key: id, text: name, value: id };
  });
  categoryOptions = [
    { key: "no", text: "No Category", value: null },
    ...categoryOptions,
  ];

  const productTypeOptions = [
    { text: "Physical", value: "physical" },
    { text: "Virtual", value: "virtual" },
  ];
  return (
    <Form onSubmit={(e) => saveBaseProduct(e)}>
      <Form.Group>
        <Form.Button
          disabled={!isEdited}
          positive
          basic
          onClick={(e) => saveBaseProduct(e)}
        >
          Save Base Product
        </Form.Button>
        <Form.Field
          control={Checkbox}
          label={enabled ? "Enabled" : "Disabled"}
          toggle
          checked={enabled}
          name="enabled"
          onChange={handleChange}
        />
      </Form.Group>
      <Form.Group widths="equal">
        <Form.Field
          control={Input}
          label="Name"
          placeholder="Name"
          value={name}
          name="name"
          onChange={handleChange}
        />
        <Form.Field
          control={Input}
          label="Description"
          placeholder="Description"
          value={description}
          name="description"
          onChange={handleChange}
        />
        <Form.Field
          control={Select}
          label="Category"
          placeholder="Category"
          value={categoryId}
          options={categoryOptions}
          name="categoryId"
          onChange={handleChange}
        />
        <Form.Field
          control={Select}
          label="Product Type"
          placeholder="Product Type"
          value={productType}
          defaultValue="physical"
          options={productTypeOptions}
          name="productType"
          onChange={handleChange}
          disabled={mode === "edit"}
        />
      </Form.Group>
      <Form.Group>
        <Form.Field
          control={Select}
          label="Role Access Control"
          placeholder="Role Access"
          defaultValue={["all"]}
          multiple
          selection
          name="roles"
          value={roles || []}
          onChange={handleChange}
          style={{ zIndex: 101 }}
          options={[
            {
              text: "All",
              value: "all",
            },
            {
              text: "Admin",
              value: "admin",
            },
            {
              text: "Dealer",
              value: "user",
            },
            {
              text: "Dealer Approver",
              value: "approver",
            },
            {
              text: "Corp Manager",
              value: "corporate-manager",
            },
            {
              text: "Corp Employee / All",
              value: "corporate-user",
            },
            {
              text: "Corp Employee / FT",
              value: "corporate-user/ft",
            },
            {
              text: "Corp Employee / PT",
              value: "corporate-user/pt",
            },
            {
              text: "Corp Employee / EV",
              value: "corporate-user/ev",
            },
          ]}
        />
      </Form.Group>
    </Form>
  );
};
