// @ts-check
import React, { Component } from "react";
import { Container, Grid, Dimmer, Loader } from "semantic-ui-react";
import CheckoutRail from "../components/CheckoutRail";
import CheckoutShipping from "../components/CheckoutShipping";
// redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { removeFromCart, addToCart, submitOrder } from "../actions/product";
import { toggleCart } from "../actions/ui";
import { getMe } from "../actions/user";

class CheckoutPage extends Component {
  state = {
    email: this.props.user.detail.email,
  };
  componentWillMount() {
    if (this.props.ui.toggleCart) {
      this.props.toggleCart();
    }
    this.props.getMe();
  }

  componentDidUpdate() {
    const orderNum = this.props.product.singleOrder.orderNum;
    if (orderNum) {
      this.props.history.push("/view-orders");
    }
  }

  addToCart(id, qty) {
    this.props.addToCart(
      {
        userId: this.props.user.detail.id,
        productVariantId: id,
        qty,
      },
      true
    );
  }

  handleChange(e, data) {
    this.setState({
      [data.name]: data.value,
    });
  }

  handleAddressChange(e, data) {
    this.setState({
      ...data.value,
    });
  }
  onSubmit(e) {
    e.preventDefault();
    let details = this.state;
    details.email = !details.email ? this.props.user.detail.email : null;
    delete details.contextRef;
    this.props.submitOrder(details);
  }

  render() {
    const { contextRef } = this.state;
    const { product, user, removeFromCart } = this.props;
    const { cart, isCartLoading, isOrderLoading } = product;
    let { shipAddresses, requesters, role, hourlyType } = user.detail;

    const isCorporateUser = role === "corporate-user";
    const isEventUser = isCorporateUser && hourlyType === "EV"
    const state = this.state;
    if (requesters) {
      shipAddresses =
        user.detail.role === "approver"
          ? requesters.reduce((acc, val) => {
              let shipAddresses = val.shipAddresses.map((add) => {
                return {
                  ...add,
                  username: val.username,
                };
              });
              return [...acc, ...shipAddresses];
            }, [])
          : shipAddresses;
    }
    const addressOpts = shipAddresses
      ? shipAddresses.map((add) => {
          return {
            key: add.id,
            text: `${add.username ? add.username + " / " : ""}${add.name} - ${
              add.streetAddress
            }`,
            value: add,
          };
        })
      : null;
    return (
      <Container>
        <Dimmer active={isOrderLoading || isCartLoading} inverted blurring page>
          <Loader active={isOrderLoading || isCartLoading}>Loading...</Loader>
        </Dimmer>
        <Grid>
          <Grid.Row>
            <Grid.Column computer={10} tablet={16} mobile={16}>
              {addressOpts && (
                <CheckoutShipping
                  addressOpts={addressOpts}
                  handleAddressChange={this.handleAddressChange.bind(this)}
                  handleChange={this.handleChange.bind(this)}
                  submitOrder={this.onSubmit.bind(this)}
                  isEventUser={isEventUser}
                  {...state}
                />
              )}
            </Grid.Column>
            <CheckoutRail
              contextRef={contextRef}
              cart={cart}
              removeFromCart={removeFromCart}
              addToCart={this.addToCart.bind(this)}
              isCorporateUser={isCorporateUser}
            />
          </Grid.Row>
        </Grid>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { removeFromCart, addToCart, toggleCart, getMe, submitOrder },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutPage);
