// @ts-check
import React, { Component } from "react";

import { Header, Divider, Grid } from "semantic-ui-react";
import AdminLayout from "../components/AdminLayout";

// redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  adminGetProductOptions,
  adminGetOrderDetailReport,
} from "../actions/admin";
import { OrderMasterListTable } from "../components/OrderMasterListTable";

class OrderMasterList extends Component {
  render() {
    const { isProductTokensLoading } = this.props.admin;
    return (
      <AdminLayout isLoading={isProductTokensLoading}>
        <Header as="h3">Order Detail Report </Header>
        <Divider />

        <Grid>
          <Grid.Row>
            <Grid.Column>
              <OrderMasterListTable
                adminGetProductOptions={this.props.adminGetProductOptions}
                adminGetOrderDetailReport={this.props.adminGetOrderDetailReport}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </AdminLayout>
    );
  }
}

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      adminGetProductOptions,
      adminGetOrderDetailReport,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderMasterList);
