import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import { store } from "./store";

// styles
import "simple-line-icons/css/simple-line-icons.css";
import "./index.css";

(async () => {
  const appStyles = process.env.REACT_APP_STYLE;

  if (appStyles === "shaw") {
    await import("@dkintl/semantic-ui-shaw/dist/semantic.css");
  } else {
    await import("@dkintl/semantic-ui/dist/semantic.css");
  }

  ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,
    document.getElementById("root")
  );
})();
