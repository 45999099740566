import React, { Component } from "react";

import { Container, Grid, Dimmer, Loader } from "semantic-ui-react";
import AdminMenu from "../components/AdminMenu";

export default class AdminLayout extends Component {
  render() {
    const { isLoading, children } = this.props;
    return (
      <div>
        <Container fluid>
          <Dimmer active={isLoading} inverted page>
            <Loader active={isLoading}>Loading...</Loader>
          </Dimmer>
          <Grid>
            <Grid.Row columns="equal">
              <Grid.Column width={3} className="no-print">
                <AdminMenu />
              </Grid.Column>
              <Grid.Column>{children}</Grid.Column>
            </Grid.Row>
          </Grid>
        </Container>
      </div>
    );
  }
}
