import React, { useEffect, useState, useCallback } from "react";
import { Table, Button, Form, ButtonGroup, Grid } from "semantic-ui-react";
import DatePicker from "react-datepicker";
import moment from "moment";
import { startCase } from "lodash";
const NEXT = 1;
const PREV = -1;

const DEFAULT_PAGINATION = {
  offset: 0,
  limit: 50,
};

export const OrderMasterListTable = (props) => {
  const { adminGetOrderDetailReport, adminGetProductOptions } = props;

  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);

  const [filterDate, setFilterDate] = useState({
    startDate: moment().startOf("month"),
    endDate: moment().endOf("month"),
  });

  // const [productTokenOnly, setProductTokensOnly] = useState(null);

  const handleDateChange = (date, name) => {
    setFilterDate((state) => ({ ...state, [name]: date }));
  };

  const [currentData, setCurrentData] = useState([]);

  const handleGetData = async ({
    offset,
    limit,
    productId,
    startDate,
    endDate,
    // productTokenOnly,
  }) => {
    const data = await adminGetOrderDetailReport({
      offset,
      limit,
      productId,
      createdAtStart: startDate,
      createdAtEnd: endDate,
      // productTokenOnly,
    });
    setCurrentData(data);
  };

  const [productOptions, setProductOptions] = useState([]);

  const handleGetProductOptions = useCallback(async () => {
    const productOpts = await adminGetProductOptions(null);
    const productOptions = productOpts.map((p) => ({
      key: p.id,
      text: p.name,
      value: { name: p.name, id: p.id },
    }));

    setProductOptions(productOptions);
  }, [adminGetProductOptions]);

  const [selectedProduct, setSelectedProduct] = useState({
    id: "all",
    name: "All Products",
  });

  const productId =
    selectedProduct.id === "all" ? undefined : selectedProduct.id;

  const handleOnSearch = (resetPagination = false) => {
    let nextPagination = { ...pagination };
    if (resetPagination) {
      nextPagination = DEFAULT_PAGINATION;
      setPagination(DEFAULT_PAGINATION);
    }

    handleGetData({
      ...nextPagination,
      ...filterDate,
      productId,
      // productTokenOnly,
    });
  };

  const handlePagination = (direction) => {
    const nextOffset = pagination.offset + pagination.limit * direction;
    setPagination({ ...pagination, offset: nextOffset });
    return handleOnSearch();
  };

  const handleOnExport = async () => {
    const data = await adminGetOrderDetailReport({
      offset: 0,
      limit: 5000,
      productId,
      createdAtStart: filterDate.startDate,
      createdAtEnd: filterDate.endDate,
      // productTokenOnly,
    });

    const csv = [
      Object.keys(currentData[0])
        .map((v) => `"${v}"`)
        .join(","),
    ];

    for (const record of data) {
      csv.push(
        Object.values(record)
          .map((v) => `"${v}"`)
          .join(",")
      );
    }

    const csvFile = new Blob([csv.join("\n")], { type: "text/csv" });
    const downloadLink = document.createElement("a");
    downloadLink.download = "order-detail-report.csv";
    downloadLink.href = window.URL.createObjectURL(csvFile);
    downloadLink.style.display = "none";
    document.body.appendChild(downloadLink);
    downloadLink.click();
  };

  useEffect(() => {
    handleGetProductOptions();
  }, [handleGetProductOptions]);

  return (
    <div>
      <Form>
        <Form.Group>
          <Form.Select
            label="Filter By Product"
            name="product"
            options={[
              {
                key: "all",
                text: "All Products",
                value: { id: "all", name: "All Products" },
              },
              ...productOptions,
            ]}
            value={selectedProduct}
            onChange={(e, data) => setSelectedProduct(data.value)}
          />

          <Form.Field>
            <label>Ordered Start Date</label>
            <DatePicker
              name="startDate"
              dateFormat="YYYY-MM-DD"
              selected={filterDate.startDate}
              startDate={filterDate.startDate}
              endDate={filterDate.endDate}
              onChange={(date) => handleDateChange(date, "startDate")}
              showMonthDropdown
              showYearDropdown
              monthsShown={2}
              className="ui input"
            />
          </Form.Field>
          <Form.Field>
            <label>Ordered End Date</label>
            <DatePicker
              dateFormat="YYYY-MM-DD"
              selected={filterDate.endDate}
              startDate={filterDate.startDate}
              endDate={filterDate.endDate}
              onChange={(date) => handleDateChange(date, "endDate")}
              showMonthDropdown
              showYearDropdown
              monthsShown={2}
              className="ui input"
            />
          </Form.Field>

          {/* <Form.Checkbox
            label="Filter Product Tokens Only"
            checked={productTokenOnly}
            onClick={() => {
              if (productTokenOnly) {
                return setProductTokensOnly(null);
              }
              return setProductTokensOnly(1);
            }}
          /> */}

          <Form.Button
            label="Search"
            content="Search"
            primary
            onClick={() => handleOnSearch(true)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Button
            label="Export"
            content="Export"
            icon="save"
            onClick={handleOnExport}
          />
        </Form.Group>
      </Form>

      <Grid>
        <Grid.Column width={16}>
          <ButtonGroup floated="right">
            <Button
              icon="left arrow"
              basic
              disabled={pagination.offset === 0}
              onClick={() => handlePagination(PREV)}
            />
            <Button
              icon="right arrow"
              basic
              disabled={currentData.length !== pagination.limit}
              onClick={() => handlePagination(NEXT)}
            />
          </ButtonGroup>
        </Grid.Column>
        <Grid.Column style={{ overflowX: "auto" }} width="16">
          <Table compact="very" size="small">
            <Table.Header>
              <Table.Row>
                {Object.keys(currentData[0] || {}).map((v) => {
                  return (
                    <Table.HeaderCell key={v}>{startCase(v)}</Table.HeaderCell>
                  );
                })}
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {currentData.map((t) => {
                return (
                  <Table.Row key={t.orderNum + t.sku}>
                    {Object.entries(t).map(([k, v]) => {
                      const key = k.toLowerCase();
                      if (
                        key.indexOf("createdat") > -1 ||
                        key.indexOf("updatedat") > -1
                      ) {
                        v = new Date(v).toLocaleString();
                      }
                      return <Table.Cell key={k}>{v}</Table.Cell>;
                    })}
                  </Table.Row>
                );
              })}
              {!currentData.length && (
                <Table.Row>
                  <Table.Cell>No data</Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
        </Grid.Column>
      </Grid>
    </div>
  );
};
