import jwtDecode from "jwt-decode";
import { TYPES } from "../actions/user";

const token = localStorage.getItem("Auth") || null;
let role, id;
if (token) {
  const detail = jwtDecode(token) || {};
  role = detail.role;
  id = detail.id;
}

const initialState = {
  token,
  isLoggedIn: !!token,
  detail: { role, id },
  loginMessage: null,
  pwReset: false,
  locations: [],
  managers: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TYPES.USER_LOGOUT:
      localStorage.removeItem("Auth");
      return {
        token: null,
        isLoggedIn: false,
        detail: {},
      };
    case TYPES.USER_LOGIN:
      let token = action.payload.token;
      localStorage.Auth = token;
      return {
        token,
        isLoggedIn: true,
        detail: jwtDecode(token),
      };
    case TYPES.SET_LOGIN_MESSAGE:
      return {
        ...state,
        loginMessage: action.payload,
      };
    case TYPES.DEL_LOGIN_MESSAGE:
      return {
        ...state,
        loginMessage: null,
      };
    case TYPES.GET_ME:
      return {
        ...state,
        detail: action.payload,
        loginMessage: null,
      };
    case TYPES.PASSWORD_RESET:
      return {
        ...state,
        pwReset: true,
      };
    case TYPES.VERIFY_USER:
      return {
        ...state,
        isLoggedIn: true,
        detail: jwtDecode(action.payload),
        token: action.payload,
      };
    case TYPES.GET_LOCATIONS:
      return {
        ...state,
        locations: action.payload,
      };
    case TYPES.GET_MANAGERS:
      return {
        ...state,
        managers: action.payload,
      };
    default:
      return state;
  }
};
