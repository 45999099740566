import React, { Component } from "react";
import {
  Segment,
  Sidebar,
  Button,
  Header,
  Divider,
  Icon,
  Item,
  Dimmer,
  Loader,
  Message,
} from "semantic-ui-react";
import CartItem from "./CartItem";
import { Link } from "react-router-dom";
// redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { toggleCart } from "../actions/ui";
import {
  removeFromCart,
  addToCart,
  clearSingleOrder,
} from "../actions/product";
import { THEME } from "../env";

class CartSidebar extends Component {
  addToCart(id, qty) {
    this.props.addToCart(
      {
        userId: this.props.user.detail.id,
        productVariantId: id,
        qty,
      },
      true
    );
  }

  render() {
    const {
      user: {
        detail: { role, wallet },
      },
    } = this.props;

    const isCorporateUser = role === "corporate-user";
    const visible = this.props.ui.toggleCart;
    const { cart, isCartLoading, cartSum } = this.props.product;
    const remainingCredits = wallet - cartSum;
    const isRemCreditsNegative = isCorporateUser ? remainingCredits < 0 : false;
    const cartNeedsAttention =
      cart.filter(
        ({ qty, v_productVariant }) => qty > v_productVariant.virtualinstock
      ).length > 0 || isRemCreditsNegative;
    const cartItems =
      cart.length > 0 ? (
        cart.map((cartItem) => (
          <CartItem
            key={cartItem.id}
            product={cartItem}
            itemNeedsAttention={
              cartItem.qty > cartItem.v_productVariant.virtualinstock
            }
            removeFromCart={this.props.removeFromCart}
            addToCart={this.addToCart.bind(this)}
            isCorporateUser={isCorporateUser}
          />
        ))
      ) : (
        <Header as="h4" textAlign="center">
          No Items in Cart
        </Header>
      );
    return (
      <Sidebar
        as={Segment}
        animation="overlay"
        width="very wide"
        direction="right"
        visible={visible}
        vertical
        style={{ backgroundColor: "white", padding: 25 }}
      >
        <Button
          basic
          color="grey"
          icon="close"
          onClick={this.props.toggleCart}
          floated="right"
          size="mini"
        />

        <Header as="h4" textAlign="center" style={{ margin: 0 }}>
          <Icon size="large">
            <div className="icon-bag" />
          </Icon>
          Shopping Bag
        </Header>
        <Divider />
        <Dimmer inverted active={isCartLoading}>
          <Loader active={isCartLoading} />
        </Dimmer>
        <Item.Group>{cartItems}</Item.Group>
        <div className="bag-checkout-button">
          <Divider />
          <p>
            <strong>
              Cart Quantity: {cartSum} <br />
              {isCorporateUser && (
                <>
                  Credits: {wallet}
                  <br />
                  Remaining Credits: {remainingCredits}
                </>
              )}
            </strong>
          </p>
          {cartNeedsAttention && (
            <Message error>
              {isRemCreditsNegative ? (
                <>You do not have enough credits, please edit your cart.</>
              ) : (
                <>A product in your cart is no longer available.</>
              )}
            </Message>
          )}
          <Button
            as={Link}
            to="/checkout"
            fluid
            primary
            size="large"
            disabled={cartNeedsAttention || !cart.length}
            content={cartNeedsAttention ? "Attention Required" : "Checkout"}
            onClick={this.props.clearSingleOrder}
            circular={THEME === "shaw"}
          />
        </div>
      </Sidebar>
    );
  }
}

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { toggleCart, removeFromCart, addToCart, clearSingleOrder },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CartSidebar);
