import thunkMiddleware from 'redux-thunk'
import { createStore, applyMiddleware, compose } from 'redux'
import { routerMiddleware } from 'react-router-redux'
import createHistory from 'history/createBrowserHistory'

import rootReducer from './reducers'

let store
let composeEnhancers

//dev
const history = createHistory()
const routeMiddleware = routerMiddleware(history)

if (process.env.NODE_ENV === 'development') {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
  store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(thunkMiddleware, routeMiddleware))
  )
} else {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
  store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(thunkMiddleware, routeMiddleware))
  )
}

export { store, history }
