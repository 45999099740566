import React, { useEffect, useState } from "react";
import { Button, Form, List } from "semantic-ui-react";

export const EditIntakeForm = (props) => {
  const { name, onChange, intakeForm } = props;
  const [intakeFormFields, setIntakFormFields] = useState(intakeForm || []);
  const [formFieldName, setFormFieldName] = useState("");
  const [formFieldType, setFormFieldType] = useState("text");
  const [formFieldSelectedOptions, setFormFieldSelectedOptions] = useState("");
  const [formFieldOptions, setFormFieldOptions] = useState([]);

  useEffect(() => {
    if (intakeForm) {
      setIntakFormFields(intakeForm);
    }
  }, [intakeForm]);

  const handleChange = (nextFormFields) => {
    onChange(null, { name, value: nextFormFields });
  };

  const handleAddFormField = () => {
    const nextFormFields = [
      ...intakeFormFields,
      { name: formFieldName, type: formFieldType, options: formFieldOptions },
    ];
    setIntakFormFields(nextFormFields);
    setFormFieldName("");
    handleChange(nextFormFields);
  };

  const handleRemoveFormField = (idx) => {
    const nextArr = Array.from(intakeFormFields);
    nextArr.splice(idx, 1);
    setIntakFormFields(nextArr);
    handleChange(nextArr);
  };

  const isDisabled =
    !formFieldName ||
    (formFieldType === "select" && !formFieldSelectedOptions.length);

  return (
    <>
      <Form>
        <Form.Group>
          <Form.Input
            name="fieldName"
            label="Field Name"
            placeholder="Field Name"
            onChange={(e, data) => setFormFieldName(data.value)}
            value={formFieldName}
          />
          <Form.Select
            name="type"
            label="Field Type"
            placeholder="Field Type"
            onChange={(e, data) => setFormFieldType(data.value)}
            value={formFieldType}
            options={[
              {
                text: "Text",
                value: "text",
              },
              {
                text: "Select",
                value: "select",
              },
            ]}
          />
          {formFieldType === "select" && (
            <Form.Dropdown
              width="5"
              options={formFieldOptions}
              name="fieldOptions"
              label="Field Options (CSV)"
              placeholder="Option1,Option2..."
              search
              selection
              fluid
              multiple
              allowAdditions
              value={formFieldSelectedOptions.split(",")}
              onChange={(e, data) =>
                setFormFieldSelectedOptions(data.value.join(","))
              }
              onAddItem={(e, data) =>
                setFormFieldOptions((a) => [
                  ...a,
                  { text: data.value, value: data.value },
                ])
              }
            />
          )}
          <Form.Button
            label={"Add"}
            icon="plus"
            color="success"
            onClick={handleAddFormField}
            disabled={isDisabled}
          />
        </Form.Group>
      </Form>
      <List>
        {intakeFormFields?.map((v, idx) => (
          <List.Item key={v.name + idx}>
            <span style={{ marginRight: 15 }}>{v.name}</span>
            {v.options && (
              <span style={{ marginRight: 15 }}>
                ({v.options?.map((o) => o.value).join(", ")})
              </span>
            )}

            <Button
              icon="trash"
              onClick={() => handleRemoveFormField(idx)}
              size="mini"
            />
          </List.Item>
        ))}
      </List>
    </>
  );
};
