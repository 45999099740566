//@ts-check
import axios from "axios";
import { logoutUser } from "../actions/user";
const basename = process.env.PUBLIC_URL || "";

export const api = (options) => {
  const token = localStorage.Auth || null;
  options.url = `${basename}${options.url}`;
  options.headers = {
    ...options.headers,
    Auth: token,
  };
  options.validateStatus = (status) => status < 399;
  return axios(options);
};

export default async (options, dispatch, type) => {
  try {
    dispatch({ type: `REQUEST_SENT`, options });
    dispatch({ type: `R_${type}` });
    const result = await api(options);
    dispatch({ type: `REQUEST_SUCCESS` });
    return result;
  } catch (err) {
    if (typeof err.response !== "undefined") {
      dispatch({ type: `REQUEST_ERROR`, payload: err });
      let { status } = err.response;
      switch (status) {
        case 401:
          logoutUser()(dispatch);
          break;
        case 404:
          break;
        default:
          return err.response;
      }
      return err.response;
    }
  }
};
