import React, { Component } from 'react'
import { Header, Divider, Button, Table, Form, Input } from 'semantic-ui-react'
import AdminLayout from '../components/AdminLayout'
// redux
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  adminGetCategories,
  adminCreateCategory,
  adminEditCategory,
  adminDeleteCategory
} from '../actions/admin'

class ManageCategories extends Component {
  state = {
    id: null,
    name: '',
    description: ''
  }
  componentWillMount() {
    this.props.adminGetCategories()
  }
  handleChange(e, data) {
    this.setState({
      [data.name]: data.value
    })
  }
  selectCategory(category) {
    this.setState(category)
  }
  editCategory() {
    this.props.adminEditCategory(this.state)
    this.setState({
      id: null,
      name: '',
      description: ''
    })
  }
  createCategory() {
    const { name, description } = this.state
    this.props.adminCreateCategory({ name, description })
    this.setState({
      id: null,
      name: '',
      description: ''
    })
  }
  render() {
    const { categories, isProductsLoading } = this.props.admin
    const { id, name, description } = this.state
    const isComplete = name
    return (
      <AdminLayout isLoading={isProductsLoading}>
        <Header as="h3">Manage Categories</Header>
        <Divider />
        <Table definition>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Action</Table.HeaderCell>
              <Table.HeaderCell>Category</Table.HeaderCell>
              <Table.HeaderCell>Description</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            <Table.Row>
              <Table.Cell collapsing>
                <Button
                  circular
                  icon="add"
                  color="green"
                  basic
                  fluid
                  onClick={
                    id
                      ? this.editCategory.bind(this)
                      : this.createCategory.bind(this)
                  }
                  disabled={!isComplete}
                />
              </Table.Cell>
              <Table.Cell>
                <Form.Field
                  control={Input}
                  placeholder="Category"
                  fluid
                  name="name"
                  value={name}
                  onChange={this.handleChange.bind(this)}
                />
              </Table.Cell>
              <Table.Cell>
                <Form.Field
                  control={Input}
                  placeholder="Description"
                  fluid
                  name="description"
                  value={description}
                  onChange={this.handleChange.bind(this)}
                />
              </Table.Cell>
            </Table.Row>
            {categories
              .filter(({ id }) => id !== this.state.id)
              .map(({ id, name, description }) =>
                <Table.Row>
                  <Table.Cell>
                    <Button.Group>
                      <Button
                        icon="edit"
                        basic
                        circular
                        color="grey"
                        onClick={() =>
                          this.selectCategory({ id, name, description })}
                      />
                      <Button
                        icon="delete"
                        basic
                        color="red"
                        circular
                        onClick={() => this.props.adminDeleteCategory({ id })}
                      />
                    </Button.Group>
                  </Table.Cell>
                  <Table.Cell>
                    {name}
                  </Table.Cell>
                  <Table.Cell>
                    {description}
                  </Table.Cell>
                </Table.Row>
              )}
          </Table.Body>
        </Table>
      </AdminLayout>
    )
  }
}

const mapStateToProps = state => {
  return state
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      adminGetCategories,
      adminCreateCategory,
      adminEditCategory,
      adminDeleteCategory
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageCategories)
