//@ts-check
import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { Dimmer } from "semantic-ui-react";

// Layout
import Navbar from "./components/Navbar/Navbar";
import CartSidebar from "./components/CartSidebar";
import ErrorBox from "./components/ErrorBox";

// Pages
import LoginPage from "./pages/Login";
import CatalogPage from "./pages/Catalog";
import CheckoutPage from "./pages/Checkout";
import ViewSingleOrder from "./pages/ViewSingleOrder";
import ViewOrdersPage from "./pages/ViewOrders";
import MyProfile from "./pages/MyProfile";

// Approver Pages
import ApproverPage from "./pages/Approver";

// Admin Pages
import ManageProducts from "./pages/ManageProducts";
import ManageCategories from "./pages/ManageCategories";
import EditProduct from "./pages/EditProduct";
import CreateProduct from "./pages/CreateProduct";
import CreateUser from "./pages/CreateUser";
import ManageUsers from "./pages/ManageUsers";
import ManageOrders from "./pages/ManageOrders";
import EditUser from "./pages/EditUser";
import Dashboard from "./pages/Dashboard";
import ManageSizeGuide from "./pages/ManageSizeGuide";
import ManageProductTokens from "./pages/ManageProductTokens";
import ManageShipAddresses from "./pages/ManageShipAddresses";
import { ProductPage } from "./pages/ProductPage";
// Actions
import { logoutUser, getMe } from "./actions/user";
import { toggleCart, dismissError } from "./actions/ui";
import { getCart } from "./actions/product";
import { getApproverOrders } from "./actions/product";
import { RegisterPage } from "./pages/Register";
import { VerifyPage } from "./pages/VerifyPage";
import OrderMasterList from "./pages/OrderMasterList";
import { CorporateManger } from "./pages/CorporateManager";
import ManageVirtualOrders from "./pages/ManageVirtualOrders";

const PrivateRoute = ({
  component: Component,
  redirect,
  isLoggedIn,
  isAdmin,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => {
      // render login if the user is not logged in
      if (!isLoggedIn) {
        return (
          <Redirect
            to={{
              pathname: redirect,
              state: { from: props.location },
            }}
          />
        );
      }

      if (props.location.pathname.indexOf("admin") > -1 && !isAdmin) {
        return (
          <Redirect
            to={{
              pathname: "/",
            }}
          />
        );
      }

      return <Component {...props} />;
    }}
  />
);

class App extends Component {
  componentDidMount() {
    if (this.props.user.isLoggedIn) {
      this.props.getCart();
      this.props.getMe();
      if (
        this.props.user.role === "approver" ||
        this.props.user.role === "corporate-manager"
      ) {
        this.props.getApproverOrders({
          offset: 0,
          limit: 1,
          status: "pending",
        });
      }
    }
  }
  render() {
    const { error } = this.props.ui;
    const { isLoggedIn, detail } = this.props.user;
    const { role } = detail;
    const isAdmin = role === "admin";
    const basename = process.env.PUBLIC_URL;

    return (
      <Router basename={basename}>
        <div>
          <Route
            component={(props) => <Navbar {...props} isLoggedIn={isLoggedIn} />}
          />
          <div style={{ marginTop: 120, marginBottom: 80 }}>
            <Route
              component={(props) => (
                <ErrorBox
                  {...props}
                  error={error}
                  dismissError={this.props.dismissError}
                  isLoggedIn={isLoggedIn}
                />
              )}
            />

            <Route component={CartSidebar} />
            <Dimmer
              active={this.props.ui.toggleCart}
              inverted
              page
              blurring
              style={{ opacity: 0.8 }}
              onClickOutside={this.props.toggleCart}
            />
            <Switch>
              <PrivateRoute
                exact
                path="/"
                component={CatalogPage}
                isLoggedIn={isLoggedIn}
                redirect="/login"
              />

              <PrivateRoute
                path="/products/:id"
                component={ProductPage}
                isLoggedIn={isLoggedIn}
                redirect="/login"
              />

              <PrivateRoute
                exact
                path="/checkout"
                component={CheckoutPage}
                isLoggedIn={isLoggedIn}
                redirect="/login"
              />
              <PrivateRoute
                exact
                path="/view-orders"
                component={ViewOrdersPage}
                isLoggedIn={isLoggedIn}
                redirect="/login"
              />
              <PrivateRoute
                exact
                path="/view-orders/:orderNum"
                component={ViewSingleOrder}
                isLoggedIn={isLoggedIn}
                redirect="/login"
              />
              <PrivateRoute
                exact
                path="/my-profile"
                component={MyProfile}
                isLoggedIn={isLoggedIn}
                redirect="/login"
              />

              {/*approver routes*/}
              <PrivateRoute
                exact
                path="/approver/"
                component={ApproverPage}
                isLoggedIn={isLoggedIn}
                redirect="/login"
              />

              {/*corporate-manager routes*/}
              <PrivateRoute
                exact
                path="/corporate-manager/"
                component={CorporateManger}
                isLoggedIn={isLoggedIn}
                redirect="/login"
              />

              {/*admin routes*/}
              <PrivateRoute
                exact
                path="/admin/dashboard"
                component={Dashboard}
                isLoggedIn={isLoggedIn}
                isAdmin={isAdmin}
                redirect="/login"
              />

              <PrivateRoute
                exact
                path="/admin/manage-size-guide"
                component={ManageSizeGuide}
                isLoggedIn={isLoggedIn}
                isAdmin={isAdmin}
                redirect="/login"
              />
              <PrivateRoute
                exact
                path="/admin/manage-orders"
                component={ManageOrders}
                isLoggedIn={isLoggedIn}
                isAdmin={isAdmin}
                redirect="/login"
              />
              <PrivateRoute
                exact
                path="/admin/manage-virtual-orders"
                component={ManageVirtualOrders}
                isLoggedIn={isLoggedIn}
                isAdmin={isAdmin}
                redirect="/login"
              />
              <PrivateRoute
                exact
                path="/admin/create-user"
                component={CreateUser}
                isLoggedIn={isLoggedIn}
                isAdmin={isAdmin}
                redirect="/login"
              />
              <PrivateRoute
                exact
                path="/admin/manage-users/edit/:id"
                component={EditUser}
                isLoggedIn={isLoggedIn}
                isAdmin={isAdmin}
                redirect="/login"
              />
              <PrivateRoute
                exact
                path="/admin/manage-users"
                component={ManageUsers}
                isLoggedIn={isLoggedIn}
                isAdmin={isAdmin}
                redirect="/login"
              />
              <PrivateRoute
                exact
                path="/admin/manage-categories"
                component={ManageCategories}
                isLoggedIn={isLoggedIn}
                isAdmin={isAdmin}
                redirect="/login"
              />
              <PrivateRoute
                exact
                path="/admin/manage-products"
                component={ManageProducts}
                isLoggedIn={isLoggedIn}
                isAdmin={isAdmin}
                redirect="/login"
              />
              <PrivateRoute
                exact
                path="/admin/manage-product-tokens"
                component={ManageProductTokens}
                isLoggedIn={isLoggedIn}
                isAdmin={isAdmin}
                redirect="/login"
              />
              <PrivateRoute
                exact
                path="/admin/order-detail-report"
                component={OrderMasterList}
                isLoggedIn={isLoggedIn}
                isAdmin={isAdmin}
                redirect="/login"
              />
              <PrivateRoute
                exact
                path="/admin/manage-ship-addresses"
                component={ManageShipAddresses}
                isLoggedIn={isLoggedIn}
                isAdmin={isAdmin}
                redirect="/login"
              />
              <PrivateRoute
                exact
                path="/admin/manage-products/edit/:id"
                component={EditProduct}
                isLoggedIn={isLoggedIn}
                isAdmin={isAdmin}
                redirect="/login"
              />
              <PrivateRoute
                exact
                path="/admin/create-product"
                component={CreateProduct}
                isLoggedIn={isLoggedIn}
                isAdmin={isAdmin}
                redirect="/login"
              />

              <PrivateRoute
                path="/login"
                component={LoginPage}
                isLoggedIn={!isLoggedIn}
                redirect="/"
              />

              <PrivateRoute
                path="/register"
                component={RegisterPage}
                isLoggedIn={!isLoggedIn}
                redirect="/"
              />
              <Route
                path="/verify/:verificationToken?"
                component={VerifyPage}
                redirect="/"
              />
            </Switch>
          </div>
        </div>
      </Router>
    );
  }
}

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { logoutUser, toggleCart, dismissError, getCart, getApproverOrders, getMe },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
