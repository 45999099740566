import axios from "../api/axios";
import { getMe } from "./user";

export const TYPES = {
  GET_PRODUCTS: "GET_PRODUCTS",
  GET_MORE_PRODUCTS: "GET_PRODUCTS",
  GET_CATEGORIES: "GET_CATEGORIES",
  ADD_TO_CART: "ADD_TO_CART",
  GET_CART: "GET_CART",
  REMOVE_FROM_CART: "REMOVE_FROM_CART",
  SUBMIT_ORDER: "SUBMIT_ORDER",
  SUBMIT_VIRTUAL_ORDER: "SUBMIT_VIRTUAL_ORDER",
  GET_SINGLE_ORDER: "GET_SINGLE_ORDER",
  CLEAR_SINGLE_ORDER: "CLEAR_SINGLE_ORDER",
  GET_ORDERS: "GET_ORDERS",
  CANCEL_ORDER: "CANCEL_ORDER",
  GET_APPROVER_ORDERS: "GET_APPROVER_ORDERS",
  APPROVE_ORDER: "APPROVE_ORDER",
  REJECT_ORDER: "REJECT_ORDER",
  GET_SIZE_GUIDE: "GET_SIZE_GUIDE",
  GET_TOKEN: "GET_TOKEN",
};

export function getProducts(
  { offset, limit, categoryId, categoryIds, id },
  more
) {
  return async (dispatch) => {
    const options = {
      method: "get",
      url: "/api/product",
      params: {
        offset,
        limit,
        categoryId,
        categoryIds,
        enabled: true,
        id,
        order: ["sortOrder"],
      },
    };
    const type = more ? TYPES.GET_MORE_PRODUCTS : TYPES.GET_MORE_PRODUCTS;
    const { data } = await axios(options, dispatch, type);
    dispatch({ type, payload: data });
    return data;
  };
}

export function getCategories() {
  return async (dispatch) => {
    const options = {
      method: "get",
      url: "/api/product/category",
      params: {},
    };
    const type = TYPES.GET_CATEGORIES;
    const { data } = await axios(options, dispatch, type);
    dispatch({ type, payload: data });
  };
}
export function getSizeGuide() {
  return async (dispatch) => {
    const options = {
      method: "get",
      url: "/api/product/size-guide",
      params: {},
    };
    const type = TYPES.GET_SIZE_GUIDE;
    const { data } = await axios(options, dispatch, type);
    dispatch({ type, payload: data });
  };
}

export function addToCart({ userId, productVariantId, qty, tokenId }, edit) {
  return async (dispatch, getState) => {
    const cart = getState().product.cart;
    const inCartItem = cart.filter(
      (item) => item.productVariantId === productVariantId
    )[0];

    if (inCartItem && !edit) {
      qty = inCartItem.qty + qty;
      const limit = inCartItem.v_productVariant.virtuallimit;
      if (qty > limit) {
        dispatch({
          type: "REQUEST_ERROR",
          payload: { message: "Product Limit Reached!" },
        });
        qty = limit;
      }
    }

    const options = {
      method: "post",
      url: "/api/cart",
      data: {
        userId,
        productVariantId,
        qty,
        tokenId,
      },
    };
    const type = TYPES.ADD_TO_CART;
    const { data } = await axios(options, dispatch, type);
    dispatch({ type, payload: data });
    getCart()(dispatch);
  };
}

export function getCart() {
  return async (dispatch) => {
    const options = {
      method: "get",
      url: "/api/cart",
      data: {},
    };
    const type = TYPES.GET_CART;
    const { data } = await axios(options, dispatch, type);
    dispatch({ type, payload: data });
  };
}

export function removeFromCart(id) {
  return async (dispatch) => {
    const options = {
      method: "delete",
      url: `/api/cart/${id}`,
    };
    const type = TYPES.REMOVE_FROM_CART;
    const { data } = await axios(options, dispatch, type);
    dispatch({ type, payload: data });
    getCart()(dispatch);
  };
}

export function submitOrder(details) {
  return async (dispatch) => {
    const options = {
      method: "post",
      url: "/api/order/create",
      data: details,
    };
    const type = TYPES.SUBMIT_ORDER;
    const { data } = await axios(options, dispatch, type);
    dispatch({ type, payload: data });
    getMe()(dispatch);
  };
}

export function getSingleOrder(orderNum) {
  return async (dispatch) => {
    const options = {
      method: "get",
      url: "/api/order/",
      params: {
        orderNum,
      },
    };
    const type = TYPES.GET_SINGLE_ORDER;
    const { data } = await axios(options, dispatch, type);
    dispatch({ type, payload: data });
  };
}

export function clearSingleOrder() {
  return (dispatch) => {
    const type = TYPES.CLEAR_SINGLE_ORDER;
    dispatch({ type });
  };
}

export function getOrders({ offset, limit, orderNum }) {
  return async (dispatch) => {
    const options = {
      method: "get",
      url: "/api/order/",
      params: {
        offset,
        limit,
        orderNum,
      },
    };
    const type = TYPES.GET_ORDERS;
    const { data } = await axios(options, dispatch, type);
    dispatch({ type, payload: data });
  };
}

export function cancelOrder(id) {
  return async (dispatch) => {
    const options = {
      method: "post",
      url: "/api/order/cancel",
      data: {
        id,
      },
    };
    const type = TYPES.CANCEL_ORDER;
    const { data } = await axios(options, dispatch, type);
    dispatch({ type, payload: data });
    getOrders({ offset: 0, limit: 5 })(dispatch);
  };
}

export function getApproverOrders({ offset, limit, status }) {
  return async (dispatch) => {
    const options = {
      method: "get",
      url: "/api/order/approver",
      params: {
        offset,
        limit,
        status,
      },
    };
    const type = TYPES.GET_APPROVER_ORDERS;
    const { data } = await axios(options, dispatch, type);
    dispatch({ type, payload: data });
  };
}

export function approveOrder(id) {
  return async (dispatch) => {
    const options = {
      method: "post",
      url: "/api/order/approver/approve",
      data: {
        id,
      },
    };
    const type = TYPES.APPROVE_ORDER;
    const { data } = await axios(options, dispatch, type);
    dispatch({ type, payload: data });
    getApproverOrders({ offset: 0, limit: 5, status: "pending" })(dispatch);
  };
}

export function rejectOrder(id, rejectReason) {
  return async (dispatch) => {
    const options = {
      method: "post",
      url: "/api/order/approver/reject",
      data: {
        id,
        rejectReason,
      },
    };
    const type = TYPES.REJECT_ORDER;
    const { data } = await axios(options, dispatch, type);
    dispatch({ type, payload: data });
    getApproverOrders({ offset: 0, limit: 5, status: "pending" })(dispatch);
  };
}

export function getProductToken({ token, productId }) {
  return async (dispatch) => {
    const options = {
      method: "get",
      url: "/api/product/token",
      params: {
        token,
        productId,
      },
    };
    const type = TYPES.GET_TOKEN;
    const { data } = await axios(options, dispatch, type);
    dispatch({ type, payload: data });
    return data;
  };
}

export function submitVirtualOrder({ productVariantId, intakeForm }) {
  return async (dispatch) => {
    const options = {
      method: "POST",
      url: "/api/order/create-virtual",
      data: {
        productVariantId,
        intakeForm,
      },
    };
    const type = TYPES.SUBMIT_VIRTUAL_ORDER;
    const { data } = await axios(options, dispatch, type);
    dispatch({ type, payload: data });
    getMe()(dispatch);
  };
}
