import React, { Component } from "react";
import { Header, Divider, Grid } from "semantic-ui-react";
import AdminLayout from "../components/AdminLayout";
import EditBaseUserForm from "../components/EditBaseUserForm";
import EditShipAddresses from "../components/EditShipAddresses";
import UserRequesterTable from "../components/UserRequesterTable";

// redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  adminClearUser,
  adminGetSingleUser,
  adminGetApprovers,
  adminEditUserProp,
  adminEditUser,
  adminCreateUserAddress,
  adminDeleteUserAddress,
  adminEditUserAddress,
  adminChangePassword,
  adminDeleteUser,
} from "../actions/admin";
import { getLocations } from "../actions/user";
import { Redirect } from "react-router";

class EditUserPage extends Component {
  state = {
    isDeleted: false,
  };
  componentWillMount() {
    const { id } = this.props.match.params;
    this.props.adminGetSingleUser({ id });
    this.props.adminGetApprovers();
    this.props.getLocations();
  }
  shouldComponentUpdate(nextProps) {
    const oldId = this.props.match.params.id;
    const newId = nextProps.match.params.id;
    const callUpdate = oldId !== newId;
    if (callUpdate) {
      this.props.adminClearUser();
      this.props.adminGetSingleUser({ id: newId });
    }
    return true;
  }
  componentWillUnmount() {
    this.props.adminClearUser();
  }
  handleChange(e, data) {
    if (data.name === "enabled") {
      data.value = data.checked;
    }
    this.props.adminEditUserProp(data.name, data.value);
  }

  handleSaveBaseUser(e) {
    e.preventDefault();
    const {
      id,
      username,
      firstname,
      lastname,
      email,
      enabled,
      role,
      approverId,
      wallet,
      location,
      hourlyType,
    } = this.props.admin.singleUser;
    this.props.adminEditUser({
      id,
      username,
      firstname,
      lastname,
      enabled,
      email,
      role,
      approverId,
      wallet,
      location,
      hourlyType,
    });
  }

  handleDeleteUser() {
    this.props.adminDeleteUser(this.props.admin.singleUser);
    this.setState({ isDeleted: true });
  }

  handlePasswordReset() {
    const { id, newPassword } = this.props.admin.singleUser;
    this.props.adminChangePassword({
      id,
      password: newPassword,
    });
    this.props.adminEditUserProp("newPassword", "");
  }

  render() {
    const { isDeleted } = this.state;
    if (isDeleted) {
      return <Redirect to="/admin/manage-users" />;
    }

    const { locations } = this.props.user;
    const { singleUser, isUsersLoading, approvers } = this.props.admin;
    const {
      id,
      username,
      enabled,
      firstname,
      lastname,
      email,
      role,
      approverId,
      newPassword,
      shipAddresses,
      isEdited,
      requesters,
      newPasswordStatus,
      wallet,
      location,
      hourlyType,
    } = singleUser;
    return (
      <AdminLayout isLoading={isUsersLoading}>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Header as="h3">Edit User</Header>
              <Divider />
              <EditBaseUserForm
                onSubmit={this.handleSaveBaseUser.bind(this)}
                enabled={enabled}
                isEdited={isEdited}
                isEditMode={true}
                username={username}
                email={email}
                firstname={firstname}
                lastname={lastname}
                role={role}
                newPassword={newPassword}
                newPasswordStatus={newPasswordStatus}
                approverId={approverId}
                approvers={approvers.filter(({ id: uid }) => uid !== id)}
                wallet={wallet}
                locations={locations}
                location={location}
                hourlyType={hourlyType}
                handleChange={this.handleChange.bind(this)}
                handlePasswordReset={this.handlePasswordReset.bind(this)}
                handleDeleteUser={this.handleDeleteUser.bind(this)}
              />
              <Divider />
              {/* 
              {role === "corporate-user" && (
                <pre>{JSON.stringify(locations, null, "\t")}</pre>
              )} */}

              {shipAddresses && role === "user" && (
                <EditShipAddresses
                  userId={id}
                  shipAddresses={shipAddresses}
                  onCreate={this.props.adminCreateUserAddress}
                  onDelete={this.props.adminDeleteUserAddress}
                  onEdit={this.props.adminEditUserAddress}
                />
              )}

              {role === "approver" && (
                <UserRequesterTable requesters={requesters} />
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </AdminLayout>
    );
  }
}

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      adminClearUser,
      adminGetSingleUser,
      adminGetApprovers,
      adminEditUserProp,
      adminEditUser,
      adminCreateUserAddress,
      adminDeleteUserAddress,
      adminEditUserAddress,
      adminChangePassword,
      adminDeleteUser,
      getLocations,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(EditUserPage);
