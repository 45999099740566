import React from "react";
import CartItem from "./CartItem";

import { Grid, Header, Segment, Item, Divider } from "semantic-ui-react";

export default ({ cart, removeFromCart, addToCart, isCorporateUser }) => {
  const cartItems =
    cart.length > 0 ? (
      cart.map((cartItem) => (
        <CartItem
          key={cartItem.id}
          product={cartItem}
          itemNeedsAttention={
            cartItem.qty > cartItem.v_productVariant.virtualinstock
          }
          smallButton={true}
          removeFromCart={removeFromCart}
          addToCart={addToCart}
          isCorporateUser={isCorporateUser}
        />
      ))
    ) : (
      <Header as="h4" textAlign="center">
        No Items in Cart
      </Header>
    );

  return (
    <Grid.Column only="large screen" offset={65} width={6}>
      <Segment style={{ maxHeight: "85vh" }}>
        <Header as="h4" textAlign="center">
          Summary
        </Header>
        <Divider />
        <Item.Group style={{ maxHeight: "70vh", overflowY: "auto" }}>
          {cartItems}
        </Item.Group>
      </Segment>
    </Grid.Column>
  );
};
