import React, { Component } from "react";
import {
  Grid,
  Header,
  Button,
  Divider,
  Form,
  Select,
  Table,
  Checkbox,
} from "semantic-ui-react";
import AdminLayout from "../components/AdminLayout";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
// redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  adminGetProductOptions,
  adminGetVirtualOrders,
  adminSetOrderStatus,
} from "../actions/admin";
import snakeCase from "lodash/snakeCase";

class ManageVirtualOrders extends Component {
  state = {
    offset: 0,
    limit: 200,
    status: "pending",
    orderNum: "",
    selectedOrder: false,
    startDate: moment().startOf("month"),
    endDate: moment().endOf("month"),
    enabled: false,
    productOptions: [],
    selectedOrderIds: new Set(),
  };

  async componentDidMount() {
    const productOptions = await this.props.adminGetProductOptions({
      productType: "virtual",
    });

    this.setState(
      {
        productOptions,
        productId: productOptions[0]?.id,
      },
      () => {
        this.search();
      }
    );
  }

  loadMore() {
    let { offset, limit } = this.state;
    offset = offset + limit;
    const state = this.state;
    this.setState({ offset });
    this.props.adminGetOrders({ ...state, offset });
  }

  onChange(e, data) {
    this.setState({ [data.name]: data.value });
  }

  onDateChange(date, name) {
    this.setState({
      [name]: date,
    });
  }

  async search() {
    let { limit, status, startDate, endDate, productId, enabled } = this.state;
    const data = await this.props.adminGetVirtualOrders({
      limit,
      status,
      startDate,
      endDate,
      productId,
      enabled,
    });

    this.setState({
      orders: data,
    });
  }

  reset() {
    const resetState = {
      offset: 0,
      limit: 50,
      status: "pending",
      orderNum: "",
      startDate: moment().subtract(30, "d"),
      endDate: moment(),
    };

    this.setState(resetState);
    this.props.adminGetOrders(resetState);
  }

  handleSelectOrderId(id) {
    const selectedOrderIds = this.state.selectedOrderIds;
    if (selectedOrderIds.has(id)) {
      selectedOrderIds.delete(id);
      return this.setState({
        selectedOrderIds,
      });
    }
    selectedOrderIds.add(id);
    this.setState({
      selectedOrderIds,
    });
  }

  handleSelectAll() {
    const selectedOrderIds = this.state.selectedOrderIds;
    const ordersVirtual = this.props.admin.ordersVirtual;
    const ordersVirtualIds = ordersVirtual.map((o) => o.orderId);
    const isAllSelected = selectedOrderIds.size === ordersVirtual.length;
    if (isAllSelected) {
      return this.setState({
        selectedOrderIds: new Set(),
      });
    }

    return this.setState({
      selectedOrderIds: new Set(ordersVirtualIds),
    });
  }

  export(all) {
    const ordersVirtual = this.props.admin.ordersVirtual;
    const selectedOrderIds = this.state.selectedOrderIds;
    const toExport = all
      ? ordersVirtual
      : ordersVirtual.filter((v) => selectedOrderIds.has(v.orderId));

    const firstRow = ordersVirtual[0];
    const intakeFormFields =
      firstRow.intakeFormFields.map((f) => f.fieldName) ?? [];
    const csv = [];
    const headerRow = [
      "status",
      "order_num",
      "username",
      "location",
      "product",
      ...intakeFormFields.map((f) => `intake_${snakeCase(f)}`),
    ];

    csv.push(headerRow);

    for (const row of toExport) {
      const nextRow = [
        row.status,
        row.orderNum,
        row.username,
        row.locationName,
        row.productName,
      ];

      for (const field of intakeFormFields) {
        const val = row.intakeForm[field] || "";
        nextRow.push(val);
      }
      csv.push(nextRow);
    }

    const csvContent =
      "data:text/csv;charset=utf-8," + csv.map((e) => e.join(",")).join("\n");

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute(
      "download",
      `virtual-orders-export_${new Date().toISOString()}.csv`
    );
    document.body.appendChild(link); // Required for FF

    link.click();
  }

  async setOrderStatus() {
    await this.props.adminSetOrderStatus({
      id: Array.from(this.state.selectedOrderIds),
      status: "complete",
    });

    this.setState({
      selectedOrderIds: new Set(),
    });

    this.search();
  }

  render() {
    const { limit } = this.state;
    const { ordersVirtual, isOrderLoading } = this.props.admin;
    const {
      status,
      startDate,
      endDate,
      disabled,
      productId,
      productOptions,
      selectedOrderIds,
    } = this.state;

    const intakeFormFields =
      ordersVirtual[0]?.intakeFormFields.map((f) => f.fieldName) ?? [];

    return (
      <AdminLayout isLoading={isOrderLoading}>
        <Header as="h3">Manage Virtual Orders</Header>
        <Divider />

        <Grid>
          <Grid.Row columns="equal">
            <Grid.Column>
              <Form inline className="no-print">
                <Form.Group>
                  <Form.Field
                    name="status"
                    control={Select}
                    value={status}
                    label="Order Status"
                    placeholder="Select Status"
                    options={[
                      { key: 1, text: "pending", value: "pending" },
                      { key: 2, text: "complete", value: "complete" },
                    ]}
                    onChange={this.onChange.bind(this)}
                    disabled={disabled}
                  />

                  <Form.Field
                    name="productId"
                    control={Select}
                    value={productId}
                    label="Product "
                    options={productOptions?.map((p) => {
                      return {
                        text: p.name,
                        value: p.id,
                      };
                    })}
                    onChange={this.onChange.bind(this)}
                    disabled={disabled}
                  />

                  <Form.Field>
                    <label>Start Date</label>
                    <DatePicker
                      name="startDate"
                      dateFormat="YYYY-MM-DD"
                      selected={startDate}
                      startDate={startDate}
                      endDate={endDate}
                      onChange={(date) => this.onDateChange(date, "startDate")}
                      disabled={disabled}
                      showMonthDropdown
                      showYearDropdown
                      monthsShown={2}
                      className="ui input"
                    />
                  </Form.Field>
                  <Form.Field>
                    <label>End Date</label>
                    <DatePicker
                      dateFormat="YYYY-MM-DD"
                      selected={endDate}
                      startDate={startDate}
                      endDate={endDate}
                      onChange={(date) => this.onDateChange(date, "endDate")}
                      disabled={disabled}
                      showMonthDropdown
                      showYearDropdown
                      monthsShown={2}
                      className="ui input"
                    />
                  </Form.Field>

                  <Form.Field>
                    <label>&nbsp;</label>
                    <Button onClick={this.search.bind(this)}>Search</Button>
                    <Button onClick={this.reset.bind(this)}>Reset</Button>
                  </Form.Field>
                </Form.Group>
              </Form>
              <Divider />
              <Button
                content="Export All"
                icon="save"
                onClick={() => this.export(true)}
              />
              <Button
                content="Export Selected"
                icon="save"
                onClick={() => this.export()}
              />
              <Button
                content="Mark Complete"
                icon="check"
                floated="right"
                color="green"
                onClick={() => this.setOrderStatus()}
              />
              <Divider />

              <Table compact>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell rowSpan="2">
                      <Checkbox
                        checked={selectedOrderIds.size === ordersVirtual.length}
                        onChange={this.handleSelectAll.bind(this)}
                      />
                    </Table.HeaderCell>
                    <Table.HeaderCell rowSpan="2">Status</Table.HeaderCell>
                    <Table.HeaderCell rowSpan="2">Order #</Table.HeaderCell>
                    <Table.HeaderCell rowSpan="2">Username</Table.HeaderCell>
                    <Table.HeaderCell rowSpan="2">Location</Table.HeaderCell>
                    <Table.HeaderCell rowSpan="2">Product</Table.HeaderCell>
                    <Table.HeaderCell
                      colSpan={ordersVirtual?.[0]?.intakeFormFields.length}
                    >
                      Intake Form
                    </Table.HeaderCell>
                  </Table.Row>
                  <Table.Row>
                    {intakeFormFields.map((fieldName) => {
                      return (
                        <Table.HeaderCell key={fieldName}>
                          {fieldName}
                        </Table.HeaderCell>
                      );
                    })}
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {ordersVirtual.map((o) => {
                    return (
                      <Table.Row key={o.orderId}>
                        <Table.Cell collapsing>
                          <Checkbox
                            checked={selectedOrderIds.has(o.orderId)}
                            onChange={() => this.handleSelectOrderId(o.orderId)}
                          />
                        </Table.Cell>
                        <Table.Cell>{o.status}</Table.Cell>
                        <Table.Cell>{o.orderNum}</Table.Cell>
                        <Table.Cell>{o.username}</Table.Cell>
                        <Table.Cell>{o.locationName}</Table.Cell>
                        <Table.Cell>{o.productName}</Table.Cell>
                        {intakeFormFields.map((f) => {
                          const val = o.intakeForm[f];
                          return <Table.Cell key={f}>{val}</Table.Cell>;
                        })}
                      </Table.Row>
                    );
                  })}
                </Table.Body>
              </Table>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row centered>
            <Grid.Column width={4}>
              <Button
                fluid
                circular
                basic
                onClick={this.loadMore.bind(this)}
                disabled={ordersVirtual.length < limit}
                content={
                  ordersVirtual.length < limit ? "No more orders." : "Load More"
                }
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </AdminLayout>
    );
  }
}

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    { adminGetProductOptions, adminGetVirtualOrders, adminSetOrderStatus },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageVirtualOrders);
