import React, { Component } from "react";

import { Grid, Header, Divider } from "semantic-ui-react";
import AdminLayout from "../components/AdminLayout";
import EditProductImageList from "../components/EditProductImageList";
import EditBaseProductForm from "../components/EditBaseProductForm";
import EditProductVariants from "../components/EditProductVariants";

import move from "lodash-move";

// redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  adminClearProduct,
  adminGetSingleProduct,
  adminEditProductProp,
  adminGetCategories,
  adminEditProduct,
  adminCreateProductVariant,
  adminEditProductVariant,
  adminAdjustProductVariant,
  adminDeleteProductVariant,
  adminDeleteProductImage,
  adminUploadProductImage,
} from "../actions/admin";
import { EditProductContent } from "../components/EditProductContent";
import { EditIntakeForm } from "../components/EditIntakeForm";

class EditProductPage extends Component {
  state = {
    contentPanes: [],
  };

  componentWillMount() {
    const { id } = this.props.match.params;
    this.props.adminGetSingleProduct({ id });
    this.props.adminGetCategories();
  }
  componentWillUnmount() {
    this.props.adminClearProduct();
  }

  handleChange(e, data) {
    if (data.name === "enabled") {
      data.value = data.checked;
    }
    this.props.adminEditProductProp(data.name, data.value);
  }

  saveBaseProduct(e, data) {
    const {
      id,
      name,
      description,
      categoryId,
      enabled,
      productType,
      roles,
      intakeForm,
    } = this.props.admin.singleProduct;

    this.props.adminEditProduct({
      id,
      name,
      description,
      categoryId,
      enabled,
      productType,
      roles,
      intakeForm,
      ...data,
    });
    if (!e) return;
    e.preventDefault();
  }

  deleteProductImage(image) {
    const { id } = this.props.admin.singleProduct;
    this.props.adminDeleteProductImage({ id, images: [image] });
  }

  handleImageInput(e) {
    const data = new FormData();
    data.append("image", e.target.files[0]);
    data.append("productId", this.props.admin.singleProduct.id);
    this.setState({
      uploadedImage: e.target.files[0],
      imageFormData: data,
    });
  }

  handleImageUpload() {
    this.props.adminUploadProductImage(
      this.state.imageFormData,
      this.props.admin.singleProduct.id
    );
    this.setState({ imageFormData: null });
  }

  handleMoveImage(fromIndex, toIndex) {
    const { id, images } = this.props.admin.singleProduct;

    let nextState = move(images, fromIndex, toIndex);
    if (toIndex === -1 || toIndex > images.length) {
      nextState = images;
    }
    this.props.adminEditProduct({
      id,
      images: nextState,
    });
  }
  selectImage(image, idx) {
    this.setState({
      selectedImage: image,
      selectedImageIndex: idx,
    });
  }

  render() {
    const { isProductsLoading, singleProduct, categories } = this.props.admin;
    const {
      id,
      name,
      description,
      enabled,
      categoryId,
      images,
      isEdited,
      v_productVariants,
      content,
      productType,
      intakeForm,
      roles,
    } = singleProduct;

    return (
      <AdminLayout isLoading={isProductsLoading}>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Header as="h3">Edit Base Product</Header>
              <Divider />
              <EditBaseProductForm
                isEdited={isEdited}
                mode="edit"
                handleChange={this.handleChange.bind(this)}
                saveBaseProduct={this.saveBaseProduct.bind(this)}
                name={name}
                categoryId={categoryId}
                description={description}
                categories={categories}
                enabled={enabled}
                productType={productType}
                roles={roles}
              />
              <Divider />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <EditProductImageList
                uploadedImage={this.state.uploadedImage}
                isEdited={isEdited}
                handleImageInput={this.handleImageInput.bind(this)}
                handleImageUpload={this.handleImageUpload.bind(this)}
                uploadDisabled={!this.state.imageFormData}
                images={images}
                deleteProductImage={this.deleteProductImage.bind(this)}
                handleMoveImage={this.handleMoveImage.bind(this)}
                selectImage={this.selectImage.bind(this)}
              />
            </Grid.Column>
          </Grid.Row>
          <Divider />
          {productType === "virtual" && (
            <Grid.Row>
              <Grid.Column>
                <Header as="h3">Intake Fields</Header>
                <EditIntakeForm
                  name="intakeForm"
                  onChange={this.handleChange.bind(this)}
                  intakeForm={intakeForm}
                />
              </Grid.Column>
            </Grid.Row>
          )}

          {v_productVariants && productType === "physical" && (
            <Grid.Row>
              <Grid.Column>
                <Header as="h3">Product Variants</Header>
                <EditProductVariants
                  productId={id}
                  productType={productType}
                  productVariants={v_productVariants}
                  adminCreateProductVariant={this.props.adminCreateProductVariant.bind(
                    this
                  )}
                  adminEditProductVariant={this.props.adminEditProductVariant.bind(
                    this
                  )}
                  adminDeleteProductVariant={this.props.adminDeleteProductVariant.bind(
                    this
                  )}
                  adminAdjustProductVariant={this.props.adminAdjustProductVariant.bind(
                    this
                  )}
                />
              </Grid.Column>
            </Grid.Row>
          )}
          <Divider />
          <Grid.Row>
            <Grid.Column>
              <Header as="h3">Product Content</Header>
              <EditProductContent
                content={content}
                onSave={(content) => {
                  this.handleChange(null, { name: "content", value: content });
                  this.saveBaseProduct(null, { content });
                }}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </AdminLayout>
    );
  }
}

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      adminClearProduct,
      adminGetSingleProduct,
      adminEditProductProp,
      adminGetCategories,
      adminEditProduct,
      adminCreateProductVariant,
      adminEditProductVariant,
      adminAdjustProductVariant,
      adminDeleteProductVariant,
      adminDeleteProductImage,
      adminUploadProductImage,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProductPage);
