import React from 'react'
import { Table, Button, Header } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

export default ({ requesters }) => {
  return (
    <div>
      <Header as="h4">Assigned Requesters</Header>
      <Table definition>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell />
            <Table.HeaderCell>Username</Table.HeaderCell>
            <Table.HeaderCell>Email</Table.HeaderCell>
            <Table.HeaderCell>Firstname</Table.HeaderCell>
            <Table.HeaderCell>Lastname</Table.HeaderCell>
            <Table.HeaderCell>Role</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {requesters
            ? requesters.map(
                ({ username, email, firstname, lastname, role, id }) => {
                  return (
                    <Table.Row key={id}>
                      <Table.Cell collapsing>
                        <Button
                          icon="edit"
                          circular
                          basic
                          as={Link}
                          to={`/admin/manage-users/edit/${id}`}
                        />
                      </Table.Cell>
                      <Table.Cell>
                        {username}
                      </Table.Cell>
                      <Table.Cell>
                        {email}
                      </Table.Cell>
                      <Table.Cell>
                        {firstname}
                      </Table.Cell>
                      <Table.Cell>
                        {lastname}
                      </Table.Cell>
                      <Table.Cell>
                        {role}
                      </Table.Cell>
                    </Table.Row>
                  )
                }
              )
            : null}
        </Table.Body>
      </Table>
    </div>
  )
}
