import React, { Component } from 'react'
import {
  Container,
  Dimmer,
  Loader,
  Grid,
  List,
  Divider,
  Header
} from 'semantic-ui-react'
import ViewOrderListItem from '../components/ViewOrderListItem'
// redux
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  getApproverOrders,
  cancelOrder,
  approveOrder,
  rejectOrder
} from '../actions/product'

class ApproverPage extends Component {
  state = { offset: 0, limit: 50, status: 'pending' }
  componentWillMount() {
    const { offset, limit, status } = this.state
    this.props.getApproverOrders({ offset, limit, status })
  }
  approveOrder(id) {
    this.props.approveOrder(id)
    this.setState({ offset: 0, limit: 50, status: 'pending' })
  }
  rejectOrder(id, rejectReason) {
    this.props.rejectOrder(id, rejectReason)
    this.setState({ offset: 0, limit: 50, status: 'pending' })
  }
  render() {
    const { approverOrders, isOrderLoading } = this.props.product
    return (
      <Container>
        <Dimmer active={isOrderLoading} inverted page blurring>
          <Loader active={isOrderLoading}>Loading</Loader>
        </Dimmer>
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Header as="h3">Approver Orders</Header>
              <Divider />
              <List>
                {approverOrders.length > 0
                  ? approverOrders.map(order =>
                      <ViewOrderListItem
                        key={order.id}
                        order={order}
                        approver={true}
                        approveOrder={this.approveOrder.bind(this)}
                        rejectOrder={this.rejectOrder.bind(this)}
                      />
                    )
                  : null}
                <div />
              </List>
              {approverOrders.length === 0
                ? <Header as="h3" textAlign="center">
                    No Orders!
                  </Header>
                : null}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    )
  }
}

const mapStateToProps = state => {
  return state
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators(
    {
      getApproverOrders,
      cancelOrder,
      approveOrder,
      rejectOrder
    },
    dispatch
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(ApproverPage)
