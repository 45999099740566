import React from 'react'
import { Table } from 'semantic-ui-react'

export default ({ orderProducts }) => {
  return (
    <Table unstackable>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Line</Table.HeaderCell>
          <Table.HeaderCell>SKU</Table.HeaderCell>
          <Table.HeaderCell>Product</Table.HeaderCell>
          <Table.HeaderCell>Size</Table.HeaderCell>
          <Table.HeaderCell>Qty</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {orderProducts
          ? orderProducts.map((product, idx) =>
              <Table.Row key={product.id}>
                <Table.Cell collapsing>
                  {idx + 1}
                </Table.Cell>
                <Table.Cell>
                  {product.productVariant.sku}
                </Table.Cell>
                <Table.Cell>
                  {product.productVariant.product.name}
                </Table.Cell>
                <Table.Cell>
                  {product.productVariant.size}
                </Table.Cell>
                <Table.Cell>
                  {product.qty}
                </Table.Cell>
              </Table.Row>
            )
          : null}
      </Table.Body>
    </Table>
  )
}
