// @ts-check
import React, { Component } from "react";

import { Header, Divider, Grid, Tab } from "semantic-ui-react";
import AdminLayout from "../components/AdminLayout";

// redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  adminGetProductTokens,
  adminGetProductOptions,
  adminCreateProductTokens,
  adminDeleteProductToken,
} from "../actions/admin";
import { AssignTokens } from "../components/AssignTokens";
import { ManageProductTokenTable } from "../components/ManageProductTokenTable";

class ManageProductTokens extends Component {
  render() {
    const {
      productTokenProductOptions,
      isProductTokensLoading,
    } = this.props.admin;
    return (
      <AdminLayout isLoading={isProductTokensLoading}>
        <Header as="h3">Manage Product Tokens</Header>
        <Divider />

        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Tab
                menu={{ secondary: true, pointing: true }}
                panes={[
                  {
                    menuItem: "Create Tokens",
                    render: () => (
                      <AssignTokens
                        productTokenProductOptions={productTokenProductOptions}
                        adminGetProductOptions={
                          this.props.adminGetProductOptions
                        }
                        adminCreateProductTokens={
                          this.props.adminCreateProductTokens
                        }
                      />
                    ),
                  },
                  {
                    menuItem: "Manage Tokens",
                    render: () => (
                      <ManageProductTokenTable
                        adminGetProductOptions={
                          this.props.adminGetProductOptions
                        }
                        adminGetProductTokens={this.props.adminGetProductTokens}
                        adminDeleteProductToken={
                          this.props.adminDeleteProductToken
                        }
                      />
                    ),
                  },
                ]}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </AdminLayout>
    );
  }
}

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      adminGetProductTokens,
      adminGetProductOptions,
      adminCreateProductTokens,
      adminDeleteProductToken,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageProductTokens);
