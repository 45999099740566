import axios from "../api/axios";

export const TYPES = {
  USER_LOGOUT: "USER_LOGOUT",
  USER_LOGIN: "USER_LOGIN",
  SET_LOGIN_MESSAGE: "SET_LOGIN_MESSAGE",
  DEL_LOGIN_MESSAGE: "DEL_LOGIN_MESSAGE",
  GET_REQUESTERS: "GET_REQUESTERS",
  GET_ADDRESSES: "GET_ADDRESSES",
  GET_ME: "GET_ME",
  PUT_ME: "PUT_ME",
  PASSWORD_RESET: "PASSWORD_RESET",
  REGISTER_USER: "REGISTER_USER",
  VERIFY_USER: "VERIFY_USER",
  GET_LOCATIONS: "GET_LOCATIONS",
  GET_MANAGERS: "GET_MANAGERS",
};

export function delLoginMessage() {
  return (dispatch) => dispatch({ type: TYPES.DEL_LOGIN_MESSAGE });
}

export function loginUser({ username, password }) {
  return async (dispatch) => {
    const options = {
      method: "post",
      url: "/api/auth/login",
      data: {
        username,
        password,
      },
    };

    const type = TYPES.USER_LOGIN;
    const { data } = await axios(options, dispatch, type);
    if (data.message) {
      dispatch({ type: TYPES.SET_LOGIN_MESSAGE, payload: data.message });
    } else {
      localStorage.Auth = data.token;
      dispatch({ type, payload: data });
      getMe()(dispatch);
    }
  };
}

export function logoutUser() {
  const type = TYPES.USER_LOGOUT;
  localStorage.removeItem("Auth");
  return (dispatch) => dispatch({ type });
}

export function setUserDetailProp(prop, val) {
  return async (dispatch) => {
    dispatch({ type: TYPES.SET_USER_DETAIL_PROP, payload: { [prop]: val } });
  };
}

export function getMe() {
  return async (dispatch) => {
    const options = {
      method: "get",
      url: "/api/user/me",
      params: {},
    };

    const type = TYPES.GET_ME;
    const { data } = await axios(options, dispatch, type);
    dispatch({ type, payload: data });
  };
}

export function putMe(body) {
  return async (dispatch) => {
    const options = {
      method: "put",
      url: "/api/user/me",
      data: body,
    };

    const type = TYPES.PUT_ME;
    const { data } = await axios(options, dispatch, type);
    dispatch({ type, payload: data });
    getMe()(dispatch);
    return data;
  };
}

export function passwordReset({ id, password, newPassword }) {
  return async (dispatch) => {
    const options = {
      method: "post",
      url: "/api/auth/password-reset",
      data: {
        password,
        newPassword,
      },
    };

    const type = TYPES.PASSWORD_RESET;
    const { data } = await axios(options, dispatch, type);
    if (!data.message) {
      dispatch({ type, payload: data });
    }
  };
}

export function registerUser(formData) {
  return async (dispatch) => {
    const options = {
      method: "post",
      url: "/api/auth/register",
      data: formData,
    };

    const type = TYPES.REGISTER_USER;
    const { data } = await axios(options, dispatch, type);
    return data;
  };
}

export function getLocations() {
  return async (dispatch) => {
    const options = {
      method: "get",
      url: "/api/user/locations",
    };

    const type = TYPES.GET_LOCATIONS;
    const { data } = await axios(options, dispatch, type);
    if (data) {
      dispatch({ type, payload: data });
    }
    return data;
  };
}

export function getManagers() {
  return async (dispatch) => {
    const options = {
      method: "get",
      url: "/api/user/corporate-managers",
    };

    const type = TYPES.GET_MANAGERS;
    const { data } = await axios(options, dispatch, type);
    if (data) {
      dispatch({ type, payload: data });
    }
    return data;
  };
}

export function verifyUser({ verificationToken }) {
  return async (dispatch) => {
    const options = {
      method: "post",
      url: "/api/auth/verify",
      data: {
        verificationToken,
      },
    };

    const type = TYPES.VERIFY_USER;
    const { data } = await axios(options, dispatch, type);
    if (data.token) {
      dispatch({ type, payload: data.token });
    }

    return data;
  };
}
