import React, { useState, useEffect } from "react";
import { Grid, Header, Button, Divider, Modal, List } from "semantic-ui-react";
import { GoSettings } from "react-icons/go";
import { GrClose } from "react-icons/gr";
import { BsCheck } from "react-icons/bs";
import "./FilterBy.css";
import clsx from "clsx";
import { THEME } from "../../env";

const FilterByCheckbox = ({ productType, checked, onCheck }) => {
  return (
    <List.Item
      onClick={() => onCheck(productType)}
      className="FilterBy-checkboxContainer"
    >
      <List.Content>
        <span className={`FilterBy-checkbox ${checked ? "active" : ""}`}>
          <BsCheck fontSize="1.25em" />
          <input type="checkbox" hidden checked={checked} readOnly />
        </span>
        {productType.name}
      </List.Content>
    </List.Item>
  );
};

export const FilterBy = (props) => {
  const { productTypes, filterCategory } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [selectedTypes, setSelectedTypes] = useState([]);

  const handleOnOpen = () => {
    setIsOpen(true);
  };

  const handleOnClose = () => {
    setIsOpen(false);
  };

  const handleOnCheck = (type) => {
    if (selectedTypes.some((t) => t.id === type.id)) {
      return setSelectedTypes(selectedTypes.filter((v) => v.id !== type.id));
    }

    return setSelectedTypes([...selectedTypes, type]);
  };

  const handleOnApply = () => {
    filterCategory(selectedTypes);
    handleOnClose();
  };

  const handleOnClear = () => {
    setSelectedTypes([]);
    filterCategory([]);
    handleOnClose();
  };

  useEffect(() => {
    return () => {};
  }, []);

  return (
    <React.Fragment>
      <Modal
        className={clsx(THEME)}
        dimmer="inverted"
        basic
        open={isOpen}
        centered={false}
        onClose={handleOnClose}
      >
        <Grid>
          <Grid.Row columns="2">
            <Grid.Column>
              <Header size="huge">Filters</Header>
            </Grid.Column>
            <Grid.Column stretched>
              <div className="FilterBy-closeIcon" onClick={handleOnClose}>
                Close
                <GrClose style={{ marginLeft: 15 }} />
              </div>
            </Grid.Column>
          </Grid.Row>
          <Divider />
          <Grid.Row columns="1">
            <Grid.Column>
              <Header size="large">Product Type</Header>
              <List horizontal relaxed size="huge">
                {productTypes.map((type) => {
                  return (
                    <FilterByCheckbox
                      key={type.name}
                      productType={type}
                      onCheck={handleOnCheck}
                      checked={selectedTypes.some((t) => t.id === type.id)}
                    />
                  );
                })}
              </List>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns="2" centered>
            <Grid.Column width="6">
              <Button
                className={clsx(THEME)}
                primary
                fluid
                basic
                size="huge"
                onClick={handleOnClear}
              >
                Clear
              </Button>
            </Grid.Column>
            <Grid.Column width="6">
              <Button
                className={clsx(THEME)}
                primary
                fluid
                size="huge"
                onClick={handleOnApply}
              >
                Apply
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Modal>

      <Grid columns="1">
        <Grid.Column stretched>
          <button
            className={clsx(THEME, "FilterBy-button")}
            onClick={handleOnOpen}
          >
            <span className="FilterBy-icon">
              <GoSettings fontSize="1.2em" />
            </span>
            <span className="FilterBy-text">
              Filter By
              {selectedTypes.length > 0 && (
                <span className="FilterBy-textSelected">
                  : {selectedTypes.map((t) => t.name).join(", ")}
                </span>
              )}
            </span>
            <span className="FilterBy-arrowLine" />
            <span className="FilterBy-arrow" />
          </button>
        </Grid.Column>
      </Grid>
    </React.Fragment>
  );
};
