import React, { Component } from "react";

import { Header, Divider, Grid, Button, Form, Select } from "semantic-ui-react";
import AdminLayout from "../components/AdminLayout";
import ManageUserTable from "../components/ManageUserTable";
import debounce from "lodash/debounce";

// redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { adminGetUsers } from "../actions/admin";

class ManageUserPage extends Component {
  state = {
    offset: 0,
    limit: 50,
    enabled: true,
    role: "",
  };

  componentDidMount() {
    const { offset, limit, enabled } = this.state;
    this.props.adminGetUsers({ offset, limit, enabled, refresh: true });
  }

  loadMore() {
    let { offset, limit, enabled } = this.state;
    offset = offset + limit;
    const state = this.state;
    this.setState({ offset });
    this.props.adminGetUsers({ ...state, offset, enabled });
  }

  handleChange(e, data) {
    let [offset, limit] = [0, 200];
    this.setState({
      ...this.state,
      offset,
      limit,
      [data.name]: data.value,
    });
    this.props.adminGetUsers({
      ...this.state,
      offset,
      limit,
      [data.name]: data.value,
      refresh: true,
    });
  }

  debouncedHandleChange = debounce(this.handleChange, 500);

  render() {
    const { limit, enabled } = this.state;
    const { users, isUsersLoading } = this.props.admin;
    return (
      <AdminLayout isLoading={isUsersLoading}>
        <Header as="h3">Manage Users</Header>
        <Divider />
        <Grid>
          <Grid.Column>
            <Form>
              <Form.Group>
                <Form.Field
                  label="Enabled/Disabled"
                  name="enabled"
                  control={Select}
                  onChange={this.handleChange.bind(this)}
                  options={[
                    { key: 0, text: "Any", value: "" },
                    { key: 1, text: "Enabled", value: true },
                    { key: 2, text: "Disabled", value: false },
                  ]}
                  value={enabled}
                />
                <Form.Field
                  label="User Role"
                  name="role"
                  control={Select}
                  onChange={this.handleChange.bind(this)}
                  options={[
                    { key: 0, text: "Any", value: "" },
                    { key: 1, text: "Admin", value: "admin" },
                    { key: 2, text: "Dealer", value: "user" },
                    { key: 3, text: "Dealer Approver", value: "approver" },
                    { key: 4, text: "Corp Employee", value: "corporate-user" },
                    {
                      key: 5,
                      text: "Corp Manager",
                      value: "corporate-manager",
                    },
                  ]}
                  defaultValue={""}
                />
                <Form.Input
                  label="Search by Username"
                  name="username"
                  placeholder="username"
                  onChange={this.debouncedHandleChange.bind(this)}
                />
                <Form.Input
                  label="Search by Email"
                  name="email"
                  placeholder="user@example.com"
                  onChange={this.debouncedHandleChange.bind(this)}
                />
              </Form.Group>
            </Form>
          </Grid.Column>
        </Grid>
        <ManageUserTable users={users} />

        <Grid>
          <Grid.Row centered>
            <Grid.Column width={4}>
              <Button
                fluid
                circular
                basic
                onClick={this.loadMore.bind(this)}
                disabled={users.length < limit}
                content={users.length < limit ? "No more users." : "Load More"}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </AdminLayout>
    );
  }
}

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      adminGetUsers,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageUserPage);
