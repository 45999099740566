//@ts-check
import React, { Component } from "react";
import { Grid, Header, Divider, Form, Select, Tab } from "semantic-ui-react";
import AdminLayout from "../components/AdminLayout";
import ProductMasterList from "../components/ProductMasterList";
import AdjustmentHistory from "../components/AdjustmentHistory";

// redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { adminGetProduct, adminGetAdjustmentHistory } from "../actions/admin";

import ManageProductsTable from "../components/ManageProductsTable";

class ManageProducts extends Component {
  state = {
    offset: 0,
    limit: 100,
    enabled: true,
  };
  componentWillMount() {
    this.props.adminGetProduct({
      offset: 0,
      limit: this.state.limit,
      enabled: true,
    });
  }

  loadMore() {
    let { offset, limit, enabled } = this.state;
    offset = offset + limit;
    this.setState({ offset });
    this.props.adminGetProduct({
      offset,
      limit,
      enabled,
    });
  }

  handleChange(e, data) {
    let [offset, limit] = [0, 100];
    this.setState({
      offset,
      limit,
      enabled: data.value,
    });
    this.props.adminGetProduct({
      offset,
      limit,
      enabled: data.value,
    });
  }

  render() {
    const { products, isProductsLoading, adjustmentHistory } = this.props.admin;
    return (
      <AdminLayout isLoading={isProductsLoading}>
        <Header as="h3">Manage Products</Header>
        <Divider />
        <Grid>
          <Grid.Column>
            <Form>
              <Form.Group>
                <Form.Field
                  label="Enabled/Disabled"
                  placeholer="Select Enabled/Disabled"
                  control={Select}
                  onChange={this.handleChange.bind(this)}
                  options={[
                    { key: 0, text: "Any", value: "" },
                    { key: 1, text: "Enabled", value: true },
                    { key: 2, text: "Disabled", value: false },
                  ]}
                />
              </Form.Group>
            </Form>
          </Grid.Column>
        </Grid>
        <Divider />
        <Tab
          menu={{ secondary: true, pointing: true }}
          panes={[
            {
              menuItem: "Product Manager",
              render: () => (
                <ManageProductsTable
                  products={products}
                  loadMore={this.loadMore.bind(this)}
                  offset={this.state.offset}
                  limit={this.state.limit}
                />
              ),
            },
            {
              menuItem: "Product Master List",
              render: () => (
                <ProductMasterList
                  products={products}
                  loadMore={this.loadMore.bind(this)}
                  offset={this.state.offset}
                  limit={this.state.limit}
                />
              ),
            },
            {
              menuItem: "Adjustment History",
              render: () => (
                <AdjustmentHistory
                  adminGetAdjustmentHistory={
                    this.props.adminGetAdjustmentHistory
                  }
                  data={adjustmentHistory}
                />
              ),
            },
          ]}
        />
      </AdminLayout>
    );
  }
}

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      adminGetProduct,
      adminGetAdjustmentHistory,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageProducts);
