import React, { useState } from "react";
import { Header, Button, Grid, Image } from "semantic-ui-react";
import { Link } from "react-router-dom";
import "./index.css";
import { THEME } from "../../env";
import cx from "clsx";

export const ProductCard = (props) => {
  const { product } = props;
  const { images, v_productVariants, id } = product;
  const imageSrc = images[0];
  const [isError, setIsError] = useState(false);

  return (
    <Link className={cx(THEME, "Product-card")} to={`/products/${id}`}>
      <Header textAlign="center" size="huge" as="h3" className="Product-header">
        <Header.Subheader>{product.category.name}</Header.Subheader>
        {product.name}
      </Header>
      <div className="Product-image">
        {!isError && (
          <picture>
            {images.length && (
              <Image
                src={`${process.env.PUBLIC_URL}/storage/${imageSrc}`}
                alt={`${product.name} product shot`}
                onError={() => setIsError(true)}
              />
            )}
          </picture>
        )}
      </div>

      <Grid columns={1}>
        <Grid.Column className="Product-option">
          <Header as="h4" size="small" textAlign="center">
            <Header.Subheader>Available In</Header.Subheader>
            {product.productType === "physical"
              ? `${v_productVariants.length} Variants`
              : "1 Variant"}
          </Header>
        </Grid.Column>

        <Grid.Column width={16}>
          <Button
            className={cx(THEME, "Product-addToCart")}
            fluid
            color="primary"
            size="huge"
            circular={THEME === "shaw"}
          >
            View Product
          </Button>
        </Grid.Column>
      </Grid>
    </Link>
  );
};
