import React, { Component } from "react";
import {
  List,
  Segment,
  Item,
  Grid,
  Button,
  Label,
  Form,
  TextArea,
} from "semantic-ui-react";
import moment from "moment";
import makePackingSlip from "../common/pdf";

export default class ViewOrderListItem extends Component {
  state = {
    rejectReason: "",
  };
  handleChange(e, data) {
    this.setState({
      rejectReason: data.value,
    });
  }
  statusColor = (status) => {
    switch (status) {
      case "shipped":
        return "green";
      case "approved":
        return "blue";
      case "pending":
        return "orange";
      case "rejected":
        return "red";
      case "cancelled":
        return null;
      default:
        return null;
    }
  };

  render() {
    const { order, cancelOrder, approver } = this.props;
    const {
      id,
      createdAt,
      orderNum,
      status,
      orderProducts,
      user,
      name,
      streetAddress,
      unit,
      city,
      postalCode,
      province,
      country,
      courier,
      tracking,
      email,
      phone,
      rejectReason,
      comments,
    } = order;

    return (
      <List.Item>
        <Segment attached="top" secondary compact>
          <Label
            ribbon
            color={this.statusColor(status)}
            style={{ transform: "translateY(-10px)" }}
          >
            {status}
          </Label>
          <List horizontal relaxed>
            <List.Item>
              <small>
                ORDER PLACED <br />
                {moment(createdAt).format("YYYY-MM-DD")}
              </small>
            </List.Item>
            <List.Item>
              <small>
                ORDER NUMBER <br />
                {orderNum}
              </small>
            </List.Item>

            <List.Item>
              <small>
                CREATED BY <br />
                {`${user.firstname} ${user.lastname} (${user.username})`}
              </small>
            </List.Item>
            <List.Item>
              <small>
                TRACKING <br />
                {tracking ? `${courier}: ${tracking}` : "N/A"}
              </small>
            </List.Item>
          </List>
        </Segment>
        <Segment attached>
          <Grid>
            <Grid.Row columns="equal">
              <Grid.Column width={6}>
                <Item.Group>
                  {orderProducts.map((product) => {
                    const { productVariant } = product;
                    return (
                      <Item key={product.id}>
                        {/* <Item.Image
                          src={`${process.env.PUBLIC_URL}/storage/${productVariant.product.images[0]}`}
                          size="tiny"
                        /> */}
                        <Item.Content>
                          <Item.Header style={{ fontSize: "14px" }}>
                            {productVariant.product.name} -{" "}
                            {productVariant.size}
                          </Item.Header>
                          <Item.Meta>
                            {productVariant.sku} <br />
                            QTY: {product.qty}
                          </Item.Meta>
                        </Item.Content>
                      </Item>
                    );
                  })}
                </Item.Group>
              </Grid.Column>
              <Grid.Column>
                {order.type === "physical" && (
                  <>
                    <strong>Ship To</strong> <br />
                    {name} <br />
                    {streetAddress} {unit ? `Unit ${unit}` : null} <br />
                    {city}, {province} {postalCode} <br />
                    {country}
                    <br />
                    <br />
                  </>
                )}
                {comments ? (
                  <div>
                    <strong>Comments</strong>
                    <br />
                    {comments}
                  </div>
                ) : null}
                <br />
                <strong>Contact</strong> <br />
                {email} <br />
                {phone} <br />
                <br />
              </Grid.Column>
              <Grid.Column
                computer={4}
                tablet={4}
                mobile={16}
                textAlign="center"
              >
                {!approver ? (
                  <Button onClick={() => makePackingSlip(order)} fluid basic>
                    Print
                  </Button>
                ) : null}
                <br />
                {!approver && rejectReason ? (
                  <div>
                    <strong>Reject Reason</strong> <br />
                    {rejectReason}
                  </div>
                ) : null}

                <br />
                {!approver ? (
                  status === "shipped" ||
                  status === "rejected" ||
                  status === "cancelled" ? null : (
                    <Button fluid onClick={() => cancelOrder(id)}>
                      Cancel
                    </Button>
                  )
                ) : null}

                {approver ? (
                  <Button fluid onClick={() => this.props.approveOrder(id)}>
                    Approve
                  </Button>
                ) : null}
                <br />

                {approver ? (
                  <div className="ui form">
                    <Form.Field
                      label="Reject Reason"
                      control={TextArea}
                      onChange={this.handleChange.bind(this)}
                      value={this.state.rejectReason}
                      placeholder="Add Reject Reason/Comment"
                    />
                  </div>
                ) : null}
                {approver ? (
                  <Button
                    fluid
                    disabled={this.state.rejectReason.length === 0}
                    onClick={() =>
                      this.props.rejectOrder(id, this.state.rejectReason)
                    }
                  >
                    Reject
                  </Button>
                ) : null}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Segment>
      </List.Item>
    );
  }
}
