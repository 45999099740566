// @ts-check
import React, { useState, useEffect, useCallback } from "react";
import { Form, Table, Button, Message } from "semantic-ui-react";

export const AssignTokens = (props) => {
  const { adminCreateProductTokens, adminGetProductOptions } = props;
  const [productOptions, setProductOptions] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [createdCount, setCreatedCount] = useState(0);

  const handleGetProductOptions = useCallback(async () => {
    const productOpts = await adminGetProductOptions();
    const productOptions = productOpts.map((p) => ({
      key: p.id,
      text: p.name,
      value: { name: p.name, id: p.id },
    }));

    setProductOptions(productOptions);
  }, [adminGetProductOptions]);

  useEffect(() => {
    handleGetProductOptions();
  }, [handleGetProductOptions]);

  const [formData, setFormData] = useState({
    product: { id: "all", name: "All Products" },
    quantity: "1",
  });

  const handleChange = (e, data) => {
    setFormData((state) => {
      return {
        ...state,
        [data.name]: data.value,
      };
    });

    if (createdCount) {
      setCreatedCount(0);
    }
  };

  const addTransaction = () => {
    const filteredTransactions = transactions.filter((v) => {
      const shouldFilter = v.product.id !== formData.product.id;
      return shouldFilter;
    });
    setTransactions([...filteredTransactions, formData]);
  };

  const handleRemoveTransaction = (idx) => {
    const nextTransactions = [...transactions];
    nextTransactions.splice(idx, 1);
    setTransactions(nextTransactions);
  };

  const clearTransactions = () => {
    setTransactions([]);
  };

  const confirmTransactions = async () => {
    const tokens = transactions.map((v) => {
      return {
        productId: v.product.id === "all" ? null : v.product.id,
        qty: v.quantity,
      };
    });

    const qtyCreated = await adminCreateProductTokens(tokens);
    clearTransactions();
    setCreatedCount(qtyCreated);
  };

  return (
    <div>
      <Form>
        <Form.Group>
          <Form.Select
            label="Product"
            name="product"
            options={[
              {
                key: "all",
                text: "All Products",
                value: { id: "all", name: "All Products" },
              },
              ...productOptions,
            ]}
            onChange={handleChange}
            value={formData.product}
          />

          <Form.Input
            label="Quantity"
            name="quantity"
            min={0}
            type="number"
            onChange={handleChange}
            value={formData.quantity}
          />
          <Form.Button
            label="Add"
            content="Add"
            primary
            onClick={addTransaction}
            type="submit"
          />
        </Form.Group>
        <Form.Group>
          <Form.Button
            label="Clear"
            content="Clear Worksheet"
            onClick={clearTransactions}
            disabled={!transactions.length}
          />
          <Form.Button
            label="Confirm"
            content="Assign Tokens"
            onClick={confirmTransactions}
            color="green"
            disabled={!transactions.length}
          />
        </Form.Group>
      </Form>

      {createdCount > 0 && (
        <Message success>Successfully created {createdCount} tokens.</Message>
      )}

      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Product</Table.HeaderCell>
            <Table.HeaderCell># of Tokens</Table.HeaderCell>
            <Table.HeaderCell>Remove</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {transactions.map((t, idx) => {
            return (
              <Table.Row key={idx}>
                <Table.Cell>{t.product.name}</Table.Cell>
                <Table.Cell>{t.quantity}</Table.Cell>
                <Table.Cell>
                  <Button
                    icon="trash"
                    basic
                    onClick={() => handleRemoveTransaction(idx)}
                  />
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </div>
  );
};
