import React from "react";
import { Grid } from "semantic-ui-react";
import { ProductCard } from "../ProductCard/ProductCard";
import "./index.css";

export const ProductList = (props) => {
  const { products, addVariantToCart } = props;

  return (
    <Grid columns="equal">
      <Grid.Row>
        {products.map((product) => (
          <Grid.Column
            key={product.id}
            computer="5"
            mobile="16"
            className="ProductList-col"
            stretched
          >
            <ProductCard
              addVariantToCart={addVariantToCart}
              product={product}
            />
          </Grid.Column>
        ))}
      </Grid.Row>
    </Grid>
  );
};
