import React, { Component } from "react";
import {
  Container,
  Grid,
  Segment,
  Form,
  Header,
  Message,
} from "semantic-ui-react";

// redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  passwordReset,
  getManagers,
  getLocations,
  putMe,
} from "../actions/user";

class MyProfile extends Component {
  state = {
    formData: {
      password: "",
      newPassword: "",
      confirmPassword: "",
    },
    didSave: false,
  };

  componentDidMount() {
    this.props.getManagers();
    this.props.getLocations();
  }

  handleChange(e, data) {
    this.setState((state) => ({
      ...state,
      formData: {
        ...state.formData,
        [data.name]: data.value,
      },
      didSave: false,
    }));
  }

  handleSubmit(e) {
    const { password, newPassword } = this.state.formData;
    const { id } = this.props.user.detail;
    this.props.passwordReset({
      id,
      password,
      newPassword,
    });
    this.setState({
      password: "",
      newPassword: "",
      confirmPassword: "",
    });
    e.preventDefault();
  }

  async handleProfileUpdate() {
    const didSave = this.props.putMe(this.state.formData);
    if (didSave) {
      this.setState({
        didSave: true,
      });
    }
  }

  get isEdited() {
    const { firstname, lastname, location, approverId } = this.state.formData;

    return !!firstname || !!lastname || !!location || !!approverId;
  }

  render() {
    const { user } = this.props;
    const { pwReset, detail, locations } = user;
    const { username, firstname, lastname, location } = detail;
    const { formData, didSave } = this.state;
    const { password, newPassword, confirmPassword } = formData;
    const match =
      newPassword === confirmPassword &&
      newPassword !== "" &&
      confirmPassword !== "" &&
      newPassword.length > 5;

    const locationOptions = locations?.map((l) => {
      return {
        text: `${l.name} - ${l.streetAddress}`,
        value: l.id,
      };
    });

    return (
      <Container>
        <Grid centered>
          <Grid.Column computer={6} mobile={16}>
            <Header as="h3">Edit Profile</Header>
            <p>Edit your profile below.</p>
            <Segment>
              <Form>
                <Form.Input
                  name="username"
                  label="Username"
                  placeholder="Username"
                  value={username}
                  disabled
                />
                <Form.Input
                  name="firstname"
                  label="First name"
                  defaultValue={firstname}
                  onChange={this.handleChange.bind(this)}
                />
                <Form.Input
                  name="lastname"
                  label="Last name"
                  defaultValue={lastname}
                  onChange={this.handleChange.bind(this)}
                />

                {detail.role === "corporate-user" && (
                  <React.Fragment>
                    <Form.Select
                      name="location"
                      label="Location"
                      options={locationOptions}
                      defaultValue={location}
                      required
                      placeholder="Location"
                      onChange={this.handleChange.bind(this)}
                    />
                  </React.Fragment>
                )}
                <Form.Group>
                  <Form.Button
                    type="submit"
                    content="Save"
                    color="primary"
                    disabled={!this.isEdited}
                    onClick={this.handleProfileUpdate.bind(this)}
                  />
                </Form.Group>
                {didSave && <Message success>Saved!</Message>}
              </Form>
            </Segment>
          </Grid.Column>

          <Grid.Column computer={6} mobile={16}>
            <Header as="h3">Password Reset</Header>
            <p>Change your password below.</p>
            <Segment>
              <Form>
                <Form.Input
                  name="password"
                  label="Password"
                  placeholder="Password"
                  type="password"
                  value={password}
                  onChange={this.handleChange.bind(this)}
                />
                <Form.Input
                  name="newPassword"
                  label="New Password"
                  placeholder="New Password"
                  type="password"
                  value={newPassword}
                  onChange={this.handleChange.bind(this)}
                />
                <Form.Input
                  name="confirmPassword"
                  label="Confirm Password"
                  placeholder="Confirm Password"
                  type="password"
                  value={confirmPassword}
                  onChange={this.handleChange.bind(this)}
                />
                <small>Ensure password is at least 5 characters long.</small>
                <br />
                <br />
                {pwReset && <Message content="Password Reset Successful!" />}

                <Form.Button
                  fluid
                  onClick={this.handleSubmit.bind(this)}
                  disabled={!match}
                >
                  {!match ? "New Passwords Do Not Match" : "Change Password"}
                </Form.Button>
              </Form>
            </Segment>
          </Grid.Column>
        </Grid>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    ui: state.ui,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      passwordReset,
      getManagers,
      getLocations,
      putMe,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(MyProfile);
