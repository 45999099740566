import React from "react";
import { Button, Table, Image, List, Grid } from "semantic-ui-react";
import { Link } from "react-router-dom";

const ManageProductsTable = ({
  products,
  editProduct,
  limit,
  offset,
  loadMore,
}) => {
  const renderBaseProductData = (product, i) => {
    const {
      id,
      name,
      description,
      images,
      category,
      enabled,
      createdAt,
      v_productVariants,
    } = product;
    const edit = (
      <Button
        as={Link}
        to={`/admin/manage-products/edit/${id}`}
        basic
        circular
        icon="edit"
        fluid
        size="mini"
      />
    );
    const imageNodes = images.map((image, idx) => {
      return (
        <List.Item key={idx}>
          <Image
            src={`${process.env.PUBLIC_URL}/storage/` + image}
            size="mini"
          />
        </List.Item>
      );
    });
    const imageListItem = (
      <List
        horizontal
        style={{ whiteSpace: "nowrap", overflowY: "auto", maxWidth: "150px" }}
      >
        {imageNodes}
      </List>
    );
    const sizes = v_productVariants.map(({ size }) => size).join(", ");
    const instockqty = v_productVariants.reduce(
      (acc, { instock }) => acc + instock,
      0
    );

    return {
      key: id,
      cells: [
        { collapsing: true, content: edit },
        name,
        description || "",
        <Table.Cell>{imageListItem}</Table.Cell>,
        category ? category.name : "",
        { key: id + "enabled", content: enabled ? "Yes" : "No" },
        new Date(createdAt).toLocaleDateString(),
        sizes,
        { key: id + "onhand", content: instockqty },
      ],
    };
  };
  return (
    <div>
      <Table
        definition
        headerRow={[
          "Action",
          "Name",
          "Description",
          "Images",
          "Category",
          "Enabled",
          "Created At",
          "Size Variants",
          "On Hand",
        ]}
        tableData={products}
        renderBodyRow={renderBaseProductData}
      />
      <Grid centered>
        <Grid.Column width={4}>
          <Button
            floated="right"
            basic
            circular
            fluid
            disabled={products ? products.length < limit : null}
            onClick={loadMore}
            content={
              products.length < limit ? "No more products." : "Next Page"
            }
          />
        </Grid.Column>
      </Grid>
    </div>
  );
};

export default ManageProductsTable;
