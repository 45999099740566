// @ts-check
import axios from "../api/axios";

export const TYPES = {
  // Products
  ADMIN_GET_PRODUCTS: "ADMIN_GET_PRODUCTS",
  ADMIN_GET_SINGLE_PRODUCT: "ADMIN_GET_SINGLE_PRODUCT",
  ADMIN_CREATE_PRODUCT: "ADMIN_CREATE_PRODUCT",
  ADMIN_EDIT_PRODUCT: "ADMIN_EDIT_PRODUCT",
  ADMIN_EDIT_PRODUCT_PROP: "ADMIN_EDIT_PRODUCT_PROP",
  ADMIN_CLEAR_PRODUCT: "ADMIN_CLEAR_PRODUCT",
  ADMIN_DELETE_PRODUCT_IMAGE: "ADMIN_DELETE_PRODUCT_IMAGE",

  // Categories
  ADMIN_GET_CATEGORIES: "ADMIN_GET_CATEGORIES",
  ADMIN_CREATE_CATEGORY: "ADMIN_CREATE_CATEGORY",
  ADMIN_EDIT_CATEGORY: "ADMIN_EDIT_CATEGORY",
  ADMIN_DELETE_CATEGORY: "ADMIN_DELETE_CATEGORY",

  // Variants
  ADMIN_EDIT_PRODUCT_VARIANT: "ADMIN_EDIT_PRODUCT_VARIANT",
  ADMIN_CREATE_PRODUCT_VARIANT: "ADMIN_CREATE_PRODUCT_VARIANT",
  ADMIN_DELETE_PRODUCT_VARIANT: "ADMIN_DELETE_PRODUCT_VARIANT",
  ADMIN_ADJUST_PRODUCT_VARIANT: "ADMIN_ADJUST_PRODUCT_VARIANT",
  ADMIN_GET_ADJUSTMENT_HISTORY: "ADMIN_GET_ADJUSTMENT_HISTORY",
  ADMIN_GET_MORE_ADJUSTMENT_HISTORY: "ADMIN_GET_MORE_ADJUSTMENT_HISTORY",

  // Users
  ADMIN_GET_USERS: "ADMIN_GET_USERS",
  ADMIN_GET_SINGLE_USER: "ADMIN_GET_SINGLE_USER",
  ADMIN_CREATE_USER: "ADMIN_CREATE_USER",
  ADMIN_DELETE_USER: "ADMIN_DELETE_USER",
  ADMIN_EDIT_USER: "ADMIN_EDIT_USER",
  ADMIN_EDIT_USER_PROP: "ADMIN_EDIT_USER_PROP",
  ADMIN_USER_PASSWORD_RESET: "ADMIN_USER_PASSWORD_RESET",
  ADMIN_CLEAR_USER: "ADMIN_CLEAR_USER",
  ADMIN_CLEAR_USERS: "ADMIN_CLEAR_USERS",
  ADMIN_GET_APPROVERS: "ADMIN_GET_APPROVERS",
  ADMIN_CREATE_USER_ADDRESS: "ADMIN_CREATE_USER_ADDRESS",
  ADMIN_DELETE_USER_ADDRESS: "ADMIN_DELETE_USER_ADDRESS",
  ADMIN_EDIT_USER_ADDRESS: "ADMIN_EDIT_USER_ADDRESS",

  // settings
  ADMIN_GET_SETTINGS: "ADMIN_GET_SETTINGS",
  ADMIN_SET_SETTING: "ADMIN_SET_SETTING",

  // orders
  ADMIN_GET_ORDERS: "ADMIN_GET_ORDERS",
  ADMIN_GET_ORDERS_GROUPED: "ADMIN_GET_ORDERS_GROUPED",
  ADMIN_GET_ORDERS_VIRTUAL: "ADMIN_GET_ORDERS_VIRTUAL",
  ADMIN_SET_ORDER_STATUS: "ADMIN_SET_ORDER_STATUS",
  ADMIN_SHIP_ORDER: "ADMIN_SHIP_ORDER",

  // dashboard / reports
  ADMIN_GET_LOW_INVENTORY: "ADMIN_GET_LOW_INVENTORY",
  ADMIN_GET_ORDER_STATS: "ADMIN_GET_ORDER_STATS",

  // size guide
  ADMIN_GET_SIZE_GUIDE: "ADMIN_GET_SIZE_GUIDE",
  ADMIN_EDIT_SIZE_GUIDE: "ADMIN_EDIT_SIZE_GUIDE",
  ADMIN_CREATE_SIZE_GUIDE: "ADMIN_EDIT_SIZE_GUIDE",
  ADMIN_DELETE_SIZE_GUIDE: "ADMIN_DELETE_SIZE_GUIDE",

  // ship addresses
  ADMIN_GET_SHIP_ADDRESSES: "ADMIN_GET_SHIP_ADDRESSES",
  ADMIN_EDIT_SHIP_ADDRESS: "ADMIN_EDIT_SHIP_ADDRESS",
  ADMIN_CREATE_SHIP_ADDRESS: "ADMIN_CREATE_SHIP_ADDRESS",
  ADMIN_DELETE_SHIP_ADDRESS: "ADMIN_DELETE_SHIP_ADDRESS",

  // product tokens
  ADMIN_GET_PRODUCT_TOKENS: "ADMIN_GET_PRODUCT_TOKENS",
  ADMIN_EDIT_PRODUCT_TOKEN: "ADMIN_EDIT_PRODUCT_TOKEN",
  ADMIN_CREATE_PRODUCT_TOKEN: "ADMIN_CREATE_PRODUCT_TOKEN",
  ADMIN_DELETE_PRODUCT_TOKEN: "ADMIN_DELETE_PRODUCT_TOKEN",
  ADMIN_GET_PRODUCT_TOKEN_PRODUCT_OPTIONS:
    "ADMIN_GET_PRODUCT_TOKEN_PRODUCT_OPTIONS",

  ADMIN_GET_ORDER_DETAIL_REPORT: "ADMIN_GET_ORDER_DETAIL_REPORT",
};

export function adminGetProduct({ offset, limit, enabled }) {
  return async (dispatch) => {
    const options = {
      method: "get",
      url: "/api/product",
      params: {
        offset,
        limit,
        order: ["id"],
        enabled,
      },
    };
    const type = TYPES.ADMIN_GET_PRODUCTS;
    const { data } = await axios(options, dispatch, type);
    dispatch({ type, payload: data });
  };
}

export function adminGetSingleProduct({ id }) {
  return async (dispatch) => {
    const options = {
      method: "get",
      url: "/api/product",
      params: {
        id,
      },
    };
    const type = TYPES.ADMIN_GET_SINGLE_PRODUCT;
    const { data } = await axios(options, dispatch, type);
    dispatch({ type, payload: data });
  };
}

export function adminEditProduct(product) {
  return async (dispatch) => {
    const options = {
      method: "put",
      url: "/api/admin/product",
      data: product,
    };
    const type = TYPES.ADMIN_EDIT_PRODUCT;
    const { data } = await axios(options, dispatch, type);
    dispatch({ type, payload: data });
    adminGetSingleProduct({ id: product.id })(dispatch);
  };
}

export function adminCreateProduct(product) {
  return async (dispatch) => {
    const options = {
      method: "POST",
      url: "/api/admin/product",
      data: product,
    };
    const type = TYPES.ADMIN_CREATE_PRODUCT;
    const { data } = await axios(options, dispatch, type);
    dispatch({ type, payload: data });
    adminGetSingleProduct({ id: data.id })(dispatch);
  };
}

export function adminEditProductProp(key, val) {
  return (dispatch) => {
    const type = TYPES.ADMIN_EDIT_PRODUCT_PROP;
    dispatch({ type, payload: { [key]: val } });
  };
}

export function adminDeleteProductImage({ id, images }) {
  return async (dispatch) => {
    const options = {
      method: "delete",
      url: "/api/admin/product/image",
      data: {
        id,
        images,
      },
    };
    const type = TYPES.ADMIN_DELETE_PRODUCT_IMAGE;
    const { data } = await axios(options, dispatch, type);
    dispatch({ type, payload: data });
    adminGetSingleProduct({ id })(dispatch);
  };
}

export function adminUploadProductImage(formData, id) {
  return async (dispatch) => {
    const options = {
      method: "post",
      url: "/api/admin/product/image",
      data: formData,
    };
    const type = TYPES.ADMIN_DELETE_PRODUCT_IMAGE;
    const { data } = await axios(options, dispatch, type);
    dispatch({ type, payload: data });
    adminGetSingleProduct({ id })(dispatch);
  };
}

export function adminGetCategories() {
  return async (dispatch) => {
    const options = {
      method: "get",
      url: "/api/admin/product/category",
      params: {},
    };
    const type = TYPES.ADMIN_GET_CATEGORIES;
    const { data } = await axios(options, dispatch, type);
    dispatch({ type, payload: data });
  };
}

export function adminCreateCategory({ name, description }) {
  return async (dispatch) => {
    const options = {
      method: "post",
      url: "/api/admin/product/category",
      data: { name, description },
    };
    const type = TYPES.ADMIN_CREATE_CATEGORY;
    const { data } = await axios(options, dispatch, type);
    dispatch({ type, payload: data });
    adminGetCategories()(dispatch);
  };
}
export function adminEditCategory({ id, name, description }) {
  return async (dispatch) => {
    const options = {
      method: "put",
      url: "/api/admin/product/category",
      data: { id, name, description },
    };
    const type = TYPES.ADMIN_EDIT_CATEGORY;
    const { data } = await axios(options, dispatch, type);
    dispatch({ type, payload: data });
    adminGetCategories()(dispatch);
  };
}
export function adminDeleteCategory({ id }) {
  return async (dispatch) => {
    const options = {
      method: "delete",
      url: "/api/admin/product/category",
      data: { id },
    };
    const type = TYPES.ADMIN_DELETE_CATEGORY;
    const { data } = await axios(options, dispatch, type);
    dispatch({ type, payload: data });
    adminGetCategories()(dispatch);
  };
}

export function adminEditProductVariant(variant) {
  return async (dispatch) => {
    const options = {
      method: "put",
      url: "/api/admin/product/variant",
      data: variant,
    };
    const type = TYPES.ADMIN_EDIT_PRODUCT_VARIANT;
    const { data } = await axios(options, dispatch, type);
    dispatch({ type, payload: data });
    adminGetSingleProduct({ id: variant.productId })(dispatch);
  };
}

export function adminAdjustProductVariant({
  productVariantId,
  qty,
  ref,
  detail,
  productId,
}) {
  return async (dispatch) => {
    const options = {
      method: "put",
      url: "/api/admin/product/variant/adjust",
      data: { productVariantId, qty, ref, detail },
    };
    const type = TYPES.ADMIN_ADJUST_PRODUCT_VARIANT;
    const { data } = await axios(options, dispatch, type);
    dispatch({ type, payload: data });
    adminGetSingleProduct({ id: productId })(dispatch);
  };
}

export function adminCreateProductVariant(variant) {
  return async (dispatch) => {
    const options = {
      method: "post",
      url: "/api/admin/product/variant",
      data: variant,
    };
    const type = TYPES.ADMIN_CREATE_PRODUCT_VARIANT;
    const { data } = await axios(options, dispatch, type);
    dispatch({ type, payload: data });
    adminGetSingleProduct({ id: variant.productId })(dispatch);
  };
}

export function adminDeleteProductVariant(variant) {
  return async (dispatch) => {
    const options = {
      method: "delete",
      url: "/api/admin/product/variant",
      data: variant,
    };
    const type = TYPES.ADMIN_DELETE_PRODUCT_VARIANT;
    const { data } = await axios(options, dispatch, type);
    dispatch({ type, payload: data });
    adminGetSingleProduct({ id: variant.productId })(dispatch);
  };
}

export function adminClearProduct() {
  return (dispatch) => {
    dispatch({ type: TYPES.ADMIN_CLEAR_PRODUCT });
  };
}

export function adminGetUsers({ offset, limit, enabled, refresh, ...rest }) {
  return async (dispatch) => {
    const options = {
      method: "get",
      url: "/api/admin/user",
      params: {
        offset,
        limit,
        enabled,
        ...rest,
      },
    };
    if (refresh) {
      dispatch({ type: TYPES.ADMIN_CLEAR_USERS });
    }
    const type = TYPES.ADMIN_GET_USERS;
    const { data } = await axios(options, dispatch, type);
    dispatch({ type, payload: data });
  };
}

export function adminGetSingleUser({ id }) {
  return async (dispatch) => {
    const options = {
      method: "get",
      url: "/api/admin/user",
      params: {
        id,
      },
    };
    const type = TYPES.ADMIN_GET_SINGLE_USER;
    const { data } = await axios(options, dispatch, type);
    dispatch({ type, payload: data });
  };
}

export function adminGetApprovers() {
  return async (dispatch) => {
    const options = {
      method: "get",
      url: "/api/admin/user/approvers",
      params: {},
    };
    const type = TYPES.ADMIN_GET_APPROVERS;
    const { data } = await axios(options, dispatch, type);
    dispatch({ type, payload: data });
  };
}

export function adminEditUserProp(key, val) {
  return (dispatch) => {
    const type = TYPES.ADMIN_EDIT_USER_PROP;
    dispatch({ type, payload: { [key]: val } });
  };
}

export function adminEditUser(body) {
  delete body.newPassword;
  return async (dispatch) => {
    const options = {
      method: "put",
      url: "/api/admin/user",
      data: body,
    };
    const type = TYPES.ADMIN_EDIT_USER;
    const { data } = await axios(options, dispatch, type);
    dispatch({ type, payload: data[0] });
    adminGetSingleUser({ id: data[0].id })(dispatch);
  };
}

export function adminChangePassword({ id, password }) {
  return async (dispatch) => {
    const options = {
      method: "post",
      url: "/api/admin/user/password-reset",
      data: {
        id,
        password,
      },
    };
    const type = TYPES.ADMIN_USER_PASSWORD_RESET;
    const { data } = await axios(options, dispatch, type);
    dispatch({ type, payload: data });
    adminEditUserProp("newPasswordStatus", true)(dispatch);
  };
}

export function adminCreateUserAddress(address) {
  return async (dispatch) => {
    const options = {
      method: "post",
      url: "/api/admin/user/address",
      data: address,
    };
    const type = TYPES.ADMIN_CREATE_USER_ADDRESS;
    const { data } = await axios(options, dispatch, type);
    dispatch({ type, payload: data });
    adminGetSingleUser({ id: data.userId })(dispatch);
  };
}
export function adminEditUserAddress(address) {
  return async (dispatch) => {
    const options = {
      method: "put",
      url: "/api/admin/user/address",
      data: address,
    };
    const type = TYPES.ADMIN_EDIT_USER_ADDRESS;
    const { data } = await axios(options, dispatch, type);
    dispatch({ type, payload: data });
    adminGetSingleUser({ id: address.userId })(dispatch);
  };
}

export function adminDeleteUserAddress(address) {
  return async (dispatch) => {
    const options = {
      method: "delete",
      url: "/api/admin/user/address",
      data: address,
    };
    const type = TYPES.ADMIN_DELETE_USER_ADDRESS;
    const { data } = await axios(options, dispatch, type);
    dispatch({ type, payload: data });
    adminGetSingleUser({ id: address.userId })(dispatch);
  };
}

export function adminCreateUser(user) {
  return async (dispatch) => {
    const options = {
      method: "post",
      url: "/api/admin/user",
      data: user,
    };
    const type = TYPES.ADMIN_CREATE_USER;
    const { data } = await axios(options, dispatch, type);
    dispatch({ type, payload: data });
  };
}

export function adminDeleteUser(user) {
  return async (dispatch) => {
    const options = {
      method: "delete",
      url: "/api/admin/user",
      data: user,
    };
    const type = TYPES.ADMIN_DELETE_USER;
    await axios(options, dispatch, type);
    dispatch({ type, payload: user });
  };
}

export function adminClearUser() {
  return (dispatch) => {
    dispatch({ type: TYPES.ADMIN_CLEAR_USER });
  };
}

export function adminGetSettings() {
  return async (dispatch) => {
    const options = {
      method: "get",
      url: "/api/admin/settings",
      params: {},
    };
    const type = TYPES.ADMIN_GET_SETTINGS;
    const { data } = await axios(options, dispatch, type);
    dispatch({ type, payload: data });
  };
}

export function adminSetSetting(id, value) {
  return async (dispatch) => {
    const options = {
      method: "post",
      url: "/api/admin/settings",
      data: {
        id,
        value,
      },
    };
    const type = TYPES.ADMIN_SET_SETTING;
    const { data } = await axios(options, dispatch, type);
    dispatch({ type, payload: data });
    adminGetSettings()(dispatch);
  };
}

export function adminGetOrders({
  offset,
  limit,
  status,
  orderNum,
  startDate,
  endDate,
}) {
  return async (dispatch) => {
    const options = {
      method: "get",
      url: "/api/admin/order",
      params: {
        offset,
        limit,
        status,
        orderNum,
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
      },
    };
    const type = TYPES.ADMIN_GET_ORDERS;
    const { data } = await axios(options, dispatch, type);
    dispatch({ type, payload: data });
  };
}

export function adminGetOrdersGrouped({
  offset,
  limit,
  status,
  startDate,
  endDate,
}) {
  return async (dispatch) => {
    const options = {
      method: "get",
      url: "/api/admin/order/grouped",
      params: {
        offset,
        limit,
        status,
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
      },
    };
    const type = TYPES.ADMIN_GET_ORDERS_GROUPED;
    const { data } = await axios(options, dispatch, type);
    dispatch({ type, payload: data });
  };
}

export function adminSetOrderStatus({ id, status }, lastSearch) {
  return async (dispatch) => {
    const options = {
      method: "post",
      url: "/api/admin/order/status",
      data: {
        id,
        status,
      },
    };
    const type = TYPES.ADMIN_SET_ORDER_STATUS;
    const { data } = await axios(options, dispatch, type);
    dispatch({ type, payload: data });
    if (lastSearch) {
      adminGetOrders(lastSearch)(dispatch);
    }
  };
}

export function adminShipOrder({ id, courier, tracking }, lastSearch) {
  return async (dispatch) => {
    const ids = Array.isArray(id) ? id : [id];
    const promises = [];

    for (const orderId of ids) {
      const options = {
        method: "post",
        url: "/api/admin/order/ship",
        data: {
          id: orderId,
          courier,
          tracking,
        },
      };

      const type = TYPES.ADMIN_SHIP_ORDER;
      promises.push(axios(options, dispatch, type));
    }
    const result = await promises;
    adminGetOrders(lastSearch)(dispatch);
    adminGetOrdersGrouped(lastSearch)(dispatch);
    return result;
  };
}

export function adminGetLowInventory() {
  return async (dispatch) => {
    const options = {
      method: "get",
      url: "/api/admin/report/low-inventory",
      params: {},
    };
    const type = TYPES.ADMIN_GET_LOW_INVENTORY;
    const { data } = await axios(options, dispatch, type);
    dispatch({ type, payload: data });
  };
}

export function adminGetOrderStats() {
  return async (dispatch) => {
    const options = {
      method: "get",
      url: "/api/admin/report/order-stats",
      params: {},
    };
    const type = TYPES.ADMIN_GET_ORDER_STATS;
    const { data } = await axios(options, dispatch, type);
    dispatch({ type, payload: data });
  };
}

export function adminGetSizeGuide() {
  return async (dispatch) => {
    const options = {
      method: "get",
      url: "/api/admin/size-guide",
      params: {},
    };
    const type = TYPES.ADMIN_GET_SIZE_GUIDE;
    const { data } = await axios(options, dispatch, type);
    dispatch({ type, payload: data });
  };
}

export function adminCreateSizeGuide(body) {
  return async (dispatch) => {
    const options = {
      method: "post",
      url: "/api/admin/size-guide",
      data: { ...body, neck: [0, 0] },
    };
    const type = TYPES.ADMIN_CREATE_SIZE_GUIDE;
    const { data } = await axios(options, dispatch, type);
    dispatch({ type, payload: data });
    adminGetSizeGuide()(dispatch);
  };
}

export function adminEditSizeGuide(body) {
  return async (dispatch) => {
    const options = {
      method: "put",
      url: "/api/admin/size-guide",
      data: body,
    };
    const type = TYPES.ADMIN_EDIT_SIZE_GUIDE;
    const { data } = await axios(options, dispatch, type);
    dispatch({ type, payload: data });
    adminGetSizeGuide()(dispatch);
  };
}

export function adminDeleteSizeGuide(body) {
  return async (dispatch) => {
    const options = {
      method: "delete",
      url: "/api/admin/size-guide",
      data: body,
    };
    const type = TYPES.ADMIN_DELETE_SIZE_GUIDE;
    const { data } = await axios(options, dispatch, type);
    dispatch({ type, payload: data });
    adminGetSizeGuide()(dispatch);
  };
}

export function adminGetAdjustmentHistory(offset, limit) {
  return async (dispatch) => {
    const options = {
      method: "get",
      url: "/api/admin/product/adjustments",
      params: {
        offset,
        limit,
      },
    };
    const type = TYPES.ADMIN_GET_ADJUSTMENT_HISTORY;
    const { data } = await axios(options, dispatch, type);
    dispatch({ type, payload: data });
  };
}

export function adminGetMoreAdjustmentHistory(offset, limit) {
  return async (dispatch) => {
    const options = {
      method: "get",
      url: "/api/admin/product/adjustments",
      params: {
        offset,
        limit,
      },
    };
    const type = TYPES.ADMIN_GET_MORE_ADJUSTMENT_HISTORY;
    const { data } = await axios(options, dispatch, type);
    dispatch({ type, payload: data });
  };
}

export function adminGetShipAddresses({ offset, limit }) {
  return async (dispatch) => {
    const options = {
      method: "get",
      url: "/api/admin/addresses",
      params: {
        offset,
        limit,
      },
    };
    const type = TYPES.ADMIN_GET_SHIP_ADDRESSES;
    const { data } = await axios(options, dispatch, type);
    dispatch({ type, payload: data });
  };
}

export function adminEditShipAddress(address) {
  return async (dispatch) => {
    const options = {
      method: "put",
      url: "/api/admin/addresses",
      data: address,
    };
    const type = TYPES.ADMIN_EDIT_SHIP_ADDRESS;
    const { data } = await axios(options, dispatch, type);
    dispatch({ type, payload: data });
  };
}

export function adminDeleteShipAddress(address) {
  return async (dispatch) => {
    const options = {
      method: "delete",
      url: "/api/admin/addresses",
      data: address,
    };
    const type = TYPES.ADMIN_DELETE_SHIP_ADDRESS;
    await axios(options, dispatch, type);
    dispatch({ type, payload: address });
  };
}
export function adminCreateShipAddress(address) {
  return async (dispatch) => {
    const options = {
      method: "post",
      url: "/api/admin/addresses",
      data: address,
    };
    const type = TYPES.ADMIN_CREATE_SHIP_ADDRESS;
    const { data } = await axios(options, dispatch, type);
    dispatch({ type, payload: data });
  };
}

export function adminGetProductTokens({
  offset,
  limit,
  productId,
  startDate,
  endDate,
}) {
  return async (dispatch) => {
    const options = {
      method: "get",
      url: "/api/admin/product-token",
      params: {
        offset,
        limit,
        productId,
        createdAt: [startDate.toISOString(), endDate.toISOString()],
      },
    };
    const type = TYPES.ADMIN_GET_PRODUCT_TOKENS;
    const { data } = await axios(options, dispatch, type);
    dispatch({ type, payload: data });
    return data;
  };
}

export function adminEditProductToken(token) {
  return async (dispatch) => {
    const options = {
      method: "put",
      url: "/api/admin/product-token",
      data: token,
    };
    const type = TYPES.ADMIN_EDIT_PRODUCT_TOKEN;
    const { data } = await axios(options, dispatch, type);
    dispatch({ type, payload: data });
  };
}

export function adminDeleteProductToken(token) {
  return async (dispatch) => {
    const options = {
      method: "delete",
      url: "/api/admin/product-token/" + token.id,
    };
    const type = TYPES.ADMIN_DELETE_PRODUCT_TOKEN;
    await axios(options, dispatch, type);
    dispatch({ type, payload: token });
  };
}
export function adminCreateProductTokens(tokens) {
  return async (dispatch) => {
    const options = {
      method: "post",
      url: "/api/admin/product-token",
      data: tokens,
    };
    const type = TYPES.ADMIN_CREATE_PRODUCT_TOKEN;
    const { data } = await axios(options, dispatch, type);
    dispatch({ type, payload: data });
    return data;
  };
}

export function adminGetProductOptions(opts = {}) {
  return async (dispatch) => {
    const options = {
      method: "get",
      url: "/api/product",
      params: {
        ...opts,
      },
    };
    const type = TYPES.ADMIN_GET_PRODUCT_TOKEN_PRODUCT_OPTIONS;
    const { data } = await axios(options, dispatch, type);
    dispatch({ type, payload: data });
    return data;
  };
}

export function adminGetOrderDetailReport({
  productId = null,
  productTokenOnly = null,
  createdAtStart = null,
  createdAtEnd = null,
  offset = 0,
  limit = 100,
}) {
  return async (dispatch) => {
    const options = {
      method: "get",
      url: "/api/admin/report/order-detail",
      params: {
        productId,
        productTokenOnly,
        createdAtStart: createdAtStart.toISOString(),
        createdAtEnd: createdAtEnd.toISOString(),
        offset,
        limit,
      },
    };
    const type = TYPES.ADMIN_GET_ORDER_DETAIL_REPORT;
    const { data } = await axios(options, dispatch, type);
    dispatch({ type, payload: data });
    return data;
  };
}

export function adminGetVirtualOrders({
  startDate,
  endDate,
  productId,
  status,
  enabled,
  offset = 0,
  limit = 100,
}) {
  return async (dispatch) => {
    const options = {
      method: "get",
      url: "/api/admin/order/virtual",
      params: {
        enabled,
        startDate,
        endDate,
        productId,
        status,
        offset,
        limit,
      },
    };
    const type = TYPES.ADMIN_GET_ORDERS_VIRTUAL;
    const { data } = await axios(options, dispatch, type);
    dispatch({ type, payload: data });
    return data;
  };
}
