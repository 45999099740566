// @ts-check
import React, { Component } from "react";
import { Table, Button } from "semantic-ui-react";
import { Link } from "react-router-dom";
import { getRoleName } from "../util/getRoleName";

class ManageUserTable extends Component {
  render() {
    const { users } = this.props;
    return (
      <Table definition>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Action</Table.HeaderCell>
            <Table.HeaderCell>Username</Table.HeaderCell>
            <Table.HeaderCell>First Name</Table.HeaderCell>
            <Table.HeaderCell>Last Name</Table.HeaderCell>
            <Table.HeaderCell>Email</Table.HeaderCell>
            <Table.HeaderCell>Role</Table.HeaderCell>
            <Table.HeaderCell>Location</Table.HeaderCell>
            <Table.HeaderCell>Ship Addresses</Table.HeaderCell>
            <Table.HeaderCell>Requesters</Table.HeaderCell>
            <Table.HeaderCell>Approver</Table.HeaderCell>
            <Table.HeaderCell>Enabled</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {users.map(
            ({
              id,
              username,
              firstname,
              lastname,
              email,
              role,
              locationDetail,
              shipAddresses,
              requesters,
              approver,
              enabled,
            }) => (
              <Table.Row key={id}>
                <Table.Cell collapsing>
                  <Button
                    icon="edit"
                    circular
                    basic
                    as={Link}
                    to={`/admin/manage-users/edit/${id}`}
                  />
                </Table.Cell>
                <Table.Cell>{username}</Table.Cell>
                <Table.Cell>{firstname}</Table.Cell>
                <Table.Cell>{lastname}</Table.Cell>
                <Table.Cell>{email}</Table.Cell>
                <Table.Cell>{getRoleName(role)}</Table.Cell>
                <Table.Cell>{locationDetail?.name}</Table.Cell>
                <Table.Cell>{shipAddresses.length}</Table.Cell>
                <Table.Cell>{requesters.length}</Table.Cell>
                <Table.Cell>{approver ? approver.username : ""}</Table.Cell>
                <Table.Cell>{enabled ? "Yes" : "No"}</Table.Cell>
              </Table.Row>
            )
          )}
        </Table.Body>
      </Table>
    );
  }
}

export default ManageUserTable;
