import React, { Component } from 'react'

import { Table, Button, Form, Input, Modal, Select } from 'semantic-ui-react'

class EditProductVariants extends Component {
  state = {
    variantForm: {
      productId: this.props.productId
    },
    adjust: false,
    adjustForm: {
      qty: 0,
      detail: '',
      ref: ''
    }
  }
  handleChange(e, data) {
    const prevState = this.state.variantForm
    let variantForm = { ...prevState, [data.name]: data.value }
    this.setState({
      variantForm
    })
  }
  selectToEditVariant(variant) {
    this.setState({
      variantForm: variant
    })
  }
  resetForm() {
    this.setState({
      variantForm: {
        sku: '',
        size: '',
        limit: '',
        instock: '',
        id: '',
        sortOrder: ''
      },
      adjustForm: {
        qty: 0,
        detail: '',
        ref: ''
      },
      adjust: false
    })
  }

  createVariant() {
    this.props.adminCreateProductVariant(this.state.variantForm)
    this.setState(
      {
        variantForm: {
          sku: '',
          size: '',
          limit: '',
          instock: '',
          id: '',
          sortOrder: ''
        }
      },
      () => this.setState({ variantForm: { productId: this.props.productId } })
    )
  }
  editVariant() {
    this.props.adminEditProductVariant(this.state.variantForm)
    this.setState(
      {
        variantForm: {
          sku: '',
          limit: '',
          instock: '',
          id: '',
          sortOrder: '',
          size: ''
        }
      },
      () => this.setState({ variantForm: { productId: this.props.productId } })
    )
  }

  handleAdjustFormChange(e, data) {
    const prevState = this.state.adjustForm
    let adjustForm = { ...prevState, [data.name]: data.value }
    this.setState({
      adjustForm
    })
  }

  adjustVariant() {
    const { variantForm, adjustForm } = this.state

    this.props.adminAdjustProductVariant({
      productVariantId: variantForm.id,
      productId: variantForm.productId,
      ...adjustForm
    })

    this.resetForm()
  }

  render() {
    const { adminDeleteProductVariant, productVariants } = this.props
    const { variantForm } = this.state
    const { id, sku, size, sortOrder, instock, limit } = variantForm
    const isComplete = sku && size && sortOrder && instock
    return (
      <div>
        <Table definition>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Action</Table.HeaderCell>
              <Table.HeaderCell>SKU</Table.HeaderCell>
              <Table.HeaderCell>Size</Table.HeaderCell>
              <Table.HeaderCell>Sort Order</Table.HeaderCell>
              <Table.HeaderCell>Limit</Table.HeaderCell>
              <Table.HeaderCell>On Hand</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            <Table.Row>
              <Table.Cell>
                <Button
                  circular
                  icon="add"
                  color="green"
                  basic
                  fluid
                  onClick={
                    id
                      ? this.editVariant.bind(this)
                      : this.createVariant.bind(this)
                  }
                  disabled={!isComplete}
                />
              </Table.Cell>
              <Table.Cell>
                <Form.Field
                  control={Input}
                  placeholder="SKU"
                  fluid
                  name="sku"
                  value={sku}
                  onChange={this.handleChange.bind(this)}
                />
              </Table.Cell>
              <Table.Cell>
                <Form.Field
                  control={Input}
                  placeholder="Size"
                  fluid
                  name="size"
                  value={size}
                  onChange={this.handleChange.bind(this)}
                />
              </Table.Cell>
              <Table.Cell>
                <Form.Field
                  control={Input}
                  placeholder="Sort Order"
                  type="number"
                  min="1"
                  fluid
                  name="sortOrder"
                  value={sortOrder}
                  onChange={this.handleChange.bind(this)}
                />
              </Table.Cell>
              <Table.Cell>
                <Form.Field
                  control={Input}
                  placeholder="Limit"
                  type="number"
                  min="1"
                  fluid
                  name="limit"
                  value={limit}
                  onChange={this.handleChange.bind(this)}
                />
              </Table.Cell>
              <Table.Cell>
                <Form.Field
                  control={Input}
                  placeholder="On Hand"
                  type="number"
                  min="0"
                  fluid
                  name="instock"
                  value={instock}
                  onChange={this.handleChange.bind(this)}
                  disabled={id}
                />
              </Table.Cell>
            </Table.Row>
            {productVariants
              .filter(({ id }) => id !== variantForm.id)
              .sort((a, b) => a.sortOrder - b.sortOrder)
              .map(variant =>
                <Table.Row key={variant.id}>
                  <Table.Cell collapsing>
                    <Button.Group>
                      <Button
                        icon="edit"
                        basic
                        circular
                        color="grey"
                        onClick={() => this.selectToEditVariant(variant)}
                      />
                      <Button
                        icon="delete"
                        basic
                        color="red"
                        circular
                        onClick={() => adminDeleteProductVariant(variant)}
                      />
                      <Button
                        basic
                        content="Adjust Qty"
                        color="grey"
                        onClick={() => {
                          this.selectToEditVariant(variant)
                          this.setState({ adjust: true })
                        }}
                      />
                    </Button.Group>
                  </Table.Cell>
                  <Table.Cell>
                    {variant.sku}
                  </Table.Cell>
                  <Table.Cell>
                    {variant.size}
                  </Table.Cell>
                  <Table.Cell>
                    {variant.sortOrder}
                  </Table.Cell>
                  <Table.Cell>
                    {variant.limit}
                  </Table.Cell>
                  <Table.Cell>
                    {variant.instock}
                  </Table.Cell>
                </Table.Row>
              )}
          </Table.Body>
        </Table>

        <Modal
          open={this.state.adjust}
          onClose={() => {
            this.setState({ adjust: false })
            this.resetForm()
          }}
          size="small"
        >
          <Modal.Header>Adjustment</Modal.Header>
          <Modal.Content>
            Adjusting: {sku}
            <br />
            <br />
            <div className="ui form">
              <Form.Group widths="equal">
                <Form.Field
                  label="Quantity"
                  control={Input}
                  placeholder="Qty"
                  fluid
                  type="number"
                  name="qty"
                  onChange={this.handleAdjustFormChange.bind(this)}
                />
                <Form.Field
                  label="Reason"
                  control={Select}
                  placeholder="Reason"
                  fluid
                  name="detail"
                  options={[
                    { key: 0, text: 'Adjustment', value: 'Adjustment' },
                    { key: 1, text: 'PO Receipt', value: 'PO Receipt' }
                  ]}
                  onChange={this.handleAdjustFormChange.bind(this)}
                />
                <Form.Field
                  label="Reference"
                  control={Input}
                  placeholder="Reference"
                  fluid
                  name="ref"
                  onChange={this.handleAdjustFormChange.bind(this)}
                />
              </Form.Group>
            </div>
          </Modal.Content>
          <Modal.Actions>
            <Button
              basic
              color="grey"
              content="Cancel"
              onClick={() => {
                this.setState({ adjust: false })
                this.resetForm()
              }}
            />
            <Button
              basic
              color="green"
              content="Submit"
              disabled={
                !this.state.adjustForm.qty || !this.state.adjustForm.detail
              }
              onClick={this.adjustVariant.bind(this)}
            />
          </Modal.Actions>
        </Modal>
      </div>
    )
  }
}

export default EditProductVariants
