// @ts-check
import { TYPES } from "../actions/admin";
const initialState = {
  products: [],
  categories: [],
  singleProduct: {},
  users: [],
  approvers: [],
  singleUser: {},
  isUsersLoading: false,
  isProductsLoading: false,
  settings: [],
  isSettingsLoading: false,
  orders: [],
  ordersGrouped: [],
  ordersVirtual: [],
  isOrderLoading: false,
  isDashboardLoading: false,
  orderStats: {},
  lowInventory: [],
  sizeGuide: [],
  adjustmentHistory: [],
  shipAddresses: [],
  isShipAddressesLoading: false,
  productTokens: [],
  productTokenProductOptions: [],
  isProductTokensLoading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case `R_${TYPES.ADMIN_GET_PRODUCTS}`:
      return {
        ...state,
        isProductsLoading: true,
      };
    case TYPES.ADMIN_GET_PRODUCTS:
      return {
        ...state,
        products: [...action.payload],
        isProductsLoading: false,
      };
    case `R_${TYPES.ADMIN_GET_SINGLE_PRODUCT}`:
      return {
        ...state,
        isProductsLoading: true,
      };
    case TYPES.ADMIN_GET_SINGLE_PRODUCT:
      return {
        ...state,
        products: [],
        singleProduct: action.payload[0],
        isProductsLoading: false,
      };
    case `R_${TYPES.ADMIN_EDIT_PRODUCT}`:
      return {
        ...state,
        isProductsLoading: true,
      };
    case TYPES.ADMIN_EDIT_PRODUCT:
      return {
        ...state,
        products: [],
        singleProduct: action.payload,
        isProductsLoading: false,
      };
    case TYPES.ADMIN_EDIT_PRODUCT_PROP:
      let nextSingleProduct = {
        ...state.singleProduct,
        ...action.payload,
        isEdited: true,
      };
      return {
        ...state,
        singleProduct: nextSingleProduct,
      };
    case `R_${TYPES.ADMIN_GET_CATEGORIES}`:
      return {
        ...state,
        isProductsLoading: true,
      };
    case TYPES.ADMIN_GET_CATEGORIES:
      return {
        ...state,
        categories: [...action.payload],
        isProductsLoading: false,
      };
    case TYPES.ADMIN_CREATE_PRODUCT:
      return {
        ...state,
        singleProduct: {},
      };
    case TYPES.ADMIN_CLEAR_PRODUCT:
      return {
        ...state,
        singleProduct: {},
      };

    case `R_${TYPES.ADMIN_GET_USERS}`:
      return {
        ...state,
        isUsersLoading: true,
      };
    case TYPES.ADMIN_CLEAR_USERS:
      return {
        ...state,
        users: [],
        isUsersLoading: false,
      };
    case TYPES.ADMIN_GET_USERS:
      return {
        ...state,
        users: [...state.users, ...action.payload],
        isUsersLoading: false,
      };

    case `R_${TYPES.ADMIN_GET_SINGLE_USER}`:
      return {
        ...state,
        isUsersLoading: true,
      };
    case TYPES.ADMIN_GET_SINGLE_USER:
      return {
        ...state,
        singleUser: action.payload[0],
        isUsersLoading: false,
      };
    case `R_${TYPES.ADMIN_GET_APPROVERS}`:
      return {
        ...state,
        isUsersLoading: true,
      };
    case TYPES.ADMIN_GET_APPROVERS:
      return {
        ...state,
        approvers: [...action.payload],
        isUsersLoading: false,
      };
    case TYPES.ADMIN_EDIT_USER_PROP:
      let nextSingleUser = {
        ...state.singleUser,
        ...action.payload,
        isEdited: true,
      };
      return {
        ...state,
        singleUser: nextSingleUser,
      };
    case TYPES.ADMIN_CLEAR_USER:
      return {
        ...state,
        singleUser: {},
      };

    case `R_${TYPES.ADMIN_CREATE_USER}`:
    case `R_${TYPES.ADMIN_DELETE_USER}`:
      return {
        ...state,
        isUsersLoading: true,
      };
    case TYPES.ADMIN_CREATE_USER:
      return {
        ...state,
        singleUser: action.payload,
        isUsersLoading: false,
      };
    case TYPES.ADMIN_DELETE_USER:
      return {
        ...state,
        users: state.users.filter((u) => u.id !== action.payload.id),
        isUsersLoading: false,
      };

    case `R_${TYPES.ADMIN_USER_CHANGE_PASSWORD}`:
      return {
        ...state,
        isUsersLoading: true,
      };
    case TYPES.ADMIN_USER_CHANGE_PASSWORD:
      return {
        ...state,
        singleUser: { ...action.payload, newPassword: "Success!" },
        isUsersLoading: false,
      };

    case `R_${TYPES.ADMIN_GET_SETTINGS}`:
      return {
        ...state,
        isSettingsLoading: true,
      };
    case TYPES.ADMIN_GET_SETTINGS:
      return {
        ...state,
        settings: action.payload,
        isSettingsLoading: false,
      };

    case `R_${TYPES.ADMIN_GET_ORDERS_VIRTUAL}`:
    case `R_${TYPES.ADMIN_GET_ORDERS}`:
    case `R_${TYPES.ADMIN_GET_ORDERS_GROUPED}`:
      return {
        ...state,
        isOrderLoading: true,
      };

    case TYPES.ADMIN_GET_ORDERS_VIRTUAL:
      return {
        ...state,
        ordersVirtual: [...action.payload],
        isOrderLoading: false,
      };
    case TYPES.ADMIN_GET_ORDERS:
      return {
        ...state,
        orders: [...action.payload],
        isOrderLoading: false,
      };

    case TYPES.ADMIN_GET_ORDERS_GROUPED:
      return {
        ...state,
        ordersGrouped: [...action.payload],
        isOrderLoading: false,
      };

    case `R_${TYPES.ADMIN_GET_LOW_INVENTORY}`:
      return {
        ...state,
        isDashboardLoading: true,
      };
    case TYPES.ADMIN_GET_LOW_INVENTORY:
      return {
        ...state,
        lowInventory: [...action.payload],
        isDashboardLoading: false,
      };

    case `R_${TYPES.ADMIN_GET_ORDER_STATS}`:
      return {
        ...state,
        isDashboardLoading: true,
      };
    case TYPES.ADMIN_GET_ORDER_STATS:
      return {
        ...state,
        orderStats: action.payload,
        isDashboardLoading: false,
      };
    case `R_${TYPES.ADMIN_GET_SIZE_GUIDE}`:
      return {
        ...state,
        isSizeGuideLoading: true,
      };
    case TYPES.ADMIN_GET_SIZE_GUIDE:
      return {
        ...state,
        sizeGuide: action.payload,
        isSizeGuideLoading: false,
      };

    case `R_${TYPES.ADMIN_GET_ADJUSTMENT_HISTORY}`:
      return {
        ...state,
        isProductsLoading: true,
      };
    case TYPES.ADMIN_GET_ADJUSTMENT_HISTORY:
      return {
        ...state,
        adjustmentHistory: action.payload,
        isProductsLoading: false,
      };
    case `R_${TYPES.ADMIN_GET_MORE_ADJUSTMENT_HISTORY}`:
      return {
        ...state,
        isProductsLoading: true,
      };
    case TYPES.ADMIN_GET_MORE_ADJUSTMENT_HISTORY:
      return {
        ...state,
        adjustmentHistory: [...state.adjustmentHistory, ...action.payload],
        isProductsLoading: false,
      };
    case `R_${TYPES.ADMIN_GET_SHIP_ADDRESSES}`:
      return {
        ...state,
        isShipAddressesLoading: true,
      };
    case TYPES.ADMIN_GET_SHIP_ADDRESSES:
      return {
        ...state,
        shipAddresses: action.payload,
        isShipAddressesLoading: false,
      };

    case TYPES.ADMIN_EDIT_SHIP_ADDRESS: {
      const addr = action.payload;

      return {
        ...state,
        shipAddresses: state.shipAddresses.map((val) => {
          if (val.id === addr.id) {
            return addr;
          }
          return val;
        }),
        isShipAddressesLoading: false,
      };
    }

    case TYPES.ADMIN_CREATE_SHIP_ADDRESS: {
      const addr = action.payload;

      return {
        ...state,
        shipAddresses: [...state.shipAddresses, addr],
        isShipAddressesLoading: false,
      };
    }

    case TYPES.ADMIN_DELETE_SHIP_ADDRESS: {
      const addr = action.payload;

      return {
        ...state,
        shipAddresses: state.shipAddresses.filter((val) => val.id !== addr.id),
        isShipAddressesLoading: false,
      };
    }

    case `R_${TYPES.ADMIN_GET_PRODUCT_TOKENS}`:
      return {
        ...state,
        isProductTokensLoading: true,
      };
    case TYPES.ADMIN_GET_PRODUCT_TOKENS:
      return {
        ...state,
        productTokens: action.payload,
        isProductTokensLoading: false,
      };

    case `R_${TYPES.ADMIN_GET_PRODUCT_TOKEN_PRODUCT_OPTIONS}`:
      return {
        ...state,
        isProductTokensLoading: true,
      };
    case TYPES.ADMIN_GET_PRODUCT_TOKEN_PRODUCT_OPTIONS:
      return {
        ...state,
        productTokenProductOptions: action.payload,
        isProductTokensLoading: false,
      };
    default:
      return state;
  }
};
