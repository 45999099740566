import React, { useState, useMemo, useEffect, useCallback } from "react";
import { Form, Tab, Button, Divider } from "semantic-ui-react";
import { Editor } from "@tinymce/tinymce-react";
import camelCase from "lodash/camelCase";
import startCase from "lodash/startCase";
import omit from "lodash/omit";

const PaneEditor = (props) => {
  const { initialValue, name, onDelete, onSubmit } = props;
  const [paneContent, setPaneContent] = useState(props.initialValue);

  const resetValue = useCallback(() => {
    setPaneContent(initialValue);
  }, [initialValue]);

  useEffect(() => {
    resetValue();
  }, [name, initialValue, resetValue]);

  return (
    <React.Fragment>
      <Editor
        value={paneContent}
        init={{
          height: 500,
          menubar: "table",
          plugins: [
            "advlist autolink lists link image charmap print preview anchor",
            "searchreplace visualblocks code fullscreen",
            "insertdatetime media table paste code help wordcount",
          ],
          toolbar:
            // eslint-disable-next-line
            "undo redo | formatselect | bold italic backcolor | \
        alignleft aligncenter alignright alignjustify | \
        bullist numlist outdent indent | removeformat | help | \
        tableprops tabledelete | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol",
        }}
        onEditorChange={(c) => setPaneContent(c)}
      />

      <Divider />
      <Button content="Cancel" onClick={resetValue} />
      <Button
        content="Delete Content"
        color="red"
        onClick={() => onDelete(name)}
      />
      <Button
        content="Save Content"
        color="green"
        onClick={() => onSubmit(name, paneContent)}
      />
    </React.Fragment>
  );
};

export const EditProductContent = (props) => {
  const { content, onSave } = props;

  const [contentPanes, setContentPanes] = useState({});
  const [addPaneName, setAddPaneName] = useState("");

  useEffect(() => {
    if (!content) return;
    setContentPanes(content);
  }, [content]);

  const handleAddPane = () => {
    setContentPanes((c) => {
      return {
        ...c,
        [camelCase(addPaneName)]: "",
      };
    });
    setAddPaneName("");
  };

  const handleOnContentSave = (name, content) => {
    const nextPanes = {
      ...contentPanes,
      [name]: content,
    };
    setContentPanes(nextPanes);

    onSave(nextPanes);
  };
  const handleOnDelete = (name) => {
    const nextPanes = omit(contentPanes, [name]);
    setContentPanes(nextPanes);
    onSave(nextPanes);
  };

  const tabPanes = useMemo(() => {
    return Object.entries(contentPanes).reduce((acc, entry) => {
      const [name, val] = entry;
      acc.push({
        menuItem: startCase(name),
        render: () => {
          return (
            <Tab.Pane>
              <PaneEditor
                name={name}
                initialValue={val}
                onSubmit={handleOnContentSave}
                onDelete={handleOnDelete}
              />
            </Tab.Pane>
          );
        },
      });
      return acc;
    }, []);
    // eslint-disable-next-line
  }, [contentPanes]);

  return (
    <>
      <Form>
        <Form.Group>
          <Form.Input
            placeholder="Add Content Pane"
            onChange={(e, data) => setAddPaneName(data.value)}
            value={addPaneName}
          />
          <Form.Button content="Add" color="green" onClick={handleAddPane} />
        </Form.Group>
      </Form>
      <Tab menu={{ secondary: true, pointing: true }} panes={tabPanes} />
    </>
  );
};
