import React, { useState, useEffect, useMemo } from "react";
import {
  Container,
  Grid,
  Header,
  Divider,
  Button,
  Responsive,
  Tab,
  Form,
  Confirm,
  Modal,
} from "semantic-ui-react";
import { ProductOptions } from "./components/ProductOptions";
import "./ProductPage.css";
import { ProductImageSlider } from "./components/ProductImageSlider";
import startCase from "lodash/startCase";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  getProducts,
  addToCart,
  getProductToken,
  submitVirtualOrder,
} from "../../actions/product";
import { THEME } from "../../env";

const ProductPageComponent = (props) => {
  const {
    match: {
      params: { id: productId },
    },
    user,
    getProducts,
    history,
    submitVirtualOrder,
  } = props;

  const [product, setProduct] = useState(null);

  useEffect(() => {
    getProducts({ id: productId }).then(([p]) => {
      setProduct(p);
    });
  }, [productId, getProducts]);

  const [selectedVariant, setSelectedVariant] = useState({});

  const variants = product?.v_productVariants;
  const images = product?.images.map(
    (img) => `${process.env.PUBLIC_URL}/storage/${img}`
  );

  useEffect(() => {
    if (product?.productType === "virtual" && variants.length === 1) {
      setSelectedVariant(variants[0]);
    }
  }, [variants, product]);

  const handleAddToCart = () => {
    props.addToCart(
      {
        userId: props.user.detail.id,
        productVariantId: selectedVariant.id,
        qty: 1,
        // tokenId: productToken?.id,
      },
      false
    );
  };

  const handleSelectVariant = (variant) => {
    setSelectedVariant(variant);
  };

  const [confirmOpen, setConfirmOpen] = useState(false);
  const [formIntakeData, setFormIntake] = useState({});

  useEffect(() => {
    if (product?.intakeForm) {
      setFormIntake(
        product.intakeForm.reduce((acc, val) => {
          return {
            [val.name]: null,
          };
        }, {})
      );
    }
  }, [product]);

  const handleFormIntakeChange = (e, data) => {
    setFormIntake((d) => {
      return {
        ...d,
        [data.name]: data.value,
      };
    });
  };

  const handleCreateVirtualOrder = async () => {
    await submitVirtualOrder({
      productVariantId: selectedVariant.id,
      intakeForm: formIntakeData,
    });
    history.push("/view-orders");
  };

  // const [productToken, setProductToken] = useState();
  // const [tokenInput, setTokenInput] = useState();
  // const handleGetToken = async () => {
  //   const data = await props.getProductToken({
  //     token: tokenInput,
  //     productId: product.id,
  //   });

  //   setProductToken(data);
  // };
  // const handleChangeToken = (e, data) => {
  //   setTokenInput(data.value);
  //   setProductToken("");
  // };

  const contentPanes = useMemo(() => {
    if (!product) return;
    const content = product.content;
    if (!content) return;
    return Object.entries(content).reduce((acc, val) => {
      const [name, data] = val;
      acc.push({
        menuItem: startCase(name),
        render: () => (
          <Tab.Pane>
            <div dangerouslySetInnerHTML={{ __html: data }} />
          </Tab.Pane>
        ),
      });
      return acc;
    }, []);
  }, [product]);

  const hasProductContent = Object.keys(contentPanes || {}).length > 0;

  if (!product) {
    return null;
  }

  const isCorporateUser = user.detail.role === "corporate-user";
  const hasCredits = isCorporateUser ? user.detail.wallet > 0 : true;
  // const hasValidToken = isCorporateUser ? productToken : true;
  const canAddToCart = selectedVariant.id; //&& hasValidToken;
  const isFormComplete = Object.values(formIntakeData).every((s) => {
    return Boolean(s);
  });

  return (
    <Container
      fluid
      style={{
        background: "white",
        paddingTop: 75,
        marginTop: -45,
        paddingBottom: 75,
      }}
    >
      <Container>
        <Grid columns="equal">
          <Grid.Row>
            <Grid.Column computer="8" mobile="16">
              <Header as="h1" size="huge">
                <Header.Subheader>{product.category.name}</Header.Subheader>
                {product.name}
              </Header>
              <p>{product.description}</p>
              <Divider />
              <Responsive maxWidth={Responsive.onlyTablet.maxWidth}>
                <div className="ProductPage-imageCarousel">
                  <ProductImageSlider images={images} />
                </div>
              </Responsive>

              {product.productType === "virtual" && (
                <>
                  <div className="ProductPage-option">
                    <Header size="huge">
                      <Header.Subheader>Intake Form</Header.Subheader>
                    </Header>
                    <Form>
                      {product.intakeForm.map((f) => {
                        if (f.type === "select" && f.options) {
                          return (
                            <Form.Select
                              key={f.name}
                              name={f.name}
                              label={f.name}
                              placeholder={`Select ${f.name}`}
                              onChange={handleFormIntakeChange}
                              value={formIntakeData[f.name]}
                              options={f.options}
                              required
                            />
                          );
                        }

                        return (
                          <Form.Input
                            key={f.name}
                            name={f.name}
                            label={f.name}
                            placeholder={`Enter ${f.name}`}
                            onChange={handleFormIntakeChange}
                            value={formIntakeData[f.name]}
                            required
                          />
                        );
                      })}

                      <Button
                        size="massive"
                        primary
                        onClick={() => setConfirmOpen(true)}
                        content={"Submit Order"}
                        disabled={!isFormComplete || !hasCredits}
                        circular={THEME === "shaw"}
                      />
                      {isCorporateUser && !hasCredits && (
                        <div>
                          You do not have enough credits to order this product.
                        </div>
                      )}
                      <Confirm
                        open={confirmOpen}
                        content={
                          <Modal.Content>
                            <p>
                              Please confirm your order. <br />
                              <br />
                              {isCorporateUser && (
                                <>
                                  <b>Note:</b> This product will deduct a credit
                                  from your current balance of{" "}
                                  <b>{user.detail.wallet}</b> credits.
                                </>
                              )}
                            </p>
                          </Modal.Content>
                        }
                        cancelButton="Cancel"
                        confirmButton="Confirm"
                        onCancel={() => setConfirmOpen(false)}
                        onConfirm={handleCreateVirtualOrder}
                      />
                    </Form>
                  </div>
                </>
              )}

              {product.productType === "physical" && (
                <>
                  <ProductOptions
                    variants={variants}
                    onSelect={handleSelectVariant}
                    selectedVariant={selectedVariant}
                  />
                  <Divider />

                  <div className="ProductPage-option">
                    {/* {isCorporateUser && (
                <div style={{ marginBottom: 15 }}>
                  <Form>
                    <Form.Group style={{ marginBottom: 0 }}>
                      <Form.Input
                        label="Product Token"
                        placeholder="Input Product Token"
                        onChange={handleChangeToken}
                        value={tokenInput}
                        error={productToken === null}
                      />

                      <Form.Button
                        label="&nbsp;"
                        content="Validate"
                        onClick={handleGetToken}
                        disabled={!tokenInput}
                      />
                    </Form.Group>
                    {productToken === null && (
                      <small style={{ color: "red" }}>Invalid Token</small>
                    )}
                    {productToken && (
                      <small style={{ color: "green" }}>Valid Token</small>
                    )}
                  </Form>
                </div>
              )} */}
                    <Button
                      size="massive"
                      primary
                      onClick={handleAddToCart}
                      content={
                        canAddToCart ? "Add to Cart" : "Select A Variant"
                      }
                      disabled={!canAddToCart}
                      circular={THEME === "shaw"}
                    />
                  </div>
                </>
              )}

              <Divider />
            </Grid.Column>
            <Grid.Column computer="8" only="computer">
              <div className="ProductPage-imageCarousel">
                <ProductImageSlider images={images} />
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <div className="ProductPage-option">
                <Header size="huge">
                  <Header.Subheader>About</Header.Subheader>
                </Header>
                <div className="ProductPage-about">
                  {hasProductContent ? (
                    <Tab
                      menu={{ secondary: true, pointing: true }}
                      panes={contentPanes}
                    />
                  ) : (
                    "No Product Info"
                  )}
                  {/* <Table basic="very" compact="very" style={{ fontSize: "11px" }}>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Size</Table.HeaderCell>
                      <Table.HeaderCell>Chest (cm)</Table.HeaderCell>
                      <Table.HeaderCell>Arm (cm)</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    {sizeGuide.length > 0
                      ? sizeGuide.map((size) => {
                          return (
                            <Table.Row key={size.id}>
                              <Table.Cell>{size.size}</Table.Cell>
                              <Table.Cell>
                                {size.chest
                                  .map((val) => val + "cm")
                                  .join(" - ")}
                              </Table.Cell>
                              <Table.Cell>
                                {size.arm.map((val) => val + "cm").join(" - ")}
                              </Table.Cell>
                            </Table.Row>
                          );
                        })
                      : null}
                  </Table.Body>
                </Table> */}
                </div>
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </Container>
  );
};

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      getProducts,
      addToCart,
      getProductToken,
      submitVirtualOrder,
    },
    dispatch
  );
};

export const ProductPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductPageComponent);
