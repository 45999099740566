import { TYPES } from "../actions/product";
const initialState = {
  products: [],
  categories: [],
  cart: [],
  cartSum: 0,
  isCartLoading: false,
  isOrderLoading: false,
  isCatalogLoading: false,
  singleOrder: {},
  orders: [],
  approverOrders: [],
  sizeGuide: [],
  productTokens: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case `R_${TYPES.GET_PRODUCTS}`:
      return {
        ...state,
        isCatalogLoading: true,
      };
    case TYPES.GET_PRODUCTS:
      return {
        ...state,
        products: [...action.payload],
        isCatalogLoading: false,
      };
    case `R_${TYPES.GET_MORE_PRODUCTS}`:
      return {
        ...state,
        isCatalogLoading: true,
      };
    case TYPES.GET_MORE_PRODUCTS:
      return {
        ...state,
        products: [...state.products, ...action.payload],
        isCatalogLoading: false,
      };
    case `R_${TYPES.GET_CATEGORIES}`:
      return {
        ...state,
        isCatalogLoading: true,
      };
    case TYPES.GET_CATEGORIES:
      return {
        ...state,
        categories: action.payload,
        isCatalogLoading: false,
      };
    case TYPES.ADD_TO_CART:
      return {
        ...state,
        cart: [...state.cart],
      };
    case `R_${TYPES.GET_CART}`:
      return {
        ...state,
        isCartLoading: true,
      };
    case TYPES.GET_CART:
      let cart = [...action.payload];
      return {
        ...state,
        cart,
        cartSum: cart.reduce((acc, val) => acc + val.qty, 0),
        isCartLoading: false,
      };

    case `R_${TYPES.SUBMIT_ORDER}`:
      return {
        ...state,
        isOrderLoading: true,
      };
    case TYPES.SUBMIT_ORDER:
      return {
        ...state,
        cart: [],
        cartSum: 0,
        singleOrder: action.payload,
        isOrderLoading: false,
      };

    case `R_${TYPES.GET_SINGLE_ORDER}`:
      return {
        ...state,
        isOrderLoading: true,
      };
    case TYPES.GET_SINGLE_ORDER:
      return {
        ...state,
        singleOrder: action.payload[0],
        isOrderLoading: false,
      };
    case TYPES.CLEAR_SINGLE_ORDER:
      return {
        ...state,
        singleOrder: {},
      };

    case `R_${TYPES.GET_ORDERS}`:
      return {
        ...state,
        isOrderLoading: true,
      };
    case TYPES.GET_ORDERS:
      return {
        ...state,
        orders: action.payload,
        isOrderLoading: false,
      };

    case `R_${TYPES.GET_APPROVER_ORDERS}`:
      return {
        ...state,
        isOrderLoading: true,
      };
    case TYPES.GET_APPROVER_ORDERS:
      return {
        ...state,
        approverOrders: action.payload,
        isOrderLoading: false,
      };
    case TYPES.GET_SIZE_GUIDE:
      return {
        ...state,
        sizeGuide: action.payload,
      };

    case TYPES.GET_TOKEN:
      return {
        ...state,
        productTokens: action.payload,
      };
    default:
      return state;
  }
};
