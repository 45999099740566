import React, { useEffect, useState, useCallback } from "react";
import { Table, Button, Form, ButtonGroup } from "semantic-ui-react";
import DatePicker from "react-datepicker";
import moment from "moment";

const NEXT = 1;
const PREV = -1;

const DEFAULT_PAGINATION = {
  offset: 0,
  limit: 50,
};

export const ManageProductTokenTable = (props) => {
  const {
    adminGetProductTokens,
    adminGetProductOptions,
    adminDeleteProductToken,
  } = props;

  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);

  const [filterDate, setFilterDate] = useState({
    startDate: moment().startOf("month"),
    endDate: moment().endOf("month"),
  });

  const handleDateChange = (date, name) => {
    setFilterDate((state) => ({ ...state, [name]: date }));
  };

  const [currentTokens, setCurrentTokens] = useState([]);

  const handleGetTokens = async ({
    offset,
    limit,
    productId,
    startDate,
    endDate,
  }) => {
    const data = await adminGetProductTokens({
      offset,
      limit,
      productId,
      startDate,
      endDate,
    });
    setCurrentTokens(data);
  };

  const [productOptions, setProductOptions] = useState([]);

  const handleGetProductOptions = useCallback(async () => {
    const productOpts = await adminGetProductOptions();
    const productOptions = productOpts.map((p) => ({
      key: p.id,
      text: p.name,
      value: { name: p.name, id: p.id },
    }));

    setProductOptions(productOptions);
  }, [adminGetProductOptions]);

  const [selectedProduct, setSelectedProduct] = useState({
    id: "all",
    name: "All Products",
  });

  const productId =
    selectedProduct.id === "all" ? undefined : selectedProduct.id;

  const handleOnSearch = (resetPagination = false) => {
    let nextPagination = { ...pagination };
    if (resetPagination) {
      nextPagination = DEFAULT_PAGINATION;
      setPagination(DEFAULT_PAGINATION);
    }

    handleGetTokens({ ...nextPagination, ...filterDate, productId });
  };

  const handleOnDelete = (token) => {
    adminDeleteProductToken(token);
    handleOnSearch();
  };

  const handlePagination = (direction) => {
    const nextOffset = pagination.offset + pagination.limit * direction;
    setPagination({ ...pagination, offset: nextOffset });
    return handleOnSearch();
  };

  const handleOnExport = async () => {
    const data = await adminGetProductTokens({
      offset: 0,
      limit: 5000,
      productId,
      ...filterDate,
    });

    const csv = [
      ["id", "productId", "productName", "token", "createdAt"]
        .map((v) => `"${v}"`)
        .join(","),
    ];

    for (const record of data) {
      csv.push(
        [
          record.id,
          record.productId,
          record.product.name,
          record.token,
          record.createdAt,
        ]
          .map((v) => `"${v}"`)
          .join(",")
      );
    }

    const csvFile = new Blob([csv.join("\n")], { type: "text/csv" });
    const downloadLink = document.createElement("a");
    downloadLink.download = "product-token-export.csv";
    downloadLink.href = window.URL.createObjectURL(csvFile);
    downloadLink.style.display = "none";
    document.body.appendChild(downloadLink);
    downloadLink.click();
  };

  useEffect(() => {
    handleGetProductOptions();
  }, [handleGetProductOptions]);

  return (
    <div>
      <Form>
        <Form.Group>
          <Form.Select
            label="Filter By Product"
            name="product"
            options={[
              {
                key: "all",
                text: "All Products",
                value: { id: "all", name: "All Products" },
              },
              ...productOptions,
            ]}
            value={selectedProduct}
            onChange={(e, data) => setSelectedProduct(data.value)}
          />

          <Form.Field>
            <label>Start Date</label>
            <DatePicker
              name="startDate"
              dateFormat="YYYY-MM-DD"
              selected={filterDate.startDate}
              startDate={filterDate.startDate}
              endDate={filterDate.endDate}
              onChange={(date) => handleDateChange(date, "startDate")}
              showMonthDropdown
              showYearDropdown
              monthsShown={2}
              className="ui input"
            />
          </Form.Field>
          <Form.Field>
            <label>End Date</label>
            <DatePicker
              dateFormat="YYYY-MM-DD"
              selected={filterDate.endDate}
              startDate={filterDate.startDate}
              endDate={filterDate.endDate}
              onChange={(date) => handleDateChange(date, "endDate")}
              showMonthDropdown
              showYearDropdown
              monthsShown={2}
              className="ui input"
            />
          </Form.Field>

          <Form.Button
            label="Search"
            content="Search"
            primary
            onClick={() => handleOnSearch(true)}
          />
        </Form.Group>
        <Form.Group>
          <Form.Button
            label="Export"
            content="Export"
            icon="save"
            onClick={handleOnExport}
          />
        </Form.Group>
      </Form>
      <ButtonGroup floated="right">
        <Button
          icon="left arrow"
          basic
          disabled={pagination.offset === 0}
          onClick={() => handlePagination(PREV)}
        />
        <Button
          icon="right arrow"
          basic
          disabled={currentTokens.length !== pagination.limit}
          onClick={() => handlePagination(NEXT)}
        />
      </ButtonGroup>
      <Table compact="very">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Product Name</Table.HeaderCell>
            <Table.HeaderCell>Token</Table.HeaderCell>
            <Table.HeaderCell>Quantity</Table.HeaderCell>
            <Table.HeaderCell>Created At</Table.HeaderCell>
            <Table.HeaderCell>Remove</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {currentTokens.map((t) => {
            return (
              <Table.Row>
                <Table.Cell>{t.product.name}</Table.Cell>
                <Table.Cell>{t.token}</Table.Cell>
                <Table.Cell>{t.qty}</Table.Cell>
                <Table.Cell>
                  {new Date(t.createdAt).toLocaleString()}
                </Table.Cell>
                <Table.Cell>
                  <Button
                    icon="trash"
                    basic
                    onClick={() => handleOnDelete(t)}
                  />
                </Table.Cell>
              </Table.Row>
            );
          })}
          {!currentTokens.length && (
            <Table.Row>
              <Table.Cell>No data</Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </div>
  );
};
