import React, { useEffect, useState } from "react";
import {
  Grid,
  Container,
  Header,
  Divider,
  Dimmer,
  Loader,
  Table,
  Button,
  Icon,
  Confirm,
  Modal,
  Form,
} from "semantic-ui-react";
import { api } from "../api/axios";

const EditCredits = (props) => {
  const {
    open,
    onClose,
    // creditLimits,
    // hourlyType,
    credits,
    title,
    onSubmit,
  } = props;

  const nextCredits = credits + 2;
  return (
    <Modal open={open} onClose={onClose} size="mini">
      <Modal.Header>{title}</Modal.Header>
      <Modal.Content>
        <Form
          onSubmit={(e, data) => {
            onSubmit(nextCredits);
          }}
        >
          <p>
            Increase credit limit by <strong>2</strong>. New balance will be{" "}
            <strong>{nextCredits}</strong>.
          </p>
          <p>
            <small>Note: Overrides allowed once per year.</small>
          </p>
          {/* <Form.Input
            label="Credits"
            placeholder="Credits"
            type="number"
            min={0}
            max={parseInt(limit?.value, 10) + 2}
            defaultValue={credits}
            name="credits"
            onChange={(e, data) => setVal(data.value)}
          /> */}
          <Form.Group>
            <Form.Button type="button" content="Cancel" onClick={onClose} />
            <Form.Button content="Confirm" color="green" />
          </Form.Group>
        </Form>
      </Modal.Content>
    </Modal>
  );
};

export const CorporateManger = () => {
  const [employeeData, setEmployeeData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [isEditingCredits, setIsEditingCredits] = useState(null);
  const [hourlyTypeCreditOptions, setHourlyTypeCreditOptions] = useState([]);

  const handleVerify = async (verificationToken) => {
    await api({
      method: "post",
      url: "/api/auth/verify",
      data: {
        verificationToken,
      },
    });
    handleGetEmployees();
  };

  const handleDelete = async (userId) => {
    await api({
      method: "delete",
      url: "/api/user/employees/" + userId,
    });
    setConfirmDelete(false);
    handleGetEmployees();
  };

  const handleGetEmployees = async () => {
    setIsLoading(true);
    const { data } = await api({
      method: "GET",
      url: "/api/user/employees",
    });

    setEmployeeData(data);
    setIsLoading(false);
  };

  const handleEditTokens = async (userId, credits) => {
    await api({
      method: "post",
      url: "/api/user/edit-credits",
      data: {
        userId,
        credits,
      },
    });
    handleGetEmployees();
    setIsEditingCredits(null);
  };

  const handleGetHourlyTypeCreditOptions = async () => {
    const { data } = await api({
      method: "GET",
      url: "/api/user/hourly-type-credits",
    });
    setHourlyTypeCreditOptions(data);
  };
  useEffect(() => {
    handleGetEmployees();
    handleGetHourlyTypeCreditOptions();
  }, []);

  return (
    <>
      <Container>
        <Dimmer active={isLoading} inverted>
          <Loader inverted content="Loading" />
        </Dimmer>
        <Header as="h1">Manager</Header>
        <Divider />
        <Grid>
          <Grid.Column>
            {!isLoading && employeeData.length && (
              <Table>
                <Table.Header>
                  <Table.HeaderCell>Username</Table.HeaderCell>
                  <Table.HeaderCell>Firstname</Table.HeaderCell>
                  <Table.HeaderCell>Lastname</Table.HeaderCell>
                  <Table.HeaderCell>Type</Table.HeaderCell>
                  <Table.HeaderCell>Location</Table.HeaderCell>
                  <Table.HeaderCell>Verification</Table.HeaderCell>
                  <Table.HeaderCell>Credits</Table.HeaderCell>
                  <Table.HeaderCell>Created At</Table.HeaderCell>
                </Table.Header>
                <Table.Body>
                  {employeeData.map((u) => {
                    return (
                      <Table.Row key={u.userId}>
                        <Table.Cell>{u.username}</Table.Cell>
                        <Table.Cell>{u.firstname}</Table.Cell>
                        <Table.Cell>{u.lastname}</Table.Cell>
                        <Table.Cell>{u.hourlyType}</Table.Cell>
                        <Table.Cell>{u.storeName}</Table.Cell>
                        <Table.Cell>
                          {u.verificationToken ? (
                            <>
                              <Button
                                onClick={() =>
                                  handleVerify(u.verificationToken)
                                }
                                content="Verify"
                                basic
                                size="mini"
                                icon="check"
                                color="green"
                              />
                              <Button
                                onClick={() => setConfirmDelete(true)}
                                content="Reject"
                                basic
                                size="mini"
                                icon="close"
                                color="red"
                              />
                              <Confirm
                                open={confirmDelete}
                                onCancel={() => setConfirmDelete(false)}
                                onConfirm={() => handleDelete(u.userId)}
                                confirmButton="Yes"
                                size="mini"
                              />
                            </>
                          ) : (
                            <small>
                              <Icon name="check" /> Verified
                            </small>
                          )}
                        </Table.Cell>
                        <Table.Cell>
                          <span style={{ marginRight: 5 }}>{u.wallet}</span>
                          {u.walletOverride ? (
                            <small>(Overidden)</small>
                          ) : (
                            <>
                              <Button
                                icon="plus"
                                size="mini"
                                basic
                                onClick={() => setIsEditingCredits(u.userId)}
                              />
                              <EditCredits
                                title={`Editing Credits for ${u.username}`}
                                open={isEditingCredits === u.userId}
                                onClose={() => setIsEditingCredits(null)}
                                creditLimits={hourlyTypeCreditOptions}
                                hourlyType={u.hourlyType}
                                credits={u.wallet}
                                onSubmit={(nextValue) =>
                                  handleEditTokens(u.userId, nextValue)
                                }
                              />
                            </>
                          )}
                        </Table.Cell>
                        <Table.Cell>
                          {new Date(u.createdAt).toLocaleDateString()}
                        </Table.Cell>
                      </Table.Row>
                    );
                  })}
                </Table.Body>
              </Table>
            )}
            {!isLoading && !employeeData.length && <p>No employees!</p>}
          </Grid.Column>
        </Grid>
      </Container>
    </>
  );
};
