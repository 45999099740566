import React, { Component } from "react";
import { Menu } from "semantic-ui-react";
import { NavLink } from "react-router-dom";

export default class AdminMenu extends Component {
  render() {
    return (
      <Menu vertical fluid>
        <Menu.Item>
          <Menu.Header>General</Menu.Header>
          <Menu.Menu>
            <Menu.Item
              as={NavLink}
              to="/admin/dashboard"
              activeClassName="active"
            >
              Dashboard
            </Menu.Item>
          </Menu.Menu>
        </Menu.Item>
        <Menu.Item>
          <Menu.Header>Users</Menu.Header>
          <Menu.Menu>
            <Menu.Item
              as={NavLink}
              to="/admin/create-user"
              activeClassName="active"
            >
              Create User
            </Menu.Item>
            <Menu.Item
              as={NavLink}
              to="/admin/manage-users"
              activeClassName="active"
            >
              Manage Users
            </Menu.Item>
            <Menu.Item
              as={NavLink}
              to="/admin/manage-ship-addresses"
              activeClassName="active"
            >
              Manage Corporate Addresses
            </Menu.Item>
          </Menu.Menu>
        </Menu.Item>
        <Menu.Item>
          <Menu.Header>Orders</Menu.Header>
          <Menu.Menu>
            <Menu.Item
              as={NavLink}
              to="/admin/manage-orders"
              activeClassName="active"
            >
              Manage Physical Orders
            </Menu.Item>
            <Menu.Item
              as={NavLink}
              to="/admin/manage-virtual-orders"
              activeClassName="active"
            >
              Manage Virtual Orders
            </Menu.Item>
            <Menu.Item
              as={NavLink}
              to="/admin/order-detail-report"
              activeClassName="active"
            >
              Order Detail Report
            </Menu.Item>
          </Menu.Menu>
        </Menu.Item>
        <Menu.Item>
          <Menu.Header>Products</Menu.Header>
          <Menu.Menu>
            <Menu.Item
              as={NavLink}
              to="/admin/create-product"
              activeClassName="active"
            >
              Create Product
            </Menu.Item>
            <Menu.Item
              as={NavLink}
              to="/admin/manage-products"
              activeClassName="active"
            >
              Manage Products
            </Menu.Item>
            {/* <Menu.Item
              as={NavLink}
              to="/admin/manage-product-tokens"
              activeClassName="active"
            >
              Manage Product Tokens
            </Menu.Item> */}
            <Menu.Item
              as={NavLink}
              to="/admin/manage-categories"
              activeClassName="active"
            >
              Manage Categories
            </Menu.Item>
            {/* <Menu.Item
              as={NavLink}
              to="/admin/manage-size-guide"
              activeClassName="active"
            >
              Manage Size Guide
            </Menu.Item> */}
          </Menu.Menu>
        </Menu.Item>

        {/* <Menu.Item>
          <Menu.Header>Nametags</Menu.Header>
          <Menu.Menu>
            <Menu.Item
              as={NavLink}
              to="/admin/export-nametags"
              activeClassName="active"
            >
              Manage Nametags
            </Menu.Item>
          </Menu.Menu>
        </Menu.Item> */}
      </Menu>
    );
  }
}
