const initialState = {
  isLoading: false,
  toggleCart: false,
  error: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "REQUEST_SENT":
      return { ...state, isLoading: true };
    case "REQUEST_SUCCESS":
      return { ...state, isLoading: false };
    case "REQUEST_ERROR":
      return { ...state, error: action.payload };
    case "ADD_TO_CART":
    case "TOGGLE_CART":
      return { ...state, toggleCart: !state.toggleCart };
    case "DISMISS_ERROR":
      return { ...state, error: false };
    case "USER_LOGIN":
      return { ...state, error: false };
    default:
      return state;
  }
};
