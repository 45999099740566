import React, { Component } from "react";

import { Button, Item, Input, Form, Label } from "semantic-ui-react";

class CartItem extends Component {
  state = {
    toggleEdit: false,
    qty: this.props.product.qty,
  };
  handleChange(e, data) {
    let val = data.value;
    if (val > data.max) {
      val = data.max;
    }
    this.setState({
      qty: val,
    });
  }
  toggleEdit() {
    this.setState({
      toggleEdit: !this.state.toggleEdit,
      qty: this.props.product.qty,
    });
  }
  onSubmit(e) {
    e.preventDefault();
    this.props.addToCart(this.props.product.productVariantId, this.state.qty);
    this.setState({ toggleEdit: !this.state.toggleEdit });
  }

  render() {
    const {
      product,
      removeFromCart,
      itemNeedsAttention,
      isCorporateUser,
    } = this.props;

    const variant = product.v_productVariant;
    const { product: baseProduct } = variant;
    return (
      <Item>
        <Item.Image
          src={`${process.env.PUBLIC_URL}/storage/${baseProduct.images[0]}`}
          size="tiny"
        />

        <Item.Content>
          <Item.Header as="a">{baseProduct.name}</Item.Header>

          <Item.Meta>
            Size: {variant.size} <br />
            Qty: {product.qty}
            <br />
            {!isCorporateUser && `Limit: ${variant.virtuallimit}`}
          </Item.Meta>
          <Item.Description />
          <Item.Extra>
            <Form onSubmit={this.onSubmit.bind(this)}>
              {!itemNeedsAttention ? (
                this.state.toggleEdit ? (
                  <Input
                    type="number"
                    max={variant.virtuallimit}
                    min={0}
                    placeholder="Qty"
                    value={this.state.qty}
                    action
                    size="small"
                    style={{ maxWidth: 70 }}
                    onChange={this.handleChange.bind(this)}
                  >
                    <input />
                    <Button
                      type="submit"
                      onClick={this.onSubmit.bind(this)}
                      icon="check"
                    />
                  </Input>
                ) : (
                  !isCorporateUser && (
                    // eslint-disable-next-line
                    <a onClick={this.toggleEdit.bind(this)}>Edit Qty</a>
                  )
                )
              ) : (
                <Label size="small" basic color="orange">
                  Item No Longer In Stock
                </Label>
              )}
              <Button
                basic
                color="grey"
                size="tiny"
                floated="right"
                icon={"trash"}
                onClick={(e) => {
                  e.preventDefault();
                  removeFromCart(product.id);
                }}
              />
            </Form>
          </Item.Extra>
        </Item.Content>
      </Item>
    );
  }
}

export default CartItem;
