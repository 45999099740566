import { combineReducers } from 'redux'
import userReducer from './user'
import uiReducer from './ui'
import productReducer from './product'
import adminReducer from './admin'

const rootReducer = combineReducers({
  user: userReducer,
  ui: uiReducer,
  product: productReducer,
  admin: adminReducer
})

export default rootReducer
