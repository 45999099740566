import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  Segment,
  Form,
  Header,
  Icon,
  Divider,
} from "semantic-ui-react";
import wings from "../logo-wings.svg";
import shawLogo from "../shaw-logo.svg";
import { THEME } from "../env";

import { Link } from "react-router-dom";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { registerUser } from "../actions/user";
import { api } from "../api/axios";

const RegisterPageView = (props) => {
  const { registerUser } = props;

  const [formData, setFormData] = useState({
    username: "",
    password: "",
    confirm: "",
  });

  const [errMessage, setErrMessage] = useState("");
  const [isPending, setIsPending] = useState(false);
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    (async () => {
      const { data: locations } = await api({ url: "/api/user/locations" });

      setLocations(locations);
    })();
  }, []);

  const handleChange = (e, data) => {
    setFormData((state) => {
      const nextState = {
        ...state,
        [data.name]: data.value,
      };
      if (data.name === "empType" && data.value.toLowerCase() === "ev") {
        const eventLocation = locations.find(
          (l) => l.name.toLowerCase().indexOf("event") > -1
        );

        if (eventLocation) {
          Object.assign(nextState, {
            location: eventLocation.id,
          });
        }
      }

      return nextState;
    });
  };

  const handleSubmit = async () => {
    if (formData.password !== formData.confirm) {
      return setErrMessage("Passwords do not match");
    }
    const data = await registerUser(formData);
    if (!data.success) {
      return setErrMessage(data.message);
    }
    setIsPending(true);
  };

  return (
    <Container className="login-page">
      <Grid centered>
        <Grid.Column computer={6} mobile={16}>
          <div className="login-box">
            <Segment>
              <Header textAlign="center" icon as="h1">
                <Icon>
                  <img
                    src={THEME === "freedom" ? wings : shawLogo}
                    width={THEME === "freedom" ? 35 : 100}
                    style={{ display: "inline-block" }}
                    alt="wings"
                  />
                </Icon>
                Register
                <Header.Subheader>
                  Register with your Corporate Email
                </Header.Subheader>
              </Header>
              {isPending ? (
                <p>Your account is pending verification.</p>
              ) : (
                <Form>
                  <Form.Input
                    name="username"
                    label="Email"
                    placeholder="email@example.com"
                    onChange={handleChange}
                    type="email"
                    value={formData.username}
                  />
                  <Form.Input
                    name="password"
                    label="Password"
                    placeholder="Password"
                    type="password"
                    onChange={handleChange}
                    value={formData.password}
                  />
                  <Form.Input
                    name="confirm"
                    label="Confirm Password"
                    placeholder="Confirm Password"
                    type="password"
                    onChange={handleChange}
                    value={formData.confirm}
                  />
                  <Divider />

                  <Form.Select
                    name="empType"
                    label="Role"
                    placeholder="Role"
                    options={[
                      { text: "Full Time", value: "FT" },
                      { text: "Part Time", value: "PT" },
                      { text: "In Home / Events", value: "EV" },
                    ]}
                    onChange={handleChange}
                    value={formData.empType}
                  />

                  <Form.Select
                    name="location"
                    label="Location"
                    placeholder="Location"
                    search
                    selection
                    options={locations.map((l) => {
                      return {
                        text: `${l.name} - ${l.streetAddress}`,
                        value: l.id,
                      };
                    })}
                    onChange={handleChange}
                    value={formData.location}
                  />
                  {errMessage && (
                    <p style={{ color: "red" }}>
                      <small>{errMessage}</small>
                    </p>
                  )}

                  <Form.Button
                    fluid
                    onClick={handleSubmit}
                    disabled={
                      !formData.username.includes("@") ||
                      formData.password.length < 5 ||
                      formData.password !== formData.confirm ||
                      !formData.empType ||
                      !formData.location
                    }
                    primary
                  >
                    Register
                  </Form.Button>
                  <p>
                    <Link to="/Login">Go to Login</Link>
                  </p>
                </Form>
              )}
            </Segment>
          </div>
        </Grid.Column>
      </Grid>
    </Container>
  );
};

const mapStateToProps = (state) => state;
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ registerUser }, dispatch);
};
export const RegisterPage = connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterPageView);
