import React, { Component } from "react";

import { Header, Divider } from "semantic-ui-react";
import AdminLayout from "../components/AdminLayout";

import EditBaseProductForm from "../components/EditBaseProductForm";

// redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  adminCreateProduct,
  adminGetCategories,
  adminEditProductProp,
  adminClearProduct,
} from "../actions/admin";

class CreateProductPage extends Component {
  state = {};

  componentWillMount() {
    this.props.adminClearProduct();
    this.props.adminGetCategories();
  }

  componentDidUpdate() {
    const id = this.props.admin.singleProduct.id;
    if (id) {
      this.props.history.push(`/admin/manage-products/edit/${id}`);
    }
  }

  handleChange(e, data) {
    if (data.name === "enabled") {
      data.value = data.checked;
    }
    this.props.adminEditProductProp(data.name, data.value);
  }

  saveBaseProduct(e) {
    const { name, description, categoryId, enabled, productType, roles } =
      this.props.admin.singleProduct;

    this.props.adminCreateProduct({
      name,
      description,
      categoryId,
      enabled,
      productType,
      roles,
    });
    e.preventDefault();
  }

  render() {
    const { isProductsLoading, singleProduct, categories } = this.props.admin;
    const { name, description, enabled, categoryId, productType, roles } =
      singleProduct;
    const isEdited = name;
    return (
      <AdminLayout isLoading={isProductsLoading}>
        <Header as="h3">Create Base Product</Header>
        <Divider />
        <EditBaseProductForm
          isEdited={isEdited}
          handleChange={this.handleChange.bind(this)}
          saveBaseProduct={this.saveBaseProduct.bind(this)}
          name={name}
          categoryId={categoryId}
          description={description}
          categories={categories}
          enabled={enabled}
          productType={productType}
          roles={roles}
        />
      </AdminLayout>
    );
  }
}

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      adminGetCategories,
      adminCreateProduct,
      adminEditProductProp,
      adminClearProduct,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateProductPage);
