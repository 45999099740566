import React, { Component } from 'react'
import {
  Container,
  Grid,
  Step,
  Segment,
  List,
  Dimmer,
  Loader
} from 'semantic-ui-react'
import ViewOrderProductsTable from '../components/ViewOrderProductsTable'
// redux
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { getSingleOrder, clearSingleOrder } from '../actions/product'

class ViewOrderPage extends Component {
  componentWillMount() {
    const { orderNum } = this.props.match.params
    if (orderNum !== 'submission') {
      this.props.getSingleOrder(orderNum)
    }
  }

  componentWillUnmount() {
    this.props.clearSingleOrder()
  }
  render() {
    const { singleOrder, isOrderLoading } = this.props.product

    const {
      status,
      orderNum,
      name,
      streetAddress,
      unit,
      city,
      province,
      country,
      postalCode,
      phone,
      email,
      comments,
      orderProducts
    } = singleOrder

    const steps = [
      {
        active: status === 'pending',
        icon: 'clock',
        title: 'Pending',
        description: 'Your order is pending approval.'
      },
      {
        active: status === 'approved',
        icon: 'check',
        title: 'Approved',
        description: 'Your order has been approved.'
      },
      {
        active: status === 'shipped',
        icon: 'truck',
        title: 'Shipped',
        description: 'Your order has been shipped.'
      }
    ]
    return (
      <Container>
        <Dimmer active={isOrderLoading} inverted page blurring>
          <Loader active={isOrderLoading}>Loading</Loader>
        </Dimmer>
        <Grid>
          <Grid.Row>
            <Step.Group items={steps} fluid />
          </Grid.Row>
          <Grid.Row columns="equal">
            <Grid.Column as={Segment} padded style={{ margin: 10 }}>
              <List relaxed>
                <List.Item>
                  <List.Header>Order Number</List.Header>
                  {orderNum}
                </List.Item>
                <List.Item>
                  <List.Header>Ship To Address</List.Header>
                  {name} <br />
                  {streetAddress} {unit ? `Unit ${unit}` : null} <br />
                  {city}, {province} {postalCode} <br />
                  {country}
                </List.Item>
              </List>
            </Grid.Column>
            <Grid.Column as={Segment} padded style={{ margin: 10 }}>
              <List relaxed>
                <List.Item>
                  <List.Header>Contact</List.Header>
                  {phone} <br />
                  {email}
                </List.Item>
                <List.Item>
                  <List.Header>Comments</List.Header>
                  {comments}
                </List.Item>
              </List>
            </Grid.Column>
          </Grid.Row>

          <Grid.Row>
            <Grid.Column>
              {orderProducts
                ? <ViewOrderProductsTable orderProducts={orderProducts} />
                : null}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    )
  }
}

const mapStateToProps = state => {
  return state
}

const mapDispatchToProps = dispatch => {
  return bindActionCreators({ getSingleOrder, clearSingleOrder }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewOrderPage)
