import React, { Component } from "react";
import {
  Container,
  Grid,
  Segment,
  Form,
  Message,
  Header,
  Icon,
} from "semantic-ui-react";

// redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { loginUser, delLoginMessage } from "../actions/user";
import { Link } from "react-router-dom";

import wings from "../logo-wings.svg";
import shawLogo from "../shaw-logo.svg";
import { THEME } from "../env";

class LoginPage extends Component {
  state = {
    username: "",
    password: "",
  };

  handleChange(e) {
    this.props.delLoginMessage();
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  handleSubmit(e) {
    if (!(this.state.username.length < 4 || this.state.password.length < 5)) {
      const username = this.state.username.toLowerCase();
      this.setState(
        {
          username,
        },
        () => this.props.loginUser(this.state)
      );
      e.preventDefault();
    }
  }

  render() {
    const { loginMessage } = this.props.user;
    return (
      <Container className="login-page">
        <Grid centered>
          <Grid.Column computer={6} tablet={6} mobile={16}>
            <div className="login-box">
              <Segment>
                <Header textAlign="center" icon as="h1">
                  <Icon>
                    <img
                      src={THEME === "freedom" ? wings : shawLogo}
                      width={THEME === "freedom" ? 35 : 100}
                      style={{ display: "inline-block" }}
                      alt="wings"
                    />
                  </Icon>
                  Login
                  <Header.Subheader>
                    Login with your corporate login, or credentials provided by
                    your administrator
                  </Header.Subheader>
                </Header>
                <Form>
                  <Form.Input
                    name="username"
                    label="Username"
                    placeholder="Username"
                    onChange={this.handleChange.bind(this)}
                  />
                  <Form.Input
                    name="password"
                    label="Password"
                    placeholder="Password"
                    type="password"
                    onChange={this.handleChange.bind(this)}
                  />
                  <p>
                    <Link to="/register">Register Corporate User</Link>
                  </p>
                  <Form.Button
                    fluid
                    onClick={this.handleSubmit.bind(this)}
                    disabled={
                      this.state.username.length < 4 ||
                      this.state.password.length < 5
                    }
                  >
                    Login
                  </Form.Button>
                  {loginMessage && <Message negative>{loginMessage}</Message>}
                </Form>
              </Segment>
            </div>
          </Grid.Column>
        </Grid>
      </Container>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    ui: state.ui,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      loginUser,
      delLoginMessage,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
