import React, { useState } from "react";

import {
  Form,
  Checkbox,
  Input,
  Select,
  Dropdown,
  Button,
  Divider,
} from "semantic-ui-react";

export default ({
  onSubmit,
  isEditMode,
  isEdited,
  username,
  password,
  enabled,
  newPassword,
  newPasswordStatus,
  firstname,
  lastname,
  email,
  role,
  approverId,
  approvers,
  location,
  locations,
  wallet,
  hourlyType,
  handleChange,
  handlePasswordReset,
  handleDeleteUser,
}) => {
  const approverOptions = [
    {
      id: 0,
      text: "No Approver",
      value: null,
    },
    ...approvers.map(({ username, id }) => {
      return {
        key: id,
        text: username,
        value: id,
      };
    }),
  ];

  const [confirmDelete, setConfirmDelete] = useState("");

  return (
    <>
      <Form onSubmit={onSubmit}>
        <Form.Group>
          <Form.Button
            disabled={!isEdited || newPassword}
            positive
            basic
            onClick={onSubmit}
          >
            Save User
          </Form.Button>
          <Form.Field
            control={Checkbox}
            label={enabled ? "Enabled" : "Disabled"}
            toggle
            checked={enabled}
            name="enabled"
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group>
          <Form.Field
            name="username"
            control={Input}
            label="Username"
            value={username}
            placeholder="Username"
            // disabled={isEditMode}
            onChange={handleChange}
          />

          {isEditMode ? (
            <Form.Field
              name="newPassword"
              action={
                <Button
                  onClick={() => handlePasswordReset()}
                  content={
                    newPasswordStatus ? "Successful!" : "Change Password"
                  }
                  disabled={newPasswordStatus || !newPassword}
                  type="button"
                  positive={newPasswordStatus}
                />
              }
              control={Input}
              label="New Password"
              value={newPasswordStatus ? "Successful!" : newPassword}
              placeholder="New Password"
              type="text"
              onChange={handleChange}
            />
          ) : (
            <Form.Field
              name="password"
              control={Input}
              label="Password"
              value={isEditMode ? "12345" : password}
              placeholder="Password"
              disabled={isEditMode}
              type="password"
              onChange={handleChange}
            />
          )}

          <Form.Field
            name="role"
            control={Select}
            label="Role"
            value={role ? role : "user"}
            placeholder="Role"
            options={[
              { key: 0, text: "Dealer", value: "user" },
              { key: 1, text: "Dealer Approver", value: "approver" },
              { key: 2, text: "Corporate Employee", value: "corporate-user" },
              { key: 3, text: "Corporate Manager", value: "corporate-manager" },
              { key: 4, text: "Admin", value: "admin" },
            ]}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group>
          <Form.Field
            name="firstname"
            control={Input}
            label="First Name"
            value={firstname}
            placeholder="First Name"
            onChange={handleChange}
          />
          <Form.Field
            name="lastname"
            control={Input}
            label="Last Name"
            value={lastname}
            placeholder="Last Name"
            onChange={handleChange}
          />
          <Form.Field
            name="email"
            control={Input}
            label="Email"
            value={email}
            placeholder="Email"
            type="email"
            onChange={handleChange}
          />
          {role === "corporate-user" && (
            <>
              <Form.Field
                name="wallet"
                control={Input}
                label="Wallet"
                value={wallet}
                placeholder="Wallet"
                type="number"
                onChange={handleChange}
              />

              <Form.Select
                name="hourlyType"
                label="Sub-Role"
                placeholder="Sub-Role"
                options={[
                  { text: "Full Time", value: "FT" },
                  { text: "Part Time", value: "PT" },
                  { text: "Events", value: "EV" },
                ]}
                onChange={handleChange}
                value={hourlyType}
              />

              <Form.Select
                name="location"
                label="Location"
                placeholder="Location"
                search
                selection
                options={locations?.map((l) => {
                  return {
                    text: `${l.name} - ${l.streetAddress}`,
                    value: l.id,
                  };
                })}
                onChange={handleChange}
                value={location}
              />
            </>
          )}
          {role === "user" && (
            <Form.Field name="approver" control={Input} label="Approver">
              <Dropdown
                name="approverId"
                placeholder="No Approver"
                search
                selection
                autoComplete
                options={approverOptions}
                value={approverId}
                onChange={handleChange}
                disabled={role !== "user"}
              />
            </Form.Field>
          )}
        </Form.Group>
      </Form>
      {isEditMode && (
        <>
          <Divider />
          <Form>
            <Form.Group widths="16">
              <Form.Input
                placeholder="Type username to confirm"
                width="6"
                label="Delete User"
                onChange={(e, data) => setConfirmDelete(data.value)}
              />
            </Form.Group>
            <div style={{ marginBottom: 5, marginTop: -10 }}>
              <small>
                Type in <b>{username}</b> to confirm deletion
              </small>
            </div>

            <Form.Button
              color="red"
              content="Delete"
              disabled={username !== confirmDelete}
              onClick={handleDeleteUser}
            />
          </Form>
        </>
      )}
    </>
  );
};
