// @ts-check
import React, { Component } from "react";

import { Table, Button, Form, Input, Header } from "semantic-ui-react";

class EditShipAddresses extends Component {
  state = {
    addressForm: {
      userId: this.props.userId || null,
      channel: this.props.channel || null,
    },
  };

  handleChange(e, data) {
    const prevState = this.state.addressForm;
    let addressForm = { ...prevState, [data.name]: data.value };
    this.setState({
      addressForm,
    });
  }

  selectToEditAddress(address) {
    this.setState({
      addressForm: address,
    });
  }

  reset() {
    this.setState(
      {
        addressForm: {
          id: "",
          name: "",
          streetAddress: "",
          unit: "",
          city: "",
          province: "",
          country: "",
          postalCode: "",
          phone: "",
          userId: null,
          channel: this.props.channel,
        },
      },
      () => this.setState({ addressForm: { userId: this.props.userId } })
    );
  }

  createAddress() {
    this.props.onCreate(this.state.addressForm);
    this.reset();
  }

  editAddress() {
    this.props.onEdit(this.state.addressForm);
    this.reset();
  }

  render() {
    const { shipAddresses, managers } = this.props;
    const { addressForm } = this.state;
    const {
      id,
      name,
      streetAddress,
      unit,
      city,
      province,
      country,
      postalCode,
      phone,
      userId,
    } = addressForm;
    const isComplete = name && streetAddress && city && country && province;
    return (
      <div>
        <Form>
          <Header as="h4">Ship to Addresses</Header>
          <Table definition>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Action</Table.HeaderCell>
                <Table.HeaderCell>Name</Table.HeaderCell>
                {managers && <Table.HeaderCell>Manager</Table.HeaderCell>}
                <Table.HeaderCell>Address</Table.HeaderCell>
                <Table.HeaderCell>Unit</Table.HeaderCell>
                <Table.HeaderCell>City</Table.HeaderCell>
                <Table.HeaderCell>Province</Table.HeaderCell>
                <Table.HeaderCell>Country</Table.HeaderCell>
                <Table.HeaderCell>Postal Code</Table.HeaderCell>
                <Table.HeaderCell>Phone</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
              <Table.Row>
                <Table.Cell collapsing>
                  <Button
                    circular
                    icon="add"
                    color="green"
                    basic
                    fluid
                    onClick={
                      id
                        ? this.editAddress.bind(this)
                        : this.createAddress.bind(this)
                    }
                    disabled={!isComplete}
                    type="submit"
                  />
                </Table.Cell>
                <Table.Cell>
                  <Form.Field
                    control={Input}
                    placeholder="Name"
                    fluid
                    name="name"
                    value={name}
                    onChange={this.handleChange.bind(this)}
                  />
                </Table.Cell>

                {managers && (
                  <Table.Cell>
                    <Form.Select
                      name="userId"
                      placeholder="Area Manager"
                      search
                      selection
                      options={managers.map((m) => {
                        return {
                          text: `${m.firstname} ${m.lastname} - ${m.username}`,
                          value: m.id,
                        };
                      })}
                      onChange={this.handleChange.bind(this)}
                      value={userId}
                    />
                  </Table.Cell>
                )}

                <Table.Cell>
                  <Form.Field
                    control={Input}
                    placeholder="Address"
                    fluid
                    name="streetAddress"
                    value={streetAddress}
                    onChange={this.handleChange.bind(this)}
                  />
                </Table.Cell>
                <Table.Cell>
                  <Form.Field
                    control={Input}
                    placeholder="Unit"
                    fluid
                    name="unit"
                    value={unit}
                    onChange={this.handleChange.bind(this)}
                  />
                </Table.Cell>
                <Table.Cell>
                  <Form.Field
                    control={Input}
                    placeholder="City"
                    fluid
                    name="city"
                    value={city}
                    onChange={this.handleChange.bind(this)}
                  />
                </Table.Cell>
                <Table.Cell>
                  <Form.Field
                    control={Input}
                    placeholder="Province"
                    fluid
                    name="province"
                    value={province}
                    onChange={this.handleChange.bind(this)}
                  />
                </Table.Cell>
                <Table.Cell>
                  <Form.Field
                    control={Input}
                    placeholder="Country"
                    fluid
                    name="country"
                    value={country}
                    onChange={this.handleChange.bind(this)}
                  />
                </Table.Cell>
                <Table.Cell>
                  <Form.Field
                    control={Input}
                    placeholder="Postal Code"
                    fluid
                    name="postalCode"
                    value={postalCode}
                    onChange={this.handleChange.bind(this)}
                  />
                </Table.Cell>
                <Table.Cell>
                  <Form.Field
                    control={Input}
                    placeholder="Phone"
                    fluid
                    name="phone"
                    value={phone}
                    onChange={this.handleChange.bind(this)}
                  />
                </Table.Cell>
              </Table.Row>
              {shipAddresses
                .filter(({ id }) => id !== addressForm.id)
                .map((address) => (
                  <Table.Row key={address.id}>
                    <Table.Cell collapsing>
                      <Button.Group>
                        <Button
                          icon="edit"
                          basic
                          circular
                          color="grey"
                          onClick={() => this.selectToEditAddress(address)}
                        />
                        <Button
                          icon="delete"
                          basic
                          color="red"
                          circular
                          onClick={() => this.props.onDelete(address)}
                        />
                      </Button.Group>
                    </Table.Cell>
                    <Table.Cell>{address.name}</Table.Cell>
                    {managers && (
                      <Table.Cell>{address?.user?.username}</Table.Cell>
                    )}
                    <Table.Cell>{address.streetAddress}</Table.Cell>
                    <Table.Cell>{address.unit}</Table.Cell>
                    <Table.Cell>{address.city}</Table.Cell>
                    <Table.Cell>{address.province}</Table.Cell>
                    <Table.Cell>{address.country}</Table.Cell>
                    <Table.Cell>{address.postalCode}</Table.Cell>
                    <Table.Cell>{address.phone}</Table.Cell>
                  </Table.Row>
                ))}
            </Table.Body>
          </Table>
        </Form>
      </div>
    );
  }
}

export default EditShipAddresses;
