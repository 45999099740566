import { startCase } from "lodash";
export const getRoleName = (role) => {
  switch (role) {
    case "user":
      return "Dealer";
    case "approver":
      return "Dealer Approver";
    case "corporate-user":
      return "Corp Employee";
    default:
      return startCase(role);
  }
};
