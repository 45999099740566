import React, { Component } from "react";
import {
  Header,
  Divider,
  Grid,
  Checkbox,
  Table,
  Statistic,
  // Input,
  Button,
  Modal,
  Form,
  // Message,
} from "semantic-ui-react";
import AdminLayout from "../components/AdminLayout";
// redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  adminGetLowInventory,
  adminGetOrderStats,
  adminGetSettings,
  adminSetSetting,
} from "../actions/admin";

const InputEditCell = (props) => {
  const { value, name, onSubmit, display } = props;
  const [val, setVal] = React.useState(value);
  const [isOpen, setIsOpen] = React.useState(false);
  const [err, setErr] = React.useState(false);

  return (
    <div>
      <Modal open={isOpen} size="mini">
        <Modal.Content>
          <Form>
            <Form.Group>
              <Form.Input
                label={name}
                defaultValue={value}
                onChange={(e, data) => {
                  setVal(data.value);
                }}
              />
            </Form.Group>
            {err && <div style={{ color: "red", marginBottom: 10 }}>{err}</div>}

            <Form.Group>
              <Form.Button type="button" onClick={() => setIsOpen(false)}>
                Cancel
              </Form.Button>
              <Form.Button
                type="submit"
                primary
                onClick={() => {
                  const err = onSubmit(val);
                  if (err) {
                    return setErr(err);
                  }
                  setIsOpen(false);
                }}
              >
                Submit
              </Form.Button>
            </Form.Group>
          </Form>
        </Modal.Content>
      </Modal>
      <span style={{ marginRight: 5 }}>{display || value}</span>
      <Button
        icon="edit"
        basic
        size="mini"
        onClick={() => setIsOpen((v) => !v)}
      />
    </div>
  );
};

class Dashboard extends Component {
  componentWillMount() {
    this.props.adminGetOrderStats();
    this.props.adminGetSettings();
  }
  handleSettingChange(id, value) {
    this.props.adminSetSetting(id, value);
  }
  render() {
    const {
      orderStats,
      settings,
      isDashboardLoading,
      isSettingsLoading,
    } = this.props.admin;
    const { pending, approved } = orderStats;
    return (
      <AdminLayout isLoading={isDashboardLoading || isSettingsLoading}>
        <Header as="h3">Dashboard</Header>
        <Divider />
        <Grid>
          <Grid.Row>
            <Grid.Column>
              <Statistic.Group widths={2}>
                <Statistic label="Pending Orders" value={pending} />
                <Statistic label="Approved Orders" value={approved} />
              </Statistic.Group>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column>
              <Header as="h3">Settings</Header>
              <Divider />
              <Table definition>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell></Table.HeaderCell>
                    <Table.HeaderCell>Setting Name</Table.HeaderCell>
                    <Table.HeaderCell>Setting Description</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {settings &&
                    settings.map(
                      ({ id, name, value, description, dataType }) => {
                        return (
                          <Table.Row>
                            <Table.Cell collapsing>
                              {dataType === "boolean" && (
                                <Checkbox
                                  slider
                                  checked={value === "true"}
                                  onChange={() => {
                                    this.handleSettingChange(
                                      id,
                                      !(value === "true")
                                    );
                                  }}
                                />
                              )}
                              {dataType === "number" && (
                                <InputEditCell
                                  name={name}
                                  value={value}
                                  onSubmit={(value) => {
                                    const int = parseInt(value, 10);
                                    if (Number.isNaN(int)) {
                                      return "Must be a number greater than 0.";
                                    }
                                    this.handleSettingChange(id, int);
                                  }}
                                />
                              )}

                              {dataType === "string" && (
                                <InputEditCell
                                  name={name}
                                  value={value}
                                  display={value.substr(0, 10) + "..."}
                                  onSubmit={(value) => {
                                    this.handleSettingChange(id, value);
                                  }}
                                />
                              )}
                            </Table.Cell>
                            <Table.Cell>{name}</Table.Cell>
                            <Table.Cell>{description}</Table.Cell>
                          </Table.Row>
                        );
                      }
                    )}
                </Table.Body>
              </Table>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </AdminLayout>
    );
  }
}

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      adminGetLowInventory,
      adminGetOrderStats,
      adminGetSettings,
      adminSetSetting,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
