import React from "react";
import Slider from "react-slick";
import { Image } from "semantic-ui-react";
import { IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Arrow = (props) => {
  const { style, onClick, as: Component } = props;
  return (
    <div
      className={"ProductPage-imageCarousel--arrow"}
      style={{ ...style, color: "black" }}
      onClick={onClick}
    >
      <Component style={{}}></Component>
    </div>
  );
};

export const ProductImageSlider = ({ images }) => {
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    center: true,
    customPaging: (i) => {
      return <Image key={i} src={images[i]} size="medium" />;
    },
    nextArrow: <Arrow as={IoIosArrowForward} />,
    prevArrow: <Arrow as={IoIosArrowBack} />,
  };
  return (
    <Slider {...settings}>
      {images.map((img) => {
        return <Image key={img} src={img} />;
      })}
    </Slider>
  );
};
