import clsx from "clsx";
import React from "react";
import { Header, Button } from "semantic-ui-react";
import { THEME } from "../../../env";

export const ProductOptions = ({ onSelect, variants, selectedVariant }) => {
  return (
    <div className={clsx(THEME, "ProductPage-option")}>
      <Header size="huge">
        <Header.Subheader>Variants</Header.Subheader>
      </Header>
      {variants
        .sort((a, b) => a.sortOrder - b.sortOrder)
        .map((variant) => {
          return (
            <Button
              key={variant.id}
              color={THEME === "shaw" ? "primary" : "secondary"}
              circular={THEME === "shaw"}
              basic
              content={variant.size}
              size="huge"
              className={selectedVariant.id === variant.id ? "active" : ""}
              onClick={() => {
                onSelect(variant);
              }}
              disabled={!(variant.virtualinstock > 0)}
            />
          );
        })}
    </div>
  );
};
