// @ts-check
import React, { Component } from "react";

import { Header, Divider, Grid, Button, Form } from "semantic-ui-react";
import AdminLayout from "../components/AdminLayout";
import { api } from "../api/axios";
// redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  adminGetShipAddresses,
  adminEditShipAddress,
  adminDeleteShipAddress,
  adminCreateShipAddress,
} from "../actions/admin";
import EditShipAddresses from "../components/EditShipAddresses";

class ManageShipAddresses extends Component {
  state = {
    offset: 0,
    limit: 250,
    managers: [],
  };
  async componentDidMount() {
    const { offset, limit } = this.state;
    this.props.adminGetShipAddresses({ offset, limit });

    const { data: managers } = await api({
      url: "/api/user/corporate-managers",
    });

    this.setState({ managers });
  }

  loadMore() {
    let { offset, limit } = this.state;
    offset = offset + limit;
    const state = this.state;
    this.setState({ offset });
    this.props.adminGetShipAddresses({ ...state, offset });
  }
  handleChange(e, data) {
    let [offset, limit] = [0, 200];
    this.setState({
      offset,
      limit,
    });
    this.props.adminGetUsers({
      offset,
      limit,
      refresh: true,
    });
  }
  render() {
    const { limit, managers } = this.state;
    const { admin } = this.props;
    const { shipAddresses, isShipAddressesLoading } = admin;
    return (
      <AdminLayout isLoading={isShipAddressesLoading}>
        <Header as="h3">Manage Corporate Ship Addresses</Header>
        <Divider />
        <Grid>
          <Grid.Column>
            <Form>
              <Form.Group></Form.Group>
            </Form>
          </Grid.Column>
        </Grid>

        <EditShipAddresses
          shipAddresses={shipAddresses}
          onEdit={this.props.adminEditShipAddress}
          onCreate={this.props.adminCreateShipAddress}
          onDelete={this.props.adminDeleteShipAddress}
          managers={managers}
          channel="corporate"
        />

        <Grid>
          <Grid.Row centered>
            <Grid.Column width={4}>
              <Button
                fluid
                circular
                basic
                onClick={this.loadMore.bind(this)}
                disabled={shipAddresses.length < limit}
                content={
                  shipAddresses.length < limit ? "No more data." : "Load More"
                }
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </AdminLayout>
    );
  }
}

const mapStateToProps = (state) => {
  return state;
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      adminGetShipAddresses,
      adminEditShipAddress,
      adminCreateShipAddress,
      adminDeleteShipAddress,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageShipAddresses);
